.content_subscription,
.content_subscription-reversed {
  //padding: var(--g-content) 0
  background-color: var(--bck-color);
  position: relative;
  margin: 0;
  .block--wrapper {
    padding: clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px) 0;
    grid-template-columns: 1fr;
    display: grid;
    grid-template-areas: 'content' 'img';
    max-width: var(--container);
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375))), 24px);
    @media(min-width: 769px) {
      grid-template-areas: 'content img';
      grid-template-columns: repeat(2, 1fr); } }
  .block--content {
    grid-area: content;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    border-radius: var(--border-corner);
    padding: clamp(22px, calc(22px + (40 - 22) * ((100vw - 375px) / (768 - 375))), 40px) 22px;
    @media(min-width: 769px) {
      padding: var(--g-section); }
    &:after {
      content: '';
      position: absolute;
      width: 110%;
      height: 125%;
      bottom: calc(clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px) * -1);
      //left: 0
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../img/block-subscription-bck-1.png"); } }
  .block--image {
    grid-area: img;
    @media(max-width: 768px) {
      position: relative; }
    .people {
      height: 150%;
      position: absolute;
      width: 100%;
      bottom: calc(clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 40px) * -1);
      right: 0;
      object-fit: contain;
      object-position: bottom right;
      aspect-ratio: 517/560;
      @media(min-width: 575px) {
        height: 120%; }
      @media(min-width: 768px) {
        top: 0;
        height: 100%;
        bottom: 0;
        aspect-ratio: 948/1020; } }
    //&:before
    //  content: ''
    //  position: absolute
    //  width: 110%
    //  height: 110%
    //  bottom: calc(clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px) * -1)
    //  right: 0
    //  background: linear-gradient(0deg, rgba(237,246,255,1) 0%, rgba(255,255,255,0) 20%)
    //  z-index: 1
    img {
      border-radius: var(--border-corner); } }
  .form {
    padding: 0; }
  fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: #fff;
    gap: var(--g-20);
    @media(max-width: 768px) {
      padding: 0;
      flex-direction: column;
      .form--input,
      .form--button,
      .form--button .button {
        width: 100%; } }
    .form-input {
      flex-grow: 1; }
    .form--policy,
    .form--input span {
      display: none; }
    .form--button {
      margin-top: 8px; } } }

.content_subscription-reversed {
  .block--wrapper {
    grid-template-areas: 'content' 'img';
    @media(min-width: 769px) {
      grid-template-areas: ' img content'; } }
  .block--image .people {
    scale: -1 1; } }

.block-medicine.content_subscription,
.block-medicine.content_subscription-reversed {
  padding: 0;
  .block--content {
    &:after {
      background-image: url("../img/block-subscription-bck-2.png"); } } }
