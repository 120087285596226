
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

a[href], .button {
  cursor: pointer;
  text-decoration-skip-ink: none;
  transition: 0.3s ease-out; }

.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none; }

.hidden {
  display: none; }


.ux_col {
  padding: 180px;
  display: flex;
  flex-direction: column;
  gap: 24px; }

.ui_grid_head {
  margin: 24px auto; }
.ui_grid_buttons {
  max-width: 1440px;
  margin: 24px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 24px;
  padding: 24px;
  &_title {
    grid-column: span 3;
    margin: 48px 24px 24px; }
  .theme-dark {
    padding: 24px;
    background-color: black; } }

//*
//  outline: indianred 1px solid
//  outline-offset: -1px

body {
  //max-width: 1920px
 }  //margin: 0 auto


.language_selector {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 50;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-corner) 0 0 var(--border-corner);
  overflow: hidden;
  &--button {
    padding: 8px 12px;
    background-color: var(--c-surface-default);
    color: var(--c-text-accent);
    &.is_active {
      background-color: var(--c-surface-accent);
      color: var(--c-text-white); } } }

html.lenis {
  height: auto; }

.lenis.lenis-smooth {
  scroll-behavior: auto; }

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain; }

.lenis.lenis-stopped {
  overflow: hidden; }

.lenis.lenis-scrolling iframe {
  pointer-events: none; }



