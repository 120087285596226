.content_gallery {
  max-width: 100%;
  overflow: hidden;
  .block--wrapper {
    @media(min-width: 768px) {
      overflow: hidden; } }
  figcaption {
    display: none; }
  .slider_controls {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
    left: unset;
    transform: translateY(calc(-100% - var(--g-40)));
    @media(max-width: 768px) {
      display: none; }
    .swiper-button-next,
    .swiper-button-prev {
      width: 100%;
      color: var(--c-text-accent); } } }

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap:  clamp(16px, calc(16px + (40 - 12) * ((100vw - 1024px) / (1920 - 1024))), 40px) clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  @media(max-width: 992px) {
    grid-template-columns: 1fr 1fr; }
  //@media(max-width: 768px)
  //  grid-template-columns: 1fr
  .gallery--item {
    border-radius: var(--border-corner);
    overflow: hidden;
    img, figure {
      width: 100%;
      height: 100%;
      aspect-ratio: 517 / 420;
      @media(max-width: 992px) {
        aspect-ratio: 165/220; } } } }

.js-gallerySwiper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%;
  .gallery--item {
    height: 100%;
    aspect-ratio: 517 / 420; } }





