.content_form {
  .block--wrapper {
    @media(min-width: 1630px) {
      max-width: 1350px;
      margin: auto; } }

  .block--elements {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--g-elements);
    @media(min-width: 768px) {
      grid-template-columns: repeat(2, 1fr); }

    .block--head {
      max-width: 520px;
      &_title {
        line-height: 1; } } } }
