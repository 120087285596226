.content_vacancies {
  .block--wrapper {
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-column-gap: var(--g-section);
    place-content: start;
    place-items: start;
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      grid-column-gap: 20px; }
    .block--head, .block--actions {
      @media(min-width: 769px) {
        grid-column: span 2; } }
    .block--elements {
      margin: 0;
      width: 100%;
      grid-template-columns: 1fr;
      gap: var(--g-content); }
    .block--filters {
      overflow: hidden; }
    .filters {
      background-color: var(--bck-color);
      padding: var(--g-elements) var(--g-32);
      border-radius: var(--border-corner);
      @media(max-width: 768px) {
        overflow-x: auto;
        max-width: 100vw;
        margin: 0 -16px 0 0; }
      &--container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media(max-width: 768px) {
          flex-direction: row; }
        label {
          white-space: nowrap; }

        label:last-child span:not(.custom-checkbox) {
          margin-right: 32px; } }
      &--item_title {
        grid-area: title;
        font-family: 'arsenica', sans-serif;
        font-size: var(--fs-32); } } } }


