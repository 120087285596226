@font-face {
  font-family: 'arsenica';
  //font-weight: 300
  font-style: normal;
  src: url("../fonts/arsenica/Ars-Light.woff") format('truetype'); }

@font-face {
  font-family: 'wix-madefor';
  font-weight: 125 950;
  font-style: normal;
  src: url("../fonts/wix-madefor/WixMadeforDisplay-VariableFont_wght.ttf") format('truetype'); }

@font-face {
  font-family: 'caveat';
  font-weight: 125 950;
  font-style: normal;
  src: url("../fonts/caveat/Caveat-VariableFont_wght.ttf") format('truetype'); }


html {
  font-size: clamp(16px, calc(16px + (18 - 16) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  line-height: 1.25;
  font-family: 'wix-madefor', sans-serif;
  color: var(--c-text-dark);
  text-size-adjust: none;
  -webkit-text-size-adjust: none; }


h1,h2,h3,h4 {
  font-family: 'arsenica', sans-serif;
  font-weight: 300; }

