.block_image_text {
  margin: 0;
  padding: var(--g-section) 0;
  .block--wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: start;
    grid-template-rows: 0.5fr auto 0.5fr;
    gap: 0 24px;
    &:has(.block--image) {
      grid-template-rows: 0.5fr auto 0.5fr; }
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto; } }

  .block--head {
    grid-column: 1;
    border-radius: 8px 8px 0 0; }
  .block--content,
  .block--actions {
    grid-column: 2;
    @media(max-width: 768px) {
      grid-column: 1; } }
  .block--image ~ .block--head,
  .block--image ~ .block--content,
  .block--image ~ .block--actions {
    grid-column: 1;
    width: 100%;
    background-color: #fff;
    @media(min-width: 769px) {
      padding:  16px var(--g-section); } }
  .block--image ~ .block--head {
    justify-content: flex-end; }
  .block--image {
    grid-column: 2;
    grid-row: 1 / span 3;
    //min-height: 100%
    max-width: 100%;
    border-radius: var(--border-corner);
    @media(max-width: 768px) {
      grid-column: 1;
      margin-bottom: 8px;
 }      //grid-row: 1 / span 1
    img, video {
      //aspect-ratio: 1 / 1
      object-fit: cover;
      //height: auto
      height: 100%;
      width: 100%;
      border-radius: var(--border-corner);
      @media(min-width: 769px) {
        //position: sticky
        //top: calc(var(--headerHeight) + 40px)
        top: var(--headerHeight); } } }
  .block--head {
    align-items: flex-start;
    @media(max-width: 768px) {
      padding:  0; }
    &_title {
      color: var(--c-text-dark); } }
  .block--content {
    margin-top: 0;
    padding: 16px 0;
    @media(max-width: 768px) {
      padding: 0; }
    & > span {
      color: var(--c-text-accent-subdued); }
    & > span,
    & > p {
      margin-top: 16px;
      display: inline-block; } }
  .block--actions {
    align-items: flex-start;
    border-radius: 0 0 8px 8px;
    @media(max-width: 768px) {
      padding: 0; } }

  &.block_image_text-reversed {
    .block--wrapper {
      .block--image {
        grid-column: 1; }
      .block--head,
      .block--content,
      .block--actions {
        padding: 0 var(--g-section);
        grid-column: 2;
        @media(max-width: 768px) {
          grid-column: 1;
          padding:  0; } }
      .block--content {
        margin-top: 0;
        padding: 16px var(--g-section);
        @media(max-width: 768px) {
          padding: 0;
          .block--actions {
            @media(max-width: 768px) {
              padding: 0 var(--g-elements) 16px; } } } } } }


  // MOUNT
  &.block_image_text-reversed.block_image_text-mountain {
    position: relative;
    overflow: hidden;
    .block--image ~ .block--head {
      padding-top: var(--g-section); }
    .block--image ~ .block--actions {
      padding-bottom: var(--g-section); }
    .block--content {
      z-index: 1;
      background-color: transparent; }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: var(--g-section);
      width: clamp(291px, calc(291px + (486 - 291) * ((100vw - 1024px) / (1920 - 1024))), 486px);
      height: clamp(221px, calc(221px + (615 - 221) * ((100vw - 1024px) / (1920 - 1024))), 615px);
      background-image: url("../img/mountain.png");
      left: auto;
      scale: 1 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      z-index: 0;
      @media(max-width: 768px) {
        opacity: 0.4; } } }
  &.block_image_text-ratio {
    .block--image {
      img, video {
        aspect-ratio: 1 / 1; } } }
  &.block_image_text-ratio_portrait {
    .block--image {
      img, video {
        aspect-ratio: 3/4; } } }
  &.block_image_text-ratio_landscape {
    .block--image {
      img, video {
        aspect-ratio: 3 / 2; } } }
  &.block_image_text-wide {
    overflow: hidden;
    .block--image {
      img, video {
        margin-right: calc((100vw - var(--container) - var(--scrollbarWidth)) / -2);
        width: calc(100% + (100vw - var(--container) - var(--scrollbarWidth)) / 2);
        border-radius:  var(--border-corner) 0 0 var(--border-corner);
        @media(max-width: 768px) {
          width: 100vw;
          margin: 0 -16px; } } } }
  &.block_image_text-reversed.block_image_text-wide {
    overflow: hidden;
    .block--image {
      img, video {
        margin-left: calc((100vw - var(--container) - var(--scrollbarWidth)) / -2);
        width: calc(100% + (100vw - var(--container) - var(--scrollbarWidth)) / 2);
        border-radius: 0 var(--border-corner) var(--border-corner) 0;
        @media(max-width: 768px) {
          width: auto;
          max-width: 100vw;
          margin: 0 -16px; } } } }
  &.block_image_text-bg {
    .block--wrapper {
      //gap: 0
      .block--head {
        padding-top: 16px; }
      .block--actions {
        padding-bottom: 16px; } }
    .block--image ~ .block--head,
    .block--image ~ .block--content,
    .block--image ~ .block--actions {
      background-color: var(--bck-color);
      @media(max-width: 768px) {
        padding: 16px; } } }
  &.block_image_text-wrapper_bg {
    background-color: var(--bck-color);
    @media(max-width: 768px) {
      .block--image ~ .block--head,
      .block--image ~ .block--content,
      .block--image ~ .block--actions {
        padding: 16px; } }
    .block--wrapper {
      gap: 0 16px; } }
  &.block_image_text.block_image_text-text {
    .block--wrapper {
      grid-template-rows: auto;
      @media(min-width: 769px) {
        padding: 0 var(--g-section); } }
    .block--head {
      &_caption {
        line-height: 1.5; } }
    .block--content {
      color: var(--c-text-dark); } } }

.block_image_text.block_image_text-bg.block_image_text-wrapper_bg.block_image_text-wide {
  padding: 0;
  img {
    border-radius: 0; } }

.block_image_text.block_image_text-ratio_landscape.block_image_text-bg {
  margin: var(--g-section) 0;
  @media(max-width: 768px) {
    padding: 0;
    .block--image {
      max-width: 100vw;
      width: 100vw; } }
  .block--image ~ .block--head {
    @media(min-width: 769px) {
      padding-top: var(--g-section); } }
  .block--image ~ .block--actions {
    @media(min-width: 769px) {
      padding-bottom: var(--g-section); } } }

// MASK
.block_image_text_mask-1 {
  .block--wrapper {
    grid-template-columns: 1fr 1fr;
    @media(max-width: 768px) {
      grid-template-columns: 1fr; }
    .block--head,
    .block--content,
    .block--actions {
      background-color: transparent; } }
  .block--image img {
    position: static; }
  svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none; }
  img {
    clip-path: url(#mask);
    background-color: #fff; } }

.block_image_text.block_image_text_mask-2 {
  overflow: hidden;
  @media(max-width: 768px) {
    padding:  20px 0; }
  .block--wrapper {
    clip-path: url(#mask-2);
    background-color: #fff;
    --container: calc(100vw - 80px);
    @media(max-width: 768px) {
      clip-path: url(#mask-2-mob);
      padding:  0 0 60px 0;
      --container: 100vw; }
    .block--image ~ .block--head,
    .block--image ~ .block--content,
    .block--image ~ .block--actions {
      padding: 0 var(--g-section) 0 var(--g-section-inner); }
    .block--image ~ .block--head {
      padding-top: var(--g-elements); }
    .block--image ~ .block--actions {
      padding-bottom: var(--g-elements);
      @media(max-width: 768px) {
        padding-bottom: clamp(40px, calc(40px + (80 - 40) * ((100vw - 375px) / (768 - 375))), 80px); } }
    .block--image {
      min-height: auto;
      @media(max-width: 768px) {
        aspect-ratio: 1/1; }
      img {
        border-radius: 0;
        height: 100%;
        //margin-left: auto
        @media(max-width: 768px) {
          padding: 0 10px;
          height: 100%;
          object-position: center right; } } } }
  svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none; } }

