.content_map_around {
  #map_places {
    aspect-ratio: 1272 / 540;
    margin-top: 40px;
    position: relative;
    @media(max-width: 768px) {
      aspect-ratio: 1 / 1; }
    .ymaps-2-1-79-balloon__content {
      font: inherit; }
    .ymaps-2-1-79-image {
      -webkit-clip-path: url(#placemark_clip_path);
      clip-path: url(#placemark_clip_path);
      aspect-ratio: 40 / 50;
      width: 40px;
      height: 50px;
      background-size: cover !important;
      background-position: center !important; }

    .balloon {
      display: flex;
      flex-direction: column;
      width: 300px;
      background-color: var(--c-surface-default); }
    .balloon--wrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .button {
        margin-top: 16px; } }
    .balloon--image {
      width: 100%;
      aspect-ratio: 300 / 234;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
      &:after {
        content: "";
        width: 100%;
        height: auto;
        background: radial-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.25));
        background-blend-mode: multiply;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2; } }
    .balloon--title {
      font-weight: 500;
      font-size: var(--fs-20);
      color: var(--c-text-dark); }
    .balloon--text {
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }
    .balloon--content {
      font-size: var(--fs-16);
      color: var(--c-text-subdued);
      line-height: 1.5; } } }
