.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 50;
  background: #FFFFFF;

  transition: var(--transition);
  //@media(min-width: 1440px)
  //  max-width: calc(100vw - var(--scrollbarWidth , 17px))
  @media(min-width: 768px) {
    max-width: calc(100vw - var(--scrollbarWidth, 17px)); }
  .header--logo {
    z-index: 2;
    span {
      background: url("../img/logo/logo-2.svg") center no-repeat;
      &:after {
        background-image: url("../img/logo/logo-bck4.svg"); } } }
  &.is_scrolled {
    box-shadow: 0px 3px 12px 0px #021F541F;
    translate: 0 -55px; }
  &.header-transparent {
    position: fixed;
    .header--logo {
      span {
        background: url("../img/logo/logo.svg") center no-repeat;
        background-size: contain;
        &:after {
          background-image: url("../img/logo/logo-bck1.svg"); } } }
    &.is_scrolled {
      .header--logo {
        span {
          background: url("../img/logo/logo-2.svg") center no-repeat;
          background-size: contain; } } } }

  &.header-transparent:not(.is_scrolled) {
    background: transparent;
    border-color: transparent;
    color: var(--c-text-white);
    .header--contacts_mob-phone {
      background: rgba(206, 206, 206, .15);
      backdrop-filter: blur(8px); } } }

// color logo
.popup-menu {
  .header--logo {
    span {
      background: url("../img/logo/logo.svg") center no-repeat;
      &:after {
        background-image: url("../img/logo/logo-bck1.svg"); } } } }
.page-medicine {
  .header.header-transparent,
  .popup-menu {
    .header--logo {
      span:after {
        background-image: url("../img/logo/logo-bck2.svg"); } } }
  .header:not(.header-transparent) {
    .header--top {
      .header--actions_item .icon,
      .header--location_item .icon {
        color: var(--c-text-accent); } }
    .header--logo {
      span {
        background: url("../img/logo/logo-3.svg") center no-repeat;
        background-size: contain;
        &:after {
          background-image: url("../img/logo/logo-bck5.svg"); } } } } }




.header:not(.header-transparent),
.header-transparent.is_scrolled {
  .nav--item {
    --c-surface-default: #19293C;
    --c-surface-opacity: #b6b4b2; }
  .header--contacts {
    .button {
      color: var(--c-text-dark); } } }

.header,
.popup-menu {
  .header--top {
    max-width: var(--container);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0 0;
    z-index: 5;
    width: 100%;
    .header--actions,
    .header--location {
      display: flex;
      gap: 16px;
      position: relative;
      letter-spacing: 0.002em;
      border-radius: 6px 0 0 6px;
      padding: 6px 10px;
      overflow: hidden;
      cursor: default;
      background: rgba(206, 206, 206, .15);
      backdrop-filter: none;
      @media(min-width: 768px) {
        backdrop-filter: blur(8px);
        border-radius: 6px; }
      .header--actions_item {
        display: flex;
        align-items: center;
        white-space: nowrap; }
      span.icon {
        font-size: 16px;
        margin-right: 8px;
        position: relative;
        z-index: 2; }
      span:not(.icon) {
        font-size: 14px;
        position: relative;
        z-index: 2; } }
    .header--location {
      @media(max-width: 998px) {
        display: none; } }
    .header--actions {
      @media(max-width: 998px) {
        overflow-x: auto;
        margin-right: -16px; } } }

  .header--actions .header--actions_item {
    span.icon {
      color: #C6DCFF; } } }

.header.header-transparent {
  .header--top {
    color: #FFFFFF; } }
.header:not(header-transparent) {
  .header--top {
    color: black; }
  .header--contacts_list ul {
    background-color: rgba(5, 10, 23, .3); } }
.popup-menu,
.header {
  .header--bottom {
    max-width: var(--container);
    padding: 10px 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--g-elements);
    @media(min-width: 1140px) {
      display: grid;
      grid-template-columns: 1fr auto 1fr; }
    .header--logo {
      padding: 0;
      position: relative;
      z-index: 5;
      @media(min-width: 769px) {
        padding: 0 22px; }
      span {
        display: inline-block;
        width: 96px;
        height: 46px;
        background-size: contain;
        //position: relative
        //z-index: 5
        @media(min-width: 768px) {
          width: 160px;
          height: 57px; }
        &:after {
          content: none;
          @media(min-width: 769px) {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            z-index: -1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center; } } } }

    .header--nav {
      display: none;
      @media(min-width: 993px) {
        display: flex; }
      .nav--item[data-openpopup],
      .nav--item[data-closepopup] {
        border: 1px solid currentColor;
        border-radius: var(--border-corner); }
      .nav--item {
        font-size: var(--fs-18);
        padding: clamp(14px, calc(14px + (16 - 14) * ((100vw - 1024px) / (1920 - 1024))), 16px);
        transition: var(--transition); } }
    .header--actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: var(--g-elements);
      position: relative;
      @media(max-width: 768px) {
        flex-wrap: wrap; }
      & > .button {
        @media(max-width: 768px) {
          display: none; } } }
    .header--location {
      @media(min-width: 768px) {
        display: none; } }
    .header--contacts {
      padding: 12px 0;
      position: relative;
      display: flex;
      .contacts {
        display: flex;
        gap: 8px;
        transition: none;
        font-size: var(--fs-18);
        white-space: nowrap;
        span {
          font-weight: 400; } }
      .icon.icon-link {
        rotate: 90deg;
        transition:  var(--transition);
        @media(max-width: 768px) {
          display: none; } } }
    .header--contacts_list {
      display: none;
      @media(min-width: 769px) {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        display: block;
        min-width: 100%;
        padding-top: 8px;
        pointer-events: none;
        color: #FFFFFF;
        opacity: 0;
        transition: var(--transition);
        translate:  0 -30%;
        ul {
          position: relative;
          list-style-type: none;
          background-color: rgba(255, 255, 255, 0.3);
          padding: 12px 16px;
          border-radius: var(--border-corner);
          backdrop-filter: blur(8px);
          li {
            display: flex;
            gap: 8px;
            font-size: var(--fs-18);
            position: relative;
            z-index: 3;
            transition: var(--transition) opacity;
            span {
              margin-right: 10px; } }
          li:not(:last-child) {
            margin-bottom: 16px; } } } }
    .header--contacts:hover .header--contacts_list,
    .header--contacts_list:hover {
      @media(min-width: 769px) {
        pointer-events: auto;
        opacity: 1; } }
    .header--contacts:hover .header--contacts_list {
      @media(min-width: 769px) {
        translate:  0 0; } }
    .header--contacts:hover .icon.icon-link {
      @media(min-width: 769px) {
        rotate: 270deg; } }
    .header--contacts_list li:hover {
      @media(min-width: 769px) {
        opacity: 0.7; } } } }


.header:not(.header-transparent),
.header.is_scrolled {
  .header--bottom {
    .header--contacts_list ul {
      color: black;
      background-color: var(--bck-color); } } }

.popup-menu .header--nav {
  .nav--item[data-closepopup] {
    .burger {
      &-line {
        opacity: 0; }
      &:after {
        top: 50%;
        rotate: 45deg; }
      &:before {
        top: 50%;
        rotate: -45deg; } } } }


//is_dropdown
.is_dropdown {
  @media(min-width: 993px) {
    position: relative;
    .is_dropdown--content {
      position: absolute;
      top: 100%;
      left: 0;
      pointer-events: none;
      width: fit-content;
      //padding: 12px 16px
      transition: var(--transition);
      pointer-events: none;
      opacity: 0;
      translate: 0 -30%;
      border-radius: 4px;
      color: #FFFFFF;
      .button {
        min-width: 100%;
        text-align: left;
        justify-content: flex-start; } } } }
.is_dropdown:hover .is_dropdown--content,
.is_dropdown:focus .is_dropdown--content {
  display: flex;
  pointer-events: auto;
  flex-direction: column;
  pointer-events: all;
  opacity: 1;
  gap: 4px;
  translate: 0 0; }
.header-transparent .is_dropdown--content {
  background-color: rgba(255, 255, 255, .3);
  backdrop-filter: blur(8px); }
.header:not(.header-transparent) .is_dropdown--content,
.header.is_scrolled .is_dropdown--content {
  color: black;
  background-color: var(--bck-color); }



// MOBILE
.header--mobile_panel {
  display: none;
  transition: var(--transition);
  @media(max-width: 992px) {
    display: block;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px 12px 14px 12px;
    z-index: 150;
    width: 100vw;
    background-color: #FFFFFF; }
  .mobile_panel_actions {
    font-size: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    .mobile_panel_link {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .icon {
        position: relative; } }
    .mobile_panel_link-contacts {
      .icon.icon-contacts {
        position: static;
        display: inline-block;
        background-image: url("../img/icons/contacts.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        width: 20px;
        height: 20px;
        margin-bottom: 6px; }
      .contacts {
        border-radius: var(--border-corner);
        background-color: #FFFFFF;
        position: fixed;
        bottom: 80px;
        left: 16px;
        pointer-events: none;
        opacity: 0;
        transition: var(--transition);
        translate: 0 30%;
        &--content {
          padding: 12px 16px;
          ul {
            list-style-type: none; }
          li {
            color: var(--c-text-accent);
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 16px; }
          li:not(:last-child) {
            margin-bottom: 16px; } } }
      .contacts.active {
        opacity: 1;
        translate: 0 0; } }

    .mobile_panel_link-search {
      .icon {
        border-radius: 8px;
        display: inline-block;
        width: 52px;
        height: 52px;
        top: -32px;
        position: absolute;
        background-color: var(--c-text-accent);
        background-image: url("../img/icons/search-mob.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        padding: 4px; } }
    .mobile_panel_link-menu.open {
      .burger {
        &-line {
          opacity: 0; }
        &:after {
          top: 50%;
          rotate: 45deg; }
        &:before {
          top: 50%;
          rotate: -45deg; } } }
    .burger {
      margin-bottom: 12px; } } }


.burger {
  width: 20px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &-line {
    width: 60%;
    display: block;
    height: 2px;
    background-color: currentColor;
    @media(max-width: 768px) {
      transition: var(--transition); } }
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: currentColor;
    height: 2px;
    left: 0;
    right: 0;
    translate: 0 -50%;
    @media(max-width: 768px) {
      transition: var(--transition); } }
  &:after {
    top: 0; }
  &:before {
    top: 100%; } }



