//#map
//  width: 600px
//  height: 600px

#map-big {
  width: 100%;
  min-height: 400px;
  @media(min-width: 769px) {
    min-height: 800px; }
  .ymaps-2-1-79-balloon__content {
    font: inherit; }
  .ymaps-2-1-79-image {
    -webkit-clip-path: url(#placemark_clip_path);
    clip-path: url(#placemark_clip_path);
    aspect-ratio: 40 / 50;
    width: 40px;
    height: 50px;
    background-size: cover !important;
    background-position: center !important; } }

.content_map {
  .block--map {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    margin: var(--g-section) 0;
    position: relative; } }
