.block_banner {
  overflow: hidden;
  position: relative;
  .block--wrapper {
    height: 100%; }
  .block--head,
  .block--actions {
    z-index: 2;
    position: relative; }
  .block--content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img, video {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover; } }
  .block--head {
    & > * {
      z-index: 2;
      position: relative; } }
  .banner {
    display: grid; } }

.block_banner.banner-group:not(.banner-hero) {
  .block--wrapper {
    overflow: hidden; }
  .slider_controls {
    position: absolute;
    top: 50%;
    width: var(--container);
    left: calc((100% - var(--container)) / 2 );
    justify-content: space-between; }
  .swiper-button-next {
    transform: translateX(calc(100% + 24px)); }
  .swiper-button-prev {
    transform: translateX(calc(-100% - 24px)); } }

.block_banner:not(.banner-group):not(.banner-hero) {
  .banner {
    margin: auto; } }

.block_banner.banner-group {
  .banner.swiper-slide-active {
    z-index: 1; } }

.block_banner {
  .banner {
    min-width: var(--container);
    width: var(--container);
    height: auto;
    overflow: hidden;
    position: relative;
    place-content: end start;
    place-items: end start;
    @media(max-width: 768px) {
      aspect-ratio: 320 / 420;
      padding: 24px 16px;
      min-width: calc(100vw - 32px - 8px); } }
  .block--content {
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 51.09%, rgba(0, 0, 0, 0.3) 64.69%, rgba(0, 0, 0, 0.3) 50.57%);
      z-index: 0; } }
  //.block--actions
  //.block--head
  .block--head_caption p {
    line-height: 1.5;
    font-size: var(--fs-24); } }

.block_banner.banner-hero {
  height: 100vh;
  margin: 0;
  position: relative;
  .block--wrapper {
    padding: 0;
    max-width: 100%; }
  .banner {
    position: relative;
    height: 100%;
    min-width: 100%;
    place-items: start;
    place-content: start;
    color: var(--c-text-white);
    padding: calc(20% + var(--headerHeight, 88px)) calc((100% - var(--container)) / 2);
    @media(min-width: 998px) {
      padding: calc(120px + var(--headerHeight, 137px)) calc((100% - var(--container)) / 2); } }
  .block--head {
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 720px;
    &:before {
      content: "";
      position: absolute;
      top: -10%;
      left: -10%;
      display: block;
      width: 120%;
      height: 120%;
      background: rgba(0, 0, 0, 0.35);
      filter: blur(125px); }

    &_caption p {
      color: var(--c-text-white);
      opacity: 0.8; } }


  .slider_controls {
    display: none;
    @media(min-width: 1241px) {
      display: flex;
      justify-content: space-between;
      bottom: clamp(10%, calc(10% + (50 - 10) * ((100vw - 1024px) / (1920 - 1024))), 50%);
      left: 50%;
      translate: -50% -50%;
      width: calc(100% - var(--g-section));
      pointer-events: none;
      @media(min-width: 1760px) {
        bottom: 50%; } }
    .swiper-button-prev,
    .swiper-button-next {
      pointer-events: auto;
      &:after {
        color: white;
        background-color: rgba(255, 255, 255, 0.3); } }

 }    //filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.05))

  .swiper_pagination {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10%;
    @media(max-width: 768px) {
      bottom: 5%; }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      z-index: 10;
      background: #fff;
      opacity: 0.6;
      position: relative; }

    .swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      opacity: 1; } } }


.block_banner.banner-no_media {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    aspect-ratio: unset;
    padding: 0; }
  .block--head {
    max-width: 1000px;
    width: 100%;
    align-items: center;
    .f-h2 {
      font-size: var(--fs-title); } }
  .block--head_title,
  .block--head_caption p {
    text-align: center;
    max-width: 1000px; }
  .block--actions {
    @media(max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap; }
    .button {
      margin-top: 24px;
      @media(min-width: 768px) {
        margin-top: 32px; } } } }

