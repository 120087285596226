.form {
  fieldset {
    background-color: #EDF6FF;
    border-color: transparent;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: var(--g-content);
    border-radius: var(--border-corner); }
  &--title {
    font-size: var(--fs-56);
    font-family: arsenica; }
  &--text {
    color: var(--c-text-subdued);
    font-size: var(--fs-20); }
  &--input {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-size: 16px;
      color: var(--c-text-dark);
      font-weight: 600; }
    input, textarea, .form__input-file {
      outline: none;
      padding: 12px 16px;
      line-height: 1.2;
      font-size: 16px;
      font-weight: 400;
      background-color: transparent;
      border-radius: var(--border-corner);
      transition: 0.3s ease-out;
      background-color: #fff;
      z-index: 1; }
    input, textarea {
      border: 1px solid var(--c-text-disabled); }
    .form__input-file {
      border: 1px dashed var(--c-text-disabled);
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .icon {
        color: var(--c-text-accent);
        font-size: 0.8em; }
      &::placeholder,
      &:placeholder-shown {
        color: #828595; }
      &:not(:placeholder-shown) {
        color: var(--c-dark); }
      &:focus {
        border-color: var(--c-text-dark); }
      &:invalid:not(:placeholder-shown) {
        border-color: var(--c-error);
        & ~ .form--input_error {
          height: auto; } }
      &:valid:not(:placeholder-shown) {
        border-color: var(--c-text-dark); } }
    .form__input-file.loaded {
      .js-clearFile {
        display: block; } }
    &.error {
      .form--input_error {
        height: auto; } }

    &_error {
      color: var(--c-error);
      height: 0;
      overflow: hidden;
      transition: 0.3s linear; } }
  &--policy {
    font-size: 16px;
    font-weight: 400;
    color: var(--c-text-secondary);
    a:not(.button) {
      font-size: 16px;
      font-weight: 400; } }

  &__button {
    align-self: start; } }

.form.is_sending {
  button[type="submit"] {
    opacity: 0.5;
    pointer-events: none; } }

