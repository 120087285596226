.content_advantages {
  overflow: hidden;
  .swiper-button-next,
  .swiper-button-prev {
    @media(max-width: 768px) {
      display: none; } } }
.block_list:not(.block_list-slider).content_advantages {
  .block--elements {
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
    @media(max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr); }
    //@media(max-width: 768px)
 } }    //  grid-template-columns: 1fr
.block_list.content_advantages-2 {
  .advantages--item_wrapper.swiper {
    overflow: visible; } }
.block_list.content_advantages-2 {
  overflow: hidden;
  padding: var(--g-section) 0;
  margin: 0;
  .block--elements {
    display: flex;
    flex-direction: column;
    place-items: start;
    margin: var(--g-content) auto 0;
    border-radius: var(--border-corner);
    gap: 24px;
    @media(min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'image text'; }

    .advantages_item_wrapper {
      grid-area: text;
      @media(max-width: 1024px) {
        order: 2; } }

    .advantages_image {
      grid-area: image;
      @media(max-width: 1024px) {
        order: 1; } } }

  .advantages--item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%; }

  .advantages--item {
    cursor: pointer;
    background-color: var(--c-surface-default);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0;
    border-radius: var(--border-corner);
    background-color: transparent;
    flex-shrink: 0;
    flex-grow: 0;
    @media(min-width: 1024px) {
      height: 100%;
      background-color: var(--c-surface-default);
      gap: 16px;
      flex-shrink: 1;
      flex-grow: 1;
      padding: var(--g-20) var(--g-32);
      opacity: 0.7; }
    &_image {
      video {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center; } }
    &.is_active,
    &.swiper-slide-active {
      opacity: 1; }
    &_button {
      @media(max-width: 1024px) {
        border-radius: 8px 8px 0 0; }
      p {
        font-size: var(--fs-24);
        font-family: 'arsenica', sans-serif;
        text-transform: uppercase;
        line-height: 1.17; } }
    &_content.is_cascade {
      color: var(--c-text-dark);
      line-height: 1.5;
      font-size: var(--fs-18);
      border-radius:  0 0 8px 8px;
      flex-grow: 1;
      //align-items: flex-end
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media(min-width: 1024px) {
        align-items: center;
        flex-direction: row;
        .advantages--item_image {
          display: none;
          border-radius: 0; } }
      div,
      p,
      li,
      span {
        //line-height: 1.5
        font-size: var(--fs-18);
        margin-bottom: 0;
        &:first-child {
          margin-top: 0; } }
      ul {
        padding-left: 24px; }
      .button_wrapper {
        margin: 0;
        span {
          margin-bottom: 0; } }
      .button {
        width: 100%;
        max-width: 130px;
        padding-right: 25px;
        //@media(max-width: 768px)
        //  margin-bottom: auto
        & span:empty + span {
          display: none; } } }
    &_button,
    &_content {
      background-color: #FFFFFF;
      padding: 12px var(--g-32); } }

  .advantages--image {
    display: none;
    @media(min-width: 1024px) {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
      aspect-ratio:  1/1; }
    &_item {
      //overflow: hidden
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      transition: 0.24s linear;
      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media(max-width: 1024px) {
          aspect-ratio: 344 / 333; } }
      &.is_active {
        opacity: 1; } } }

  .advantages--item_image {
    aspect-ratio: 328 / 300;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    margin: calc(var(--g-40) * -1) calc(var(--g-64) * -1) 0;
    @media (max-width: 1024px) {
      display: block;
      margin-bottom: 24px; } } }

