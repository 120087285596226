.content_document {
  &:not(.block_list-slider) {
    .block--elements {
      grid-template-columns: repeat(4, 1fr);
      @media(max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 12px; }
      @media(max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); } }
    .block--pagination {
      grid-column: span 4;
      @media(max-width: 1024px) {
        grid-column: span 3; }
      @media(max-width: 768px) {
        grid-column: 2; } } } }
