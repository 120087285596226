.footer {
  background-color: var(--background-primary);
  position: relative;
  padding: var(--g-content) 0;
  color: var(--c-text-white);
  z-index: 2;
  @media(max-width: 768px) {
    padding-bottom: 90px; }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 13%;
    background-image: url("../img/footer-bck.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0; }
  .footer--form {
    @media(max-width: 768px) {
      width: 100%; }
    &_title {
      font-size: var(--fs-44);
      margin-bottom: var(--g-32); }
    .form {
      display: flex;
      gap: 8px;
      align-items: center;
      @media(max-width: 375px) {
        flex-wrap: wrap; }
      &--input {

        width: 100%; } } }

  .footer--top {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: var(--g-section-inner);
    margin-top: 30px;
    @media(max-width: 768px) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 40px;
      margin: 0; }
    .footer--logo {
      position: relative;
      padding: 0 22px;
      position: relative;
      width: fit-content;
      span {
        position: relative;
        display: inline-block;
        width: 280px;
        height: 109px;
        background: url("../img/logo/logo.svg") center no-repeat;
        background-size: contain;
        @media(max-width: 768px) {
          width: 157px;
          height: 54px; }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
          z-index: -1;
          background-image: url("../img/logo/logo-bck1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; } } } }
  .footer--main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--g-section) 0;
    gap: var(--g-section-inner);
    @media(max-width: 1024px) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 40px; }
    .footer--contacts {
      display: flex;
      flex-direction: column;
      gap: var(--g-content);
      &  h4 {
        color: var(--c-surface-accent);
        font-family: 'caveat', sans-serif;
        font-size: var(--fs-28);
        margin-bottom: 16px; }
      &_phone {
        & div {
          display: flex;
          align-items: center;
          gap: var(--g-20);
          @media(max-width: 768px) {
            flex-wrap: wrap; } }
        & a {
          padding: 0;
          border: none;
          font-family: 'arsenica', sans-serif;
          span {
            font-size: var(--fs-28); } }
        & a:not(:last-child):after {
          content: '';
          width: 11px;
          height: 11px;
          background-color: var(--c-surface-accent);
          border-radius: 50%;
          position: static;
          display: inline-block;
          margin-left: var(--g-20); } }
      &_adress {

        & div {
          display: flex;
          align-items: center;
          gap: var(--g-20);
          white-space: nowrap;
          flex-wrap: wrap; }
        span {
          color: var(--c-surface-opacity); }
        a {
          color: var(--c-surface-opacity);
          text-decoration: underline;
          transition: var(--transition);
          &:hover {
            color: var(--c-text-white); } } }
      &_soc {
        div {
          display: flex;
          gap: 8px; }
        .icon {
          display: inline-block;
          background-color: #FFFFFF;
          border-radius: var(--border-corner);
          width: 48px;
          height: 48px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto;
          position: relative;
          &:before {
            color: var(--c-surface-accent);
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 42px;
            transition: var(--transition);
            translate: -50% -50%; }
          &:hover:before {
            color: var(--c-surface-accent-hovered); } } } }

    .footer--nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;
      @media(max-width: 475px) {
        justify-content: flex-start; }
      span {
        display: inline-block;
        color: var(--c-surface-accent);
        font-weight: 600;
        margin-bottom: 16px;
        font-family: 'caveat', sans-serif;
        font-size: var(--fs-28); }

      a {
        border: none;
        padding: 0;
        margin-bottom: 12px; } } }

  .footer--bottom {
    padding: var(--g-section-inner) 0 0 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media(max-width: 768px) {
      flex-wrap: wrap; }
    span {
      color: var(--c-surface-opacity);
      white-space: nowrap; }
    &_copyright,
    &_developer {
      display: flex;
      align-items: center;
      gap: var(--g-20);
      z-index: 1;
      a {
        padding: 0;
        white-space: nowrap;
        &:hover {
          opacity: 0.6; } } }
    &_copyright {
      @media(max-width: 768px) {
        flex-wrap: wrap;
        //position: relative
        //overflow-x: auto
        //max-width: 100vw
        //padding: 0 16px
        //margin: 0 -16px
 } } } }        //flex-wrap: nowrap


.page-medicine {
  .footer {
    --c-surface-accent: #68BD5B; }
  .footer:after {
    background-image: url("../img/footer-bck-medicine.png"); }
  .footer--top {
    .footer--logo span:after {
      background-image: url("../img/logo/logo-bck2.svg"); } } }



// widget
.footer--widget {
  display: none;
  @media(min-width: 1241px) {
    display: block;
    height: 45px; }
  &_container {
    @media(min-width: 1241px) {
      position: fixed;
      z-index: 50;
      max-width: var(--container);
      margin: 0 auto;
      backdrop-filter: blur(8px);
      bottom: 15px;
      left: 50%;
      width: 100%;
      justify-content: space-between;
      display: flex;
      translate: -50% 0;
      padding: 10px 24px;
      background: rgba(206, 206, 206, .15);
      transition: background-color var(--transition);
      border-radius: var(--border-corner); } }
  &_item {
    @media(min-width: 1241px) {
      display: flex;
      gap: 16px;
      flex: 0 0 33%; } }
  .item-grade,
  .item-advantages {
    .footer--widget_item--title {
      @media(min-width: 1241px) {
        display: none; } } }
  .item-advantages {

    .item-advantages-slide {
      padding-right: 80px;
      justify-content: flex-end; }
    .swiper-button-next,
    .swiper-button-prev {
      color: #FFFFFF; }
    .swiper-button-prev {
      right: 15px;
      left: auto;
      &:after {
        font-size: 16px; } }
    .swiper-button-next {
      right: -5px;
      &:after {
        font-size: 16px; } } } }



.footer--widget.fixed {
  .footer--widget_container {
    position: static;
    translate: 0 0; } }

.footer--widget:not(.fixed) {
  .footer--widget_container.is_scrolled {
    background-color: rgba(5, 10, 23, .3); } }

.popup,
.footer--widget {
  .footer--widget_container {
    color: var(--c-text-white);
    .item-temperature {
      justify-content: center;
 }      //.item.item-grade
    .item-advantages {
      //justify-content: end
      overflow: hidden;
      position: relative;
      .footer--widget_item--content {
        @media(max-width: 1241px) {
          flex-direction: column; } }
      .item-advantages-slide {
        @media(min-width: 1241px) {
          opacity: 0;
          flex-shrink: 0;
          flex-grow: 0;
          display: flex;
          align-items: center;
          gap: 6px;
          overflow: hidden;
          transition: var(--transition) opacity; }
        .icon {
          color: #CFE1FF; } }
      .item-advantages-slide.swiper-slide-active {
        @media(min-width: 1241px) {
          opacity: 1; } } }
    span {
      white-space: nowrap;
      line-height: 1;
      font-size: var(--fs-16); } }

  &_item--title {
    @media(max-width: 1240px) {
      margin-bottom: 8px;
      font-weight: 500; } }
  &_item--content {
    display: flex;
    gap: 16px; }
  .item-grade_item {
    display: flex;
    gap: 8px;
    align-items: center; }
  .icon-star {
    color: #FDA95C;
    font-size: 1.3em; } }

.info-widjet {
  display: none;
  @media(max-width: 1240px) {
    transition: var(--transition);
    display: block;
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 0;
    a {
      transform: rotate(-90deg) translateY(-100%);
      transform-origin: top right;
      font-size: 14px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 4px;
      background-color: var(--c-surface-accent);
      color: var(--c-text-white);

      border-radius: 8px 8px 0 0; }
    .icon {
      rotate: -90deg; } } }
