/* Common styles for button */
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  width: fit-content;
  padding: 12px var(--g-elements);
  font-size: clamp(14px, calc(14px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 8px;

  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  transition: var(--transition);
  span {
    color: inherit;
    line-height: 1.1;
    text-align: center;
    position: relative;
    z-index: 2;
    font-weight: 600;
    white-space: nowrap; }

  span[class^="icon-"],
  span[class*=" icon-"],
  svg[class^="icon"] {
    color: inherit;
    width: 1em;
    height: 1em;
    line-height: 1.1;
    aspect-ratio: 1 / 1;
    transition: var(--transition);
    display: flex;
    &:only-child {
      margin: 0 -11px; } } }


/* Button appearance */

.button.button-filled {
  background: var(--filled-bgc-base);
  border-color: var(--filled-bc-base);
  color: var(--filled-c-base);
  border-radius: var(--border-corner);
  position: relative;
  &:hover,
  &:focus,
  &:active {
    background: var(--filled-bgc-hover);
    border-color: var(--filled-bc-hover);
    color: var(--filled-c-hover); } }

.button.button-outlined {
  background-color: var(--c-surface-transparent);
  border-color: var(--outlined-bc-base);
  color: var(--outlined-c-base);
  border-radius: var(--border-corner);
  &:hover,
  &:focus,
  &:active {
    background-color: var(--outlined-bgc-hover);
    border-color: var(--outlined-bc-hover);
    color: var(--outlined-c-hover); } }

.button.button-link {
  padding: 0;
  justify-content: flex-start;
  background: var(--c-surface-transparent);
  border-color: var(--c-surface-transparent);
  color: var(--link-c-base);
  border-radius: var(--border-corner);
  span {
    font-weight: 400; }
  &:visited {
    opacity: 0.8; }
  &:hover,
  &:focus,
  &:active {
    color: var(--link-c-hover); }
  .icon {
    transition: none; } }
/* Button  colors  */
.button.button-accent {
  --filled-bgc-base: var(--c-surface-accent);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-white);
  --filled-bgc-hover: var(--c-surface-accent-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-white);

  --outlined-bc-base: var(--c-text-accent);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-accent);
  --outlined-bc-hover: var(--c-surface-accent);
  --outlined-c-hover: var(--c-text-white);

  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered);
  &.button-dark {
    --filled-bgc-base: var(--c-surface-accent);
    --filled-bc-base: var(--c-surface-accent);
    --filled-c-base: var(--c-text-white);
    --filled-bgc-hover: var(--c-surface-accent-hovered);
    --filled-bc-hover: var(--c-surface-accent-hovered);
    --filled-c-hover: var(--c-text-white);

    --outlined-bc-base: var(--c-text-accent);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-accent);
    --outlined-bc-hover: var(--c-surface-accent);
    --outlined-c-hover: var(--c-text-white);

    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-text-accent);
    --link-c-hover: #96C9FF; } }

.button.button-primary {
  --filled-bgc-base: var(--c-surface-subdued);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-accent);
  --filled-bgc-hover: var(--c-surface-subdued-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-accent);

  --outlined-bc-base: var(--c-text-disabled);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-subdued-hovered);
  --outlined-bc-hover: var(--c-surface-subdued-hovered);
  --outlined-c-hover: var(--c-text-accent);

  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered);
  &.button-dark {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: var(--c-surface-subdued-hovered);
    --filled-bc-hover: var(--c-surface-transparent);
    --filled-c-hover: var(--c-text-accent);

    --outlined-bc-base: var(--c-surface-default);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-subdued-hovered);
    --outlined-bc-hover: var(--c-surface-subdued-hovered);
    --outlined-c-hover: var(--c-text-accent);

    --link-bgc-hover: var(--c-surface-default);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-white);
    --link-c-hover: var(--c-text-accent); } }

.button.button-secondary {
  --filled-bgc-base: var(--c-surface-subdued);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-disabled);
  --filled-bgc-hover: var(--c-surface-subdued-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-accent);

  --outlined-bc-base: var(--c-surface-subdued);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-subdued-hovered);
  --outlined-bc-hover: var(--c-surface-subdued-hovered);
  --outlined-c-hover: var(--c-text-accent);

  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-disabled);
  --link-c-hover: var(--c-text-accent);
  &.button-dark {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: var(--c-surface-subdued-hovered);
    --filled-bc-hover: var(--c-surface-transparent);
    --filled-c-hover: var(--c-text-accent);

    --outlined-bc-base: var(--c-surface-opacity);
    --outlined-c-base: var(--c-surface-opacity);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-text-white);
    --outlined-c-hover: var(--c-text-white);

    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-surface-default);
    --link-c-hover: var(--c-surface-opacity); } }

.block-medicine,
.page-medicine {
  .button.button-accent {}
  --filled-bgc-base: var(--c-surface-accent);
  --filled-bc-base: var(--c-surface-accent);
  --filled-c-base: var(--c-text-white);
  --filled-bgc-hover: var(--c-surface-accent-hovered);
  --filled-bc-hover: var(--c-surface-accent-hovered);
  --filled-c-hover: var(--c-text-white);

  --outlined-bc-base: var(--c-text-accent);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-accent);
  --outlined-bc-hover: var(--c-surface-accent);
  --outlined-c-hover: var(--c-text-white);

  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered);

  &.button-dark {
    --filled-bgc-base: var(--c-surface-transparent);
    --filled-bc-base: var(--c-surface-accent);
    --filled-c-base: var(--c-text-white);
    --filled-bgc-hover: var(--c-surface-accent-hovered);
    --filled-bc-hover: var(--c-surface-accent-hovered);
    --filled-c-hover: var(--c-text-white);

    --outlined-bc-base: var(--c-surface-accent);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-surface-accent);
    --outlined-c-hover: var(--c-text-white);

    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-accent);
    --link-c-hover: #96C9FF; }

  .button.button-primary {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: #C6F3BD;
    --filled-bc-hover: #C6F3BD;
    --filled-c-hover: var(--c-text-accent);

    --outlined-bc-base: var(--c-surface-accent);
    --outlined-c-base: var(--c-surface-accent);
    --outlined-bgc-hover: var(--c-surface-subdued);
    --outlined-bc-hover: var(--c-surface-subdued);
    --outlined-c-hover: var(--c-text-accent);

    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-text-disabled);
    --link-c-hover: var(--c-surface-accent);
    &.button-dark {
      --filled-bgc-base: var(--c-surface-subdued-hovered);
      --filled-bc-base: var(--c-surface-subdued-hovered);
      --filled-c-base: var(--c-surface-accent);
      --filled-bgc-hover: var(--c-surface-primary);
      --filled-bc-hover: var(--c-surface-primary);
      --filled-c-hover: var(--c-text-accent);

      --outlined-bc-base: var(--c-surface-default);
      --outlined-c-base: var(--c-surface-default);
      --outlined-bgc-hover: var(--c-surface-subdued);
      --outlined-bc-hover: var(--c-surface-subdued);
      --outlined-c-hover: var(--c-surface-accent);

      --link-bgc-hover: var(--c-surface-default);
      --link-bc-hover: var(--c-surface-default);
      --link-c-base: var(--c-text-white);
      --link-c-hover: var(--c-text-accent); } }

  .button.button-secondary {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-subdued);
    --filled-c-base: rgba(104, 189, 91, 0.5);
    --filled-bgc-hover: var(--c-surface-subdued);
    --filled-bc-hover: var(--c-surface-subdued);
    --filled-c-hover: var(--c-text-accent);

    --outlined-bc-base: var(--c-text-disabled);
    --outlined-c-base: var(--c-text-disabled);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-surface-subdued-hovered);
    --outlined-c-hover: var(--c-text-accent);

    --link-bgc-hover: var(--c-surface-default);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-disabled);
    --link-c-hover: var(--c-text-accent);
    &.button-dark {
      --filled-bgc-base: var(--c-surface-subdued-hovered);
      --filled-bc-base: var(--c-surface-transparent);
      --filled-c-base: var(--c-surface-subdued);
      --filled-bgc-hover: var(--c-surface-subdued-hovered);
      --filled-bc-hover: var(--c-surface-subdued-hovered);
      --filled-c-hover: var(--c-surface-accent);

      --outlined-bc-base: var(--c-surface-primary-hovered);
      --outlined-c-base: var(--c-surface-primary-hovered);
      --outlined-bgc-hover: var(--c-surface-transparent);
      --outlined-bc-hover: var(--c-text-white);
      --outlined-c-hover: var(--c-text-white);

      --link-bgc-hover: var(--c-surface-transparent);
      --link-bc-hover: var(--c-surface-transparent);
      --link-c-base: var(--c-surface-default);
      --link-c-hover: var(--c-surface-opacity); } } }
