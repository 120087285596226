
.article {
  .block--content {
    margin-top: 0; }
  .block--wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0  var(--g-content_cards);
    @media(min-width: 768px) {
      grid-template-columns:  7fr 4fr; } }

  article {
    margin: var(--g-section-inner) 0; }
  .block--head {
    margin-bottom: var(--g-32); }
  .block--head_caption {
    font-family: 'caveat' , sans-serif;
    font-size: var(--fs-28); }
  article {
    .f-h1,
    .f-h2 {
      font-size: clamp(32px, calc(32px + (64 - 32) * ((100vw - 1024px) / (1920 - 1024))), 64px); }
    .block--head {
      max-width: 100%; }
    .block--content {
      max-width: var(--container);
      --container: 900px;
      @media(max-width: 992px) {
        --container: calc(100vw - 32px); } } }
  .block--content {
    grid-column: 1; }
  .content_subscription {
    height: max-content;
    margin: 0 -16px;
    @media(min-width: 768px) {
      background-color: transparent;
      margin: 0; }
    .block--wrapper {
      grid-template-areas: 'content' 'img';
      @media(min-width: 768px) {
        padding: 0;
        grid-template-columns: 1fr; }
      .block--elements {
        @media(min-width: 768px) {
          grid-template-columns: 1fr; } }
      .block--content {
        @media(min-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          background-color: var(--bck-color);
          padding: var(--g-section-inner) var(--g-content); }
        .block--head {
          &_title {
            h3 {
              font-size: var(--fs-36); } } }
        .block--text {
          padding: 8px 0;
          p {
            padding: 8px 0;
            font-size: var(--fs-20);
            color: #9198B5; } } }
      .block--actions {
        @media(max-width: 768px) {
          width: 100%; } }
      .block--image .people {
        top: 2px;
        @media(max-width: 768px) {
          top: auto; } } } } }

.content_article--sidebar {
  @media(max-width: 768px) {
    grid-row: 2;
    margin: var(--g-32) 0; } }
.content_article--sidebar_sticky {
  background-color: var(--bck-color);
  border-radius: var(--border-corner);
  padding: var(--g-24) var(--g-32);
  grid-row: 1;
  @media(min-width: 768px) {
    grid-row: auto; }
  @media(min-width: 992px) {
    position: sticky;
    top: calc(var(--headerHeight) + 40px); }
  strong {
    font-size: var(--fs-24);
    //padding-bottom: 1em
    font-family: 'arsenica', sans-serif;
    line-height: 1.17;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    display: block; }
  .sidebar_nav {
    list-style: none;
    margin: 24px 0 0;
    display: flex;
    flex-direction: column;
    &--item {
      position: relative;
      font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024))), 20px);
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 18px;
      opacity: 0.6;
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        min-width: 10px;
        background-color: transparent;
        border-radius: 50%; }
      &.is_active {
        opacity: 1;
        &:before {
          background-color: var(--c-text-accent); } }
      &:before {
        @media(max-width: 768px) {
          background-color: var(--c-text-accent); } }
      &:hover,
      &:focus,
      &:active {
        opacity: 1; } } } }
