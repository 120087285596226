.popup {
  background-color: rgba(0,0,0,0.35);
  padding: var(--g-content, 40px) var(--g-elements);
  @media(max-width: 768px) {
    padding: var(--g-content, 40px) var(--g-elements) 100px;
    height: -webkit-fill-available;
    height: calc(100 * var(--vh, 1vh)); }
  &--content {
    max-width: 780px;
    //background-color: #FFFFFF
    border-radius: var(--border-corner); }
  &--close {
    width: clamp(44px, calc(44px + (54 - 44) * ((100vw - 1024px) / (1920 - 1024))), 54px);
    height: clamp(44px, calc(44px + (54 - 44) * ((100vw - 1024px) / (1920 - 1024))), 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: var(--g-24);
    right: var(--g-24);
    z-index: 42;
    cursor: pointer;
    transition: 0.3s ease-out;
    color: var(--c-text-subdued);
    .icon-close {
      font-size: 24px;
      color: #FFFFFF;
      justify-content: center;
      align-items: center; }
    @media(max-width: 768px) {
      left: unset;
      right: 16px;
      top: 16px; } } }

[data-popup="popup_for_vacancy_form"],
.popup-confirmation {
  .popup--content {
    position: relative;
    max-width: 702px;
    width: 100%;
    @media(min-width: 769px) {
      padding: var(--g-24) var(--g-32); }
    .popup--close {
      top: var(--g-24);
      right: var(--g-32); }
    .popup--close .icon {
      color: var(--c-surface-accent);
      font-weight: 400;
      opacity: 0.8;
      font-size: 1em; } } }

// Меню
.popup.popup-menu {
  width: 100%;
  max-width: 100vw;
  place-items: start;
  padding: 0;
  color: var(--c-text-white);
  background-color: #0B182A;
  pointer-events: none;
  @media(min-width: 769px) {
    //max-width: calc(100vw - var(--scrollbarWidth, 17px))
    //width: calc(100vw - var(--scrollbarWidth, 17px))
    padding-right: var(--scrollbarWidth, 17px); }
  .menu {
    max-width: var(--container);
    width: 100%;
    margin: auto; }
  .popup--content {
    border-radius: 0;
    background-color: #0B182A;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100vw;
    //padding-right: var(--scrollbarWidth, 17px)
    max-width: 100vw;
    margin: auto;
    @media(max-width: 768px) {
      width: 100%; } }
  &.active {
    z-index: 100; }
  .header--top {
    opacity: 0; }
  .menu--content {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0 70px;
    @media(min-width: 768px) {
      padding: 55px 0;
      grid-template-columns: 1fr 1fr; }
    h3 {
      font-weight: 300;
      font-size: var(--fs-56); }
    .menu--actions {
      .accordion--button {
        width: max-content;
        gap: var(--g-20); }
      .accordion--content > p {
        @media(max-width: 768px) {
          display: flex;
          flex-direction: column;
          gap: 16px; } }
      .accordion,
      .menu--actions_link {
        opacity: 0.6;
        margin-bottom: 20px; }
      .accordion.is_open {
        opacity: 1;
        .accordion--button .icon-medicine {
          rotate: 45deg; } }

      .accordion:hover,
      .menu--actions_link:hover {
        opacity: 1; }
      .accordion {
        a {
          border: none;
          margin-right: 0.5em; }
        .accordion--content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 0;
          &_item {
            display: inline-block; } } }
      .menu--actions_link a {
        display: flex;
        align-items: center;
        gap: var(--g-20);
        .icon {
          font-size: 0.5em;
          color: var(--c-text-accent); } } }
    .menu--offers {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--g-card);
      place-content: baseline;
      @media(max-width: 1024px) {
        display: none; } } } }
.popup.popup-menu.active {
  pointer-events: auto; }

// Попап схемы
.popup.popup-scheme {
  background-color: transparent;
  @media(min-width: 768px) {
    //position: absolute
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    max-width: var(--container);
    top: 100px;
    left: 50%;
    z-index: 10;
    translate: -50% 0; }
  .popup--content {
    max-width: 320px;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    @media(min-width: 768px) {
      max-width: 500px; }
    &_image {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      aspect-ratio: 1/1;
      @media(min-width: 768px) {
        aspect-ratio: 500/320; }
      img {
        aspect-ratio: 340/320;
        @media(min-width: 768px) {
          aspect-ratio: 500/320; } } }
    &_description {
      background-color: #FFFFFF;
      padding: var(--g-24);
      .button {
        margin-top: 16px; } } }
  .popup--content_title {
    font-size: var(--fs-32);
    margin-bottom: 20px; }
  .popup--close {
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--border-corner);
    .icon-close:before {
      color: #FFFFFF;
      font-size: 0.6em;
      @media(min-width: 769px) {
        font-size: 1em; } } } }


//попап яндекс карт
.popup.popup-balloon {
  @media(min-width: 768px) {
    max-width: 500px;
    position: absolute;
    right: 20px;
    bottom: unset;
    top: 50px;
    left: unset;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    z-index: 10;
    width: 100%; }
  .popup--content {
    position: relative;
    overflow: hidden;
    max-width: 340px;
    width: 100%;
    @media(min-width: 768px) {
      max-width: 512px; } }

  .popup--close {
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--border-corner); }
  .icon-close {
    color: #FFFFFF;
    height: 1em;
    width: 1em; }
  .baloon {
    background-color: #FFFFFF;
    .balloon--image {
      aspect-ratio: 1/1;
      @media(min-width: 768px) {
        aspect-ratio: 500/320; }
      img {
        aspect-ratio: 1/1;
        @media(min-width: 768px) {
          aspect-ratio: 500/320; } } }
    .balloon--content {
      padding: var(--g-24); }
    .balloon--title {
      font-size: var(--fs-32);
      font-family: 'arsenica', sans-serif;
      margin-bottom: 20px; }
    .balloon--text {
      text-overflow: ellipsis;
      line-height: 1.4;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical; }
    .button {
      margin-top: 16px; } } }

//попап виджет инфо
.popup.popup-info_wigjet {
  .popup--content {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    width: 100%;
    //.popup--content-description
    display: flex;
    flex-direction: column;
    background: rgba(5, 10, 23, .3);
    backdrop-filter: blur(8px);
    z-index: 10;
    padding: 20px 24px;
    font-weight: 400;
    .footer--widget_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .item {
        font-size: 14px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: flex-start; }
      .item--title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: var(--c-text-white); }
      .item-advantages {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        .item-advantages-slide {
          display: flex;
          gap: 8px; } }
      .item .slider {
        gap: 16px;
        flex-direction: column;
        margin: 0; }
      .item-advantages {
        order: -1; } } }
  .popup--close {
    top: 0;
    right: 0; }
  .popup--close .icon {
    color: #FFFFFF;
    font-size: 1em;
    font-weight: 400;
    opacity: 0.8; } }

.popup-confirmation {
  .popup--content {
    position: relative;
    padding: var(--g-24) var(--g-32);
    max-width: 702px;
    width: 100%;
    background-color: var(--c-surface-default);
    @media(max-width: 768px) {
      min-height: 100%; }
    .popup--close {
      top: 0;
      right: 0; }
    .popup--content_title {
      font-size: var(--fs-28);
      font-weight: 700; }
    .popup--content_text {
      font-size: var(--fs-20);
      color: var(--c-text-subdued);
      line-height: 1.5;
      margin: 8px 0 var(--g-24); } } }
