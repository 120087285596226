.block--filters {
  .block--content {
    margin-top: 0;
    .block--sections {
      margin-top: 0; } }
  .filters {
    &--item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: var(--g-32); }
    &--item_title {
      font-weight: 600;
      font-size: 24px; }
    label {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .custom-checkbox {
        position: relative;
        width: 20px;
        height: 20px;
        background-color: transparent;
        z-index: 1;
        border: var(--c-text-disabled) 2px solid;
        border-radius: 2px; }
      .custom-checkbox:after {
        content: '';
        z-index: -1;
        top: 50%;
        left: 50%;
        translate: (-50% -50%);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: var(--transition);
        background-image: url("../img/checkbox.svg");
        z-index: 1;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
      input {
        display: none; }

      & {
        input:checked + .custom-checkbox {
          background-color: #7BBAFF; }
        input:checked + span:after {

          opacity: 1; } } } } }



