.content_diseases {
  .block--content {
    display: grid;
    grid-template-columns: 1fr;

    gap: 40px;
    @media(min-width: 768px) {
      grid-template-columns: 1fr 2fr; }
    .block--search {
      background-color: var(--bck-color);
      border-radius: var(--border-corner);
      height: min-content;
      padding: var(--g-32) var(--g-content);
      .search-input form {
        padding: 0 12px;
        margin-bottom: var(--g-32);
        border-radius: var(--border-corner);
        background-color: #FFFFFF;
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        input,
        input:focus,
        input:active {
          outline: none;
          border: none;
          width: 100%; }
        .icon-search {
          display: inline-block;
          width: 16px;
          height: 16px;
          transition: var(--transition);
          background: url("../img/search.svg") no-repeat center; }
        input.close + .icon-serach {
          background: url("../img/icon/close.svg") no-repeat center; }
        button {
          display: none;
          font-size: 14px; } }

      .search-alphabet {
        display: grid;
        grid-template-columns: repeat(7 , 1fr);
        @media(min-width: 475px) {
          grid-template-columns: repeat(12 , 1fr); }
        [data-button] {
          cursor: pointer;
          padding: 5px 10px;
          transition: var(--transition);
          text-align: center; }
        [data-button].active,
        [data-button]:hover {
          background-color: #FFFFFF;
          border-radius: var(--border-corner); } } }

    .block--diseases-list {
      display: flex;
      flex-wrap: wrap;
      gap: var(--g-content);
      a {
        transition: var(--transition); }
      a:hover {
        text-decoration: underline; } }
    .diseases-list_item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media(min-width: 769px) {
        margin-bottom: 20px; } }
    .diseases-list_item.hidden {
      display: none; }
    .diseases-list_item-title {
      font-family: 'arsenica', sans-serif;
      font-size: var(--fs-44);
      color: var(--c-text-accent); } }
  .diseases_not_found {
    display: none;
    grid-column: span 3;
    div {
      font-family: 'arsenica', sans-serif;
      font-size: var(--fs-44);
      color: var(--c-text-accent);
      margin-bottom: 20px; } } }
