.block {
  color: var(--c-text-dark);
  margin: var(--g-section) 0;
  &--wrapper {
    max-width: var(--container);
    margin: 0 auto; }
  &--content,
  &--elements {
    margin-top: var(--g-content); } }

.block--content {
  & > img {
    aspect-ratio: 1880 / 900;
    height: auto; } }


//Бэкграунд блоков
.block-background-color {
  padding: var(--g-section) 0;
  margin: 0;
  background-color: var(--bck-color); }
