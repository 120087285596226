@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot');
  src:  url('../fonts/icomoon/fonts/icomoon.eot') format('embedded-opentype'), url('../fonts/icomoon/fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon/fonts/icomoon.woff') format('woff'),  url('../fonts/icomoon/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-ya:before {
  content: "\e934"; }

.icon-check:before {
  content: "\e920"; }

.icon-conditioner:before {
  content: "\e921"; }

.icon-kitchen:before {
  content: "\e922"; }

.icon-pool:before {
  content: "\e923"; }

.icon-toilet:before {
  content: "\e924"; }

.icon-wifi:before {
  content: "\e925"; }

.icon-work-zone:before {
  content: "\e926"; }

.icon-gis:before {
  content: "\e900"; }

.icon-area:before {
  content: "\e901"; }

.icon-arrow_d:before {
  content: "\e902"; }

.icon-arrow_l:before {
  content: "\e903"; }

.icon-arrow_r:before {
  content: "\e904"; }

.icon-arrow_u:before {
  content: "\e905"; }

.icon-calendar:before {
  content: "\e906"; }

.icon-checkbox .path1:before {
  content: "\e907"; }

.icon-checkbox .path2:before {
  content: "\e908"; }

.icon-clock:before {
  content: "\e909"; }

//.icon-contacts:before
//  content: "\e90a"

.icon-dot:before {
  content: "\e90b"; }

.icon-dropdown:before {
  content: "\e90c"; }

.icon-file:before {
  content: "\e90d"; }

.icon-fire:before {
  content: "\e90e"; }

.icon-food:before {
  content: "\e90f"; }

.icon-Google:before {
  content: "\e910"; }

.icon-info:before {
  content: "\e911"; }

.icon-keyhole:before {
  content: "\e912"; }

.icon-link:before {
  content: "\e913"; }

.icon-medicine:before {
  content: "\e914"; }

.icon-menu-mob:before {
  content: "\e915"; }

.icon-phone:before {
  content: "\e916"; }

.icon-pin:before {
  content: "\e917"; }

.icon-search-mob:before {
  content: "\e918"; }

.icon-shine:before {
  content: "\e919"; }

.icon-star:before {
  content: "\e91a"; }

.icon-telegramm:before {
  content: "\e91b"; }

.icon-user:before {
  content: "\e91c"; }

.icon-viber:before {
  content: "\e91d"; }

.icon-whatsapp:before {
  content: "\e91e"; }

.icon-Yandex:before {
  content: "\e91f"; }

.icon-close:before {
  content: "\e927"; }

.icon-dzen:before {
  content: "\e928"; }

.icon-odnoklassniki:before {
  content: "\e929"; }

.icon-vk:before {
  content: "\e92a"; }

.icon-youtube:before {
  content: "\e92b"; }
.icon-advantages-icon-1:before {
  content: "\e92c"; }

.icon-advantages-icon-2:before {
  content: "\e92d"; }

.icon-advantages-icon-3:before {
  content: "\e92e"; }

.icon-advantages-icon-4:before {
  content: "\e92f"; }

.icon-advantages-icon-5:before {
  content: "\e930"; }

.icon-advantages-icon-6:before {
  content: "\e931"; }

.icon-advantages-icon-7:before {
  content: "\e932"; }

.icon-advantages-icon-8:before {
  content: "\e933"; }
