.content_programm {
  overflow: hidden;
  &:not(.block_list-slider) {
    .block--elements {
      grid-template-columns: repeat(4, 1fr);
      gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
      @media(max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr); }
      @media(max-width: 768px) {
        grid-template-columns: 1fr; } } } }

.page-title {
  font-size: var(--fs-title);
  text-align: center;
  margin-bottom: var(--g-content_cards); }
.content_schedule {
  overflow: hidden;
  .block--elements {
    //max-width: 100vw
 }    //width: 100vw
  .list {
    &_item {
      display: flex;
      flex-direction: column;
      gap: 16px; }
    &_title {
      p {
        font-size: var(--fs-18);
        font-weight: 600;
        span.today {
          color: var(--c-text-subtitle);
          margin-left: 8px; } }
      div {
        font-family: 'arsenica', sans-serif;
        font-size: var(--fs-36);
        margin-bottom: var(--g-24); } }
    &_content {
      display: grid;
      align-items: start;
      grid-template-columns: 0.5fr 1fr; }
    &_time {
      font-family: 'arsenica', sans-serif;
      font-size: var(--fs-32); }
    &_text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      span {
        font-size: 16px; }
      span:last-child {
        font-size: 14px;
        opacity: 0.8; } } }
  .slider_controls {
    display: none;
    @media(min-width: 769px) {
      display: flex;
      width: fit-content;
      left: unset;
      transform: translateY(calc(-100% - var(--g-40))); }
    .swiper-button-next,
    .swiper-button-prev {
      width: 100%;
      color: var(--c-text-accent); } } }


