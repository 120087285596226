.content_contacts,
.content_contacts-reversed {
  //max-width: 1350px
  //margin: var(--g-section) auto
  .block--content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media(min-width: 769px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'content map'; }

    .is_cascade {
      padding: var(--g-32) 0;
      @media(min-width: 769px) {
        max-width: 600px;
        margin: auto;
        grid-area: content; } }
    .block--head {
      h2 {
        margin-top: 0; } }
    .contact {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 0;
      position: relative;
      margin-bottom: 20px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0.3em;
        display: block;
        width: 100%;
        height: 2px;
        border-bottom: 1px dashed #404040;
        opacity: 0.4;
        z-index: -1; }
      &--title {
        color: rgba(31, 31, 31, 0.5);
        background-color: #fff;
        padding-right: 4px;
        z-index: 1;
        font-size: var(--fs-20);
        line-height: 1.4; }
      &--value {
        a, p {
          opacity: 0.9;
          background-color: #fff;
          padding-left: 4px;
          z-index: 1;
          font-size: var(--fs-20);
          line-height: 1.4;
          color: #2A2827;
          font-weight: 500;
          margin: 0; } } }
    .icon {
      display: inline-block;
      background-color: var(--bck-color);
      border-radius: var(--border-corner);
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      position: relative;
      &:before {
        position: absolute;
        inset: 0;
        color: var(--c-text-accent);
        font-size: xxx-large; }
      &:hover:before {
        color: var(--c-text-accent-subdued); } } } }


.map {
  grid-area: map;
  overflow: hidden;
  border-radius: var(--border-corner);
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  @media(max-width: 768px) {
    aspect-ratio: 380 /  280; }
  iframe,
  #map {
    width: 100%;
    height: 100%;
    min-height: 100%; } }


.content_contacts-reversed {
  .block--content {
    grid-template-areas: 'map content '; } }

