
.swiper-slide,
.card,
.gallery--item,
.banner-group .banner {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  flex-grow: 0;
  flex-shrink: 0; }
.swiper-wrapper {
  -webkit-transform-style: preserve-3d; }

.swiper-button-next,
.swiper-button-prev {
  cursor:  pointer;
  &:after {
    font-family: 'icomoon' !important; }
  &:not(.swiper-button-disabled):hover,
  &:not(.swiper-button-disabled):focus,
  &:not(.swiper-button-disabled):active {}
  &.swiper-button-disabled {
    opacity: 0.2; } }

.swiper-button-prev {
  &:after {
    content: "\e903"; } }

.swiper-button-next {
  &:after {
    content: "\e904"; } }


.swiper-pagination-bullets {
  --swiper-pagination-bottom: 12px;
  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {} } }


.slider_controls {
  position: absolute;
  width: var(--container);
  justify-content: flex-end;
  display: flex;
  gap: 12px;
  align-items: center;
  right: 0;
  bottom: 0;
  z-index: 1;
  @media(max-width: 1472px) {
    width: calc(100vw - 160px);
    left: 80px; }
  .swiper-button-next,
  .swiper-button-prev {
    margin: 0;
    position: static;

    &:after {
      background-color: var(--c-surface-subdued);
      transition: var(--transition);
      font-size: 1em;
      padding: 14px;
      border-radius: 8px;
      opacity: 0.6;
      color: var(--c-text-accent); }
    &:hover:after,
    &:focus:after,
    &:active:after {
      opacity: 1;
      background-color: var(--c-surface-subdued-hovered); } } }

