.block--head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &_subtitle {
    width: fit-content;
    font-family: 'caveat', sans-serif;
    font-size: var(--fs-28);
    color: var( --c-text-accent-subdued);
    &:empty {
      display: none; }
    p {
      margin-bottom: 8px;
      &:empty {
        display: none; } } }
  &_caption {
    //@media(min-width: 768px)
    //  max-width: 80%
    p {
      line-height: 1.4;
      max-width: 700px;
      font-size: var(--fs-20);
      color: var( --c-text-accent-subdued);
      margin-top: 16px;
      &:empty {
        display: none; } } }
  &_title {
    //width: 100%
    line-height: 1;
    //margin-bottom: 16px
    @media(max-width: 768px) {
      max-width: 90%;
      word-break: break-word; }
    h1 {
      font-weight: 300;
      font-size: var(--fs-title); }
    h2 {
      font-weight: 300;
      font-size: var(--fs-56); }
    a {
      h1 {
        font-size: var(--fs-title); }
      h2 {
        font-size: var(--fs-56); }
      h1, h2 {
        position: relative;
        &:after {
          content: "\e913";
          font-family: 'icomoon' !important;
          font-size: 0.6em;
          color: currentColor;
          padding-left: 0.2em;
          color: var(--c-surface-accent);
          opacity: 1;
          transition: 0.25s linear;
          display: inline-block;
          @media(max-width: 768px) {
            position: static; } } }
      &:hover,
      &:focus,
      &:active {
        h1,h2 {
          &:after {
            transform: translateX(15%);
            opacity: 0.6; } } } } } }

