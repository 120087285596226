html {
  --container: 1600px;
  @media(max-width: 1630px) {
    --container: calc(100vw - 80px); }
  @media(max-width: 992px) {
    --container: calc(100vw - 32px); }

  --c-surface-accent: #7BBAFF;
  --c-surface-accent-hovered: #5A9DE7;
  --c-surface-subdued: rgba(227, 241, 255, 0.5);
  --c-surface-subdued-hovered: #D9ECFF;
  --c-surface-primary: #e4e4e4;
  --c-surface-primary-hovered: #b6b4b2;
  --c-text-disabled: rgba(104, 170, 242, 0.6);
  --c-surface-default: #fff;
  --c-surface-transparent: transparent;
  --c-surface-opacity: rgba(255, 255, 255, 0.6);

  --background-primary: #0B182A;

  --bck-color: #EDF6FF;

  --c-text-dark: #32374D;
  --c-text-subdued: #555554;
  //--c-text-disabled: rgba(104, 170, 242, 0.3)
  --c-text-white: #FFFFFF;
  --c-text-accent: #68AAF2;
  --c-text-accent-subdued: #7FA6D0;
  --c-text-subtitle: #7FA6D0;
  //--c-text-accent-hovered: #2e4644

  --c-text-secondary:  #A6C8FF;
  --c-success: #008060;
  --c-error: #D72C0D;

  --border-color: rgba(0, 0, 0, 0.03);
  --border-corner: 8px;

  --g-20: clamp(12px, calc(12px + (20 - 12) * ((100vw - 1024px) / (1920 - 1024))), 20px);
  --g-card: clamp(8px, calc(8px + (24 - 8) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-24: clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-32: clamp(16px, calc(16px + (32 - 16) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --g-elements: clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-content: clamp(24px, calc(24px + (42 - 24) * ((100vw - 1024px) / (1920 - 1024))), 42px);
  --g-section-inner: clamp(16px, calc(16px + (64 - 16) * ((100vw - 1024px) / (1920 - 1024))), 64px);
  --g-section: clamp(40px, calc(40px + (80 - 40) * ((100vw - 1024px) / (1920 - 1024))), 80px);
  --g-content_cards: clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px);

  --fs-16: clamp(14px, calc(14px + (16 - 14) * ((100vw - 1024px) / (1920 - 1024))), 16px);
  --fs-18: clamp(14px, calc(14px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  --fs-20: clamp(16px, calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024))), 20px);
  --fs-22: clamp(18px, calc(18px + (22 - 18) * ((100vw - 1024px) / (1920 - 1024))), 22px);
  --fs-24: clamp(18px, calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --fs-28: clamp(20px, calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024))), 28px);
  --fs-32: clamp(20px, calc(20px + (32 - 20) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --fs-36: clamp(24px, calc(24px + (36 - 24) * ((100vw - 1024px) / (1920 - 1024))), 36px);
  --fs-40: clamp(24px, calc(24px + (40 - 24) * ((100vw - 1024px) / (1920 - 1024))), 40px);
  --fs-44: clamp(28px, calc(28px + (44 - 28) * ((100vw - 1024px) / (1920 - 1024))), 44px);
  --fs-56: clamp(32px, calc(32px + (56 - 32) * ((100vw - 1024px) / (1920 - 1024))), 56px);
  --fs-title: clamp(40px, calc(40px + (92 - 40) * ((100vw - 1024px) / (1920 - 1024))), 92px);

  --transition: 0.18s ease-in-out; }

.block-medicine,
.page-medicine {
  --c-surface-accent: #68BD5B;
  --c-surface-accent-hovered: #53AE45;
  --c-surface-subdued: #D1F2CB;
  --c-surface-subdued-hovered: rgba(104, 189, 91, 0.6);
  --c-surface-primary: #C6F3BD;
  --c-surface-primary-hovered: #b6b4b2;
  --link-hover: #B5F9AB;
  --c-text-disabled: rgba(104, 189, 91, 0.6);
  --c-surface-default: #fff;
  --c-surface-transparent: transparent;

  --background-primary: #10170E;
  --bck-color: #E9F6E7;

  --c-text-accent: #68BD5B;
  --c-text-accent-subdued: #7D9F77;
  --c-text-dark: #043F2D;
  --c-text-secondary:  #68BD5B;

  --blue: #3A53D2; }
