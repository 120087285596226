.card {
  flex-shrink: 0;
  flex-grow: 0;
  opacity: 1; }

.card--title {
  transition: var(--transition);
  overflow: hidden; }

.card--image {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

// Акции
.content_offers {
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: var(--transition);
    background-color: var(--c-text-accent); }
  .card--image {
    aspect-ratio: 382 / 280;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    img {
      transition: var(--transition);
      scale: 1;
      transform-origin: center;
      width: 100%;
      height: 100%; } }
  .card--content {
    padding: var(--g-elements);
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    color: var(--c-text-white);
    flex-grow: 1;
    gap: var(--g-20);
    @media(max-width: 575px) {
      justify-content: center; } }
  .card--title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    h3 {
      font-size: var(--fs-44); } }
  .card--text {
    flex-grow: 1; }
  .card--actions {
    display: none;
    margin-top: auto;
    .button {
      padding: 0;
      overflow: inherit;
      span {
        font-size: var(--fs-18); }
      &:after {
        position: absolute;
        margin-left: 6px;
        content: "\e913";
        font-family: 'icomoon' !important;
        font-size: inherit;
        top: 0;
        right: 0;
        transform: translateX(24px);
        transition: 0.25s linear; } } }
  a.card {
    &:hover {
      .card--image img {
        scale: 1.1; }
      .button:after {
        transition: var(--transition) transform;
        transform: translateX(32px); } }
    .card--content {
      justify-content: space-between; }
    .card--actions {
      display: block; }
    .card--title {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; } } }

// Проживание и афиша
.content_accommodation,
.content_excursions,
.content_afisha {
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: var(--transition);
    transition: var(--transition);
    .card--image {
      aspect-ratio: 517 / 420;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(180deg, rgba(19, 21, 39, 0.5) 0%, rgba(19, 21, 39, 0) 98.97%);
        z-index: 1;
        pointer-events: none;
        opacity: 0.5;
        transition: var(--transition); }
      img {
        transition: var(--transition) scale;
        scale: 1;
        transform-origin: center;
        width: 100%;
        height: 100%; }
      .card-video {
        position: absolute;
        inset: 0;
        z-index: -1;
        opacity: 0;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; } }
      .card-video:empty {
          display: none; }
      .play {
        position: absolute;
        right: 20px;
        bottom: 20px;
        background: rgba(206, 206, 206, .15);
        backdrop-filter: blur(8px);
        z-index: 10;
        border-radius: 50%;
        padding: 15px;
        &:after {
          background: url("../img/play.svg") center no-repeat;
          background-size: contain;
          content: '';
          z-index: 10;
          position: relative;
          width: 22px;
          height: 22px;
          display: block;
          right: 0px;
          transition: var(--transition); } }
      .card-video.is_playing {
        z-index: 5;
        opacity: 1; }
      .card-video.is_playing ~ .play:after {
        background: url("../img/icons/close.svg") center no-repeat; }
      .images_hover {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        @media(max-width: 1024px) {
          pointer-events: none; } }
      .images_hover div {
        height: 100%;
        flex: 1 1 auto; } }
    .card--label {
      position: absolute;
      z-index: 2;
      color: var(--c-text-white);
      top: var(--g-32);
      left: var(--g-elements);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      p {
        position: relative;
        display: flex;
        align-items: center;
        letter-spacing: 0.002em;
        border-radius: 6px;
        padding: 6px 10px;
        overflow: hidden;
        background: rgba(206, 206, 206, .15);
        backdrop-filter: blur(8px);
        span {
          position: relative;
          z-index: 2;
          font-size: clamp(12px, calc(12px + (14 - 12) * ((100vw - 1024px) / (1920 - 1024))), 14px); }
        span.icon {
          margin-right: 8px; } } }
    //.card--overlay:hover + .card--image:after
    //  opacity: 1
    .card--overlay {
      position: absolute;
      z-index: 2;
      top: var(--g-32);
      right: var(--g-card);
      color: var(--c-text-white);

      .card--overlay__text {
        opacity: 0; }
      &:hover .card--overlay__text {
        opacity: 1;
        translate:  0 0; }
      &__icon {
        text-align: right;
        .icon {
          position: relative;
          padding: 8px;
          border-radius: 8px;
          //overflow: hidden
          display: inline-block;
          z-index: 2;
          background: rgba(206, 206, 206, .15);
          backdrop-filter: blur(8px); } }
      &__text {
        width: 500%;
        position: absolute;
        display: block;
        pointer-events: none;
        font-size: 14px;
        right: 0;
        top: 120%;
        padding: 8px;
        background: rgba(206, 206, 206, .15);
        backdrop-filter: blur(8px);
        border-radius: 6px;
        translate:  0 -15%;
        transition: var(--transition); } }

    .card--content {
      padding: 16px 0;
      //position: relative
      z-index: 3; }
    .card--title {
      position: relative;
      line-height: 1.5;
      font-size: var(--fs-44);
      line-height: 1em;
      h3 {
        font-size: var(--fs-44); } }
    .card--text {
      font-size: var(--fs-22);
      font-weight: 600;
      color: var(  --c-text-dark);
      margin-top: 16px;
      span {
        opacity: 0.5; } }
    .swiper-pagination-bullet {
      background-color: #FFFFFF;
      width: clamp(8px, calc(8px + (12 - 8) * ((100vw - 1024px) / (1920 - 1024))), 12px);
      height: clamp(8px, calc(8px + (12 - 8) * ((100vw - 1024px) / (1920 - 1024))), 12px);
      transition: var(--transition); }
    .swiper-pagination-bullet-active {
      scale: 1.4; } }
  a.card--title:hover {
    color: var(--c-text-accent); } }


.content_programm,
.content_services {
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: var(--transition);
    background-color: var(--bck-color);
    gap: 16px;
    padding: 12px 16px;
    @media(min-width: 768px) {
      padding: var(--g-card) var(--g-20); } }
  .card--content {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1; }
  .card--title {
    font-size: var(--fs-28);
    position: relative;
    h3 {
      font-size: var(--fs-36);
      font-weight: 700;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media(min-width: 768px) {
        min-height: 2.5em; }
      span {
        display: block; } } }
  .card--image {
    aspect-ratio: 342 / 360;
    overflow: hidden;
    img {
      transition: var(--transition);
      scale: 1;
      transform-origin: center;
      width: 100%;
      height: 100%; } }
  .card--text {
    p {
      font-size: 16px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:empty {
        display: none; } } }
  .card--actions {
    display: none;
    margin-top: auto;
    .button {
      padding: 0;
      overflow: inherit;
      span {
        font-size: var(--fs-18); }
      &:after {
        position: absolute;
        margin-left: 6px;
        content: "\e913";
        font-family: 'icomoon' !important;
        font-size: inherit;
        top: 0;
        right: 0;
        transform: translateX(24px);
        transition: 0.25s linear; } } }
  a.card {
    &:hover {
      box-shadow: 0px 4px 16px -10px var(--c-surface-accent);
      .card--image img {
        scale: 1.1; }
      .button:after {
        transition: var(--transition);
        transform: translateX(32px); }
      .card--title {
        color: var(--c-text-accent); } }
    .card--text {
      p {
        margin-bottom: var(--g-24); } }
    .card--actions {
      display: block; } } }


.content_personal,
.content_galleries,
.content_card {
  .card {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    transition: var(--transition);
    aspect-ratio: 166/220;
    @media(min-width: 769px) {
      aspect-ratio: 382 / 420; }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(19, 21, 39, 0.6) 0%, rgba(19, 21, 39, 0) 25.12%, rgba(19, 21, 39, 0) 42.02%, rgba(19, 21, 39, 0.6) 65.36%);
      z-index: 2;
      transition: 0.18s ease-in-out;
      opacity: 0.7; }
    .card--content {
      position: absolute;
      z-index: 3;
      bottom: var(--g-card);
      left: var(--g-card);
      right: var(--g-card);
      top: var(--g-card);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 8px; }
    .card--label {
      position: absolute;
      z-index: 3;
      color: var(--c-text-white);
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      @media(min-width: 768px) {
        //top: var(--g-32)
        //left: var(--g-card)
        gap: 8px; }
      p {
        position: relative;
        display: inline-block;
        background: rgba(206, 206, 206, .3);
        backdrop-filter: blur(8px);
        border-radius: 6px;
        padding: 5px 6px;
        @media(min-width: 768px) {
          padding: 6px 10px; }
        &:empty {
          display: none; }
        span {
          //text-overflow: ellipsis
          //max-width: 50px
          font-size: 12px;
          letter-spacing: 0.002em;
          overflow: hidden;
          z-index: 2;
          display: block;
          @media(min-width: 768px) {
            font-size: 14px; } } } }
    .card--title {
      font-size: var(--fs-36);
      font-weight: 700;
      color: var(--c-text-white);
      z-index: 3;

      h3 {
        font-size: var(--fs-36);
        @media(max-width: 768px) {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; } } }

    .card--image {
      aspect-ratio: 410 / 480;
      //border-radius: 8px
      overflow: hidden;
      @media(max-width: 768px) {
        border-radius: 16px;
        aspect-ratio: 160 / 220; }
      img {
        transition: var(--transition);
        scale: 1;
        transform-origin: center;
        width: 100%;
        height: 100%; } }
    .card--actions {
      display: none; } }
  a.card {
    &:hover {
      &:after {
        transition: 0.18s ease-in-out;
        opacity: 0.9; }
      .card--actions {
        opacity: 1;
        .button .icon:before {
          transform: translateX(6px); } }
      .button .icon:before {
        transition: var(--transition) transform; }
      .card--image img {
        scale: 1.1; } }
    .card--actions {
      opacity: 0.8;
      display: block; } } }

.content_advantages {
  .card {
    display: flex;
    gap: var(--g-elements);
    padding: var(--g-elements);
    @media(max-width: 768px) {
      flex-direction: column; }
    &--image {
      color: var(--c-text-accent);
      min-width: 30%;
      img {
        aspect-ratio: 120/120;
        max-width: 120px;
        max-height: 120px;
        object-fit: contain; } }
    //&--content
    //  gap: 12px
    &--title {
      margin-bottom: 16px;
      h3 {
        font-size: var(--fs-32);
        font-weight: 300;
        span {
          display: block; } } }
    &--text {
      display: none;
      @media(min-width: 768px) {
        display: block;
        p {
          max-width: 85%;
          max-width: 85%;
          opacity: 0.8;
          font-size: 16px;
          line-height: 1.5; } } } } }



.content_sertificates {
  .card {
    .card--image {
      aspect-ratio: 382 / 278;
      overflow: hidden;
      background: #fff;
      img {
        object-fit: contain; } } } }

.review {
  border-radius: 8px;
  background: #EDF6FF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &--image {
    aspect-ratio: 410 / 516;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    position: relative;
    //@media(max-width: 768px)
    //  border-radius: 16px
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }
  &--author {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 8px 16px;
    margin-bottom: 12px;
    &_image {
      grid-column: 1;
      grid-row: span 2;
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100%;
      img {
        border-radius: 50%;
        width: 48px;
        height: 48px; } }
    &_name {
      font-weight: 700;
      font-size: var(--fs-20); }
    &_description {
      font-size: var(--fs-18);
      color: rgba(31, 31, 31, 0.5); } }
  &--info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px; }
  &--stars {
    display: flex;
    align-items: center;
    --inactive_stars: var(--c-text-disabled);
    --star-color:  var(--c-text-accent);
    .star-rate {
      height: 18px; } }
  &--platform {
    .icon {
      display: flex;
      color: var(--c-text-disabled); } }
  &--text {
    font-size: 16px;
    line-height: 1.6;
    color:  var(--c-text-dark-paragraph);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; }
  &--content {
    padding: 16px var(--g-32);
    display: flex;
    flex-direction: column;
    gap: 10px; }
  &--date {
    color: rgba(151, 86, 233, 0.6);
    font-size: var(--fs-18);
    line-height: 1; }
  & .button {
    @media (max-width: 768px) {
      display: none; } } }

.content_document {
  .card {
    background-color: #EDF6FF;
    border-radius: 12px;
    padding: 8px 12px;
    aspect-ratio: 382/256;
    @media (min-width: 769px) {
      padding: var(--g-elements); }
    &:hover {
      .card--title {
        opacity: 0.7; } }
    &--content {
      min-height: 132px;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      height: 100%;
      gap: 8px;
      //@media (min-width: 769px)
 }      //  min-height: 248px
    &--title {
      font-weight: 600;
      font-size: var(--fs-22);
      margin-bottom: 12px;
      overflow: hidden;
      p {
        display: -webkit-box;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }

    &--subtitle {
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 12px;
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical; } } }
    &--link {
      margin-top: auto; } } }


.menu--offers {
  .card {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    transition: var(--transition);
    display: flex;
    aspect-ratio: 378 / 320;
    max-width: 378px;
    max-height: 320px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(19, 21, 39, 0.6) 0%, rgba(19, 21, 39, 0) 25.12%, rgba(19, 21, 39, 0) 42.02%, rgba(19, 21, 39, 0.6) 65.36%);
      z-index: 2;
      transition: 0.18s ease-in-out;
      opacity: 0.8; }
    &:hover {
      &:after {
        transition: 0.18s ease-in-out;
        opacity: 1; }
      .card--image img {
        scale: 1.1; } }
    .card--content {
      position: absolute;
      z-index: 1;
      top: var(--g-card);
      left: var(--g-card);
      right: var(--g-card);
      bottom: var(--g-card);
      z-index: 3;
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
    .card--title {
      font-size: var(--fs-44);
      font-weight: 700;
      //color: var(--c-text-white)
      h3 {
        font-size: var(--fs-44); } }
    .card--actions {
      margin-top: auto;
      .button {
        padding: 0; } }

    .card--image {
      aspect-ratio: 378 / 320;
      border-radius: 8px;
      overflow: hidden;
      @media(max-width: 768px) {
        border-radius: 16px; }
      img {
        transition: var(--transition);
        scale: 1;
        transform-origin: center;
        width: 100%;
        height: 100%; } } } }


.content_vacancies {
  .card {
    width: 100%;
    border-radius: var(--border-corner);
    overflow: hidden;
    background-color: transparent;
    transition: var(--transition); }
  .card--content {
    padding: 16px var(--g-card);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    @media(max-width: 768px) {
      padding: 16px 0; } }
  .card--title {
    margin-bottom: 8px;
    line-height: 1.33;
    font-size: var(--fs-24);
    font-family: 'arsenica', sans-serif;
    position: relative; }
  a.card--title {
    display: flex;
    align-items: center;
    &:after {
      color: var(--c-text-accent);
      margin-left: 5px;
      content: "\e913";
      display: inline-block;
      font-family: 'icomoon' !important;
      font-size: inherit;
      top: 0;
      right: 0;
      transform: translateX(0);
      transition: 0.25s linear; }
    &:hover:after {
      transform: translateX(6px);
      opacity: 0.6; } }
  .card--text {
    display: flex;
    align-items: center;
    gap: 8px;
    @media(max-width: 768px) {
      flex-wrap: wrap; } }
  .icon {
    color: var(--c-text-accent); }
  .card--subtitle {
    font-size: 16px; }
  .card--actions {
    margin-top: 8px; } }


.card.hidden {
  display: none;
  opacity: 0; }


.block-background-color:not(.content_advantages) .card {
  background-color: #FFFFFF; }

