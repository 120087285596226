.content_faq {
  max-width: 1350px;
  margin: var(--g-section) auto;
  .block--wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    @media(min-width: 769px) {
      grid-template-columns: 1fr 1fr;
      gap: 20px; }
    .block--head {
      grid-column: 1;
      align-items: flex-start;
      @media(min-width: 769px) {
        align-items: center; } }
    .block--elements,
    .block--actions {
      grid-column: 2;
      @media(max-width: 768px) {
        grid-column: 1; } }
    .block--actions {
      padding: 0;
      grid-row: 2; }
    .block--actions:empty {
      display: none; }
    .block--elements {
      margin-top: 0;
      width: 100%;
      grid-template-columns: 1fr;
      gap: 0; } } }
