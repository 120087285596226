.is_cascade {
  h1, h2 {
    font-size: var(--fs-56);
    margin: var(--g-elements) 0; }
  h3 {
    font-size: var(--fs-36);
    margin: var(--g-elements) 0; }
  h4 {
    font-size: var(--fs-32);
    margin: var(--g-elements) 0; }
  h5, h6 {
    font-size: var(--fs-24);
    margin: var(--g-elements) 0; }
  & > p {
    font-size: var(--fs-20);
    line-height: 1.5;
    margin-top: var(--g-20); }

  a:not(.button) {
    color: var(--c-text-accent);
    font-size: var(--fs-20);
    margin: 0 0.5em;
    &:hover,
    &:active {
      color: var(--c-text-accent-hovered); }
    &:visited {
      opacity: 0.8; } }
  & > span {
    font-size: var(--fs-20);
    line-height: 1.5; }
  strong, b {
    font-size: var(--fs-24);
    font-weight: 700;
    line-height: 1.5; }
  & > ul, ol {
    margin: var(--g-24) 0;
    padding-left: var(--g-24);
    li {
      font-size: var(--fs-20);
      line-height: 1.5;
      &:not(:last-of-type) {
        margin-bottom: 16px; }
      &::marker {
        font-size: 0.8em; } } }
  figure {
    margin: var(--g-content) 0;
    img {
      aspect-ratio: 920 / 520;
      width: 100%; }
    figcaption {
      color: var(--c-text-disabled);
      font-size: 16px;
      margin-top: 16px; } }
  & > img,
  & > video,
  & > iframe,
  & > object {
    margin: var(--g-32) 0; }
  video, img, iframe, object {
    aspect-ratio: 1180 / 540;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    overflow: hidden; }
  .is_cascade__slider {
    aspect-ratio: 1180 / 540;

    .swiper-button-next,
    .swiper-button-prev {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      &::after {
        font-size: 18px; } } }
  blockquote {
    padding-left: 24px;
    border-left: 1px solid var(--c-surface-accent);
    p {
      font-size: var(--fs-20);
      line-height: 1.5; }
    span {
      display: block;
      font-size: 16px;
      color: var(--c-text-disabled);
      margin-top: 8px; } }
  .button_wrapper, .block--actions {
    display: flex;
    gap: 8px;
    margin: var(--g-32) 0; }
  .accordion {
    margin: var(--g-32) 0; }
  & p + .button,
  & ul + .button {
    margin-top: var(--g-32); }

  table {
    margin: var(--g-32) 0;
    border-collapse: collapse;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid rgba(104, 170, 242, .3);
    @media(min-width: 769px) {
      box-shadow: 0px 0px 0px 2px rgba(104, 170, 242, .3); }
    //margin: 0 -16px
    //padding: 0 16px
    tbody {
      @media(max-width: 768px) {
        max-width: var(--container);
        display: block;
        overflow-x: auto;
        position: relative;
        //inset: -2px
        z-index: 1; }
      tr:not(:first-of-type) td:first-of-type {
        background-color: rgb(246, 249, 255); }
      td:first-of-type {
        //position: sticky
        left: 0;
        min-width: 82px;
        padding-left: 16px;
        vertical-align: bottom;
        letter-spacing: -.02em;
        //border: 1px solid var(--c-text-disabled)
        @media(max-width: 768px) {
          max-width: 50%; } }
      tr:first-of-type {
        font-weight: 600;
        text-align: left;
        background-color: rgb(237, 242, 255); }
      td,th {
        border: 1px solid rgba(104, 170, 242, .3);
        font-size: 16px;
        padding: 10px 16px;
        //
        //@media(max-width: 768px)
 } } }        //  white-space: nowrap

  .note {
    padding: 0;
    & li {
      position: relative;
      display: flex;
      margin-left: 0;
      padding: 0;
      justify-content: space-between;
      gap: 20px;
      font-size: var(--fs-16);
      &:before {
        position: absolute;
        z-index: -1;
        top: -0.05em;
        left: 0;
        display: block;
        width: 100%;
        height: 1em;
        content: '';
        opacity: .4;
        border-bottom: 1px dashed #404040;
        z-index: 1; }
      & span,
      & p {
        background-color: #FFFFFF;
        z-index: 2; }
      & span {
        padding-right: 4px;
        font-weight: 700;
        @media(max-width: 998px) {
          width: min-content; }
        @media(max-width: 768px) {
          white-space: nowrap;
          width: auto; } }
      & p {

        padding-left: 4px;
        text-align: right; } } }
  & *:nth-child(1) {
    margin-top: 0; } }

.block-medicine {
  .is_cascade {
    table {
      border: 1px solid rgba(221, 247, 217,  1);
      @media(min-width: 769px) {
        box-shadow: 0px 0px 0px 2px rgba(221, 247, 217,  1); }
      thead, th {
        border: 1px solid rgba(221, 247, 217,  1); }
      tbody {
        tr:not(:first-of-type) td:first-of-type {
          background-color: rgb(241, 252, 240); }
        td:first-of-type {
          background-color: rgb(241, 252, 240); }
        tr:first-of-type {
          td {
            //background-color: rgba(221, 247, 217,  0.8)
            background-color: rgba(221, 247, 217,  1); } }
        td,th {
          border: 1px solid rgba(221, 247, 217,  1);
 } } } } }          //background-color: rgba(221, 247, 217,  0.4)

article {
  --container: 780px;
  @media(max-width: 992px) {
    --container: calc(100vw - 32px); } }
