.block--sections {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: var(--g-content) 0;
  position: relative;
  z-index: 2;
  @media(max-width: 768px) {
    overflow-x: auto;
    padding: 20px 16px;
    margin: 0 -16px;
    flex-wrap: nowrap;
    max-width: 100vw; }
  [data-button],
  .block--sections_item,
  a {
    text-align: center;
    white-space: nowrap;
    border-radius: 24px;
    border: 2px solid var(--c-text-disabled);
    padding: 12px 20px;
    font-size: var(--fs-18);
    opacity: 0.7;
    display: flex;
    align-items: center;
    transition: var(--transition);
    gap: 8px;
    &:hover,
    &:focus,
    &:active {
      opacity: 1; }
    &.active {
      opacity: 1;
      border: 2px solid var(--c-surface-accent-hovered); } }

  a[data-price] {
    .icon {
      transition: var(--transition);
      rotate: 0;
      color: var(--c-text-accent); } }
  a[data-price].active {
    .icon {
      transition: var(--transition);
      rotate: 180deg; } }
  //a[data-price].decreasing
  //  .icon
  //    transition: var(--transition)
  //    rotate: 180deg




  .actions {
    position: relative;
    // switch
    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 16px; }

    .switch input {
      display: none; }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(196, 207, 255, 0.4);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;
      border: 1px solid rgba(129, 136, 165, 0.8); }

    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      background-color: white;
      transition: var(--transition);
      border-radius: 50%; }

    input:checked + .slider {
      background-color: #68AAF2; }

    input:focus + .slider {
      box-shadow: 0 0 1px rgba(129, 136, 165, 1); }

    input:checked + .slider:before {
      left: 100%;
      translate: -100% -50%; }

    .info {
      .icon.icon-link {
        transition: var(--transition);
        rotate: 90deg; } }
    .info-opening {
      padding: 20px 0 0;
      pointer-events: none;
      opacity: 0;
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      transition: var(--transition);
      @media(max-width: 1024px) {
        padding: 0 0 20px;
        top: auto;
        right: auto;
        left: 0;
        bottom: 100%; }
      &-title h3 {
        font-size: var(--fs-36);
        margin-bottom: var(--g-content); }
      &-container {
        background-color: #FFFFFF;
        border-radius: var(--border-corner);
        box-shadow: 0px 3px 12px 0px #021F541F;
        padding: var(--g-32) var(--g-content); }
      &-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px; }
      &-item {
        display: flex;
        align-items: center; } }
    .info.active {
      .icon {
        rotate: -90deg; } }
    .info.active + .info-opening {
      pointer-events: auto;
      opacity: 1; } } }

