
.block_list {
  .block--elements {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: var(--container);
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
    @media(max-width: 768px) {
      grid-template-columns: 1fr; } }

  &.block_list-slider {
    position: relative;
    overflow: hidden;
    .block--elements {
      //display: flex
      position: relative;
      height: 100%;
      min-height: 100%;
      @media(min-width: 1024px) {
        overflow: hidden; }
      //@media(max-width: 1024px)
      //  max-width: 100vw
      //  width: 100vw
      .card {
        min-height: 100%;
        height: 100%; } }
    .slider_controls {
      pointer-events: none;
      left: unset;
      width: fit-content;
      @media(max-width: 998px) {
        display: none; }
      .swiper-button-prev,
      .swiper-button-next {
        width: 100%;
        pointer-events: auto; } } } }

