.breadcrumbs {
  --main-color: #8C9196;
  --non-active-color: var(--c-text-dark);
  --hover-color: #6D7175;
  padding: var(--g-content) 0;
  display: flex;
  align-items: center;
  gap: 8px;
  width: var(--container);
  margin: 0 auto;
  z-index: 45;
  @media(max-width: 768px) {
    flex-wrap: nowrap;
    width: 100vw;
    overflow-x: auto;
    padding: 20px 16px;
    margin: 0;
    justify-content: flex-start; }
  a {
    transition: var(--transition);
    position: relative;
    color: var(--main-color);
    white-space: nowrap;
    &:not(:last-child):after {
      content: "/";
      margin-left: 8px; }
    &:last-child {
      pointer-events: none;
      color: var(--non-active-color); }
    &:hover,
    &:focus,
    &:active {
      color: var(--hover-color); } } }

.header-transparent {
  & ~ .breadcrumbs {
    position: absolute;
    top: var(--headerHeight);
    left: calc( ( 100vw - var(--container) )/ 2);
    --main-color: rgba(255,255,255, 0.36);
    --non-active-color: #fff;
    --hover-color: rgba(255,255,255, 0.6);
    @media(max-width: 768px) {
      left: 0; } } }
