.content_personal {
  &:not(.block_list-slider) {
    .block--elements {
      grid-template-columns: repeat(4, 1fr);
      gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
      @media(max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr); }
      //@media(max-width: 768px)
 }      //  grid-template-columns: 1fr
    .block--pagination {
      grid-column: span 4;
      @media(max-width: 1024px) {
        grid-column: span 2; } } } }
