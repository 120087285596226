.content_scheme {
  overflow-x: hidden;
  .block--sections {
    position: absolute;
    top: 120px;
    left: 50%;
    max-width: var(--container);
    margin: auto;
    justify-content: space-between;
    translate: -50% 0;
    width: 100%;
    flex-wrap: nowrap;
    align-items: flex-start;
    //overflow-x: hidden
    @media(max-width: 1024px) {
      height: 100%;
      flex-direction: column;
      align-items: start;
      pointer-events: none;
      padding: 0;
      max-width: 100%; } } }

.scheme {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  @media (max-width: 1024px) {
    padding: var(--g-section) 0; }
  &--svg {
    aspect-ratio: 1920 / 960;
    margin-top: 140px;
    @media (max-width: 1024px) {
      overflow-x: auto;
      width: auto;
      max-width: 100vw;
      height: 70vh;
      svg {
        height: 70vh; } } } }

.scheme--filters {
  @media(min-width: 1024px) {
    flex-wrap: wrap; } }

.scheme--filters,
.scheme--actions {
  display: flex;
  gap: 16px;
  pointer-events: all;
  @media(max-width: 1140px) {
    overflow-x: auto;
    width: 100%;
    max-width: 100vw;
    padding: 0 16px; }
  button {
    pointer-events: all;
    text-align: center;
    white-space: nowrap;
    border-radius: 24px;
    border: 2px solid var(--c-text-disabled);
    padding: 12px 20px;
    font-size: var(--fs-18);
    opacity: 0.7;
    display: flex;
    align-items: center;
    transition: var(--transition);
    gap: 8px;
    &:hover,
    &:focus,
    &:active {
      opacity: 1; }
    &.active {
      opacity: 1;
      border: 2px solid var(--c-surface-accent-hovered); } } }

.scheme--actions {
  position: relative;

 }  // switch
.scheme--switch {
  font-size: var(--fs-18);
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 12px 20px;
  cursor: pointer;
  white-space: nowrap;
  opacity: .7;
  border: 2px solid var(--c-text-disabled);
  border-radius: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
  input {
    display: none; }

  input:checked + .scheme--slider {
    background-color: #68AAF2; }

  input:focus + .scheme--slider {
    box-shadow: 0 0 1px rgba(129, 136, 165, 1); }

  input:checked + .scheme--slider:before {
    left: 100%;
    translate: -100% -50%; } }
.scheme--slider {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 16px;
  cursor: pointer;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  border: 1px solid rgba(129, 136, 165, .8);
  border-radius: 34px;
  background-color: rgba(196, 207, 255, .4);
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0;
    top: 50%;
    translate: 0 -50%;
    background-color: white;
    transition: var(--transition);
    border-radius: 50%; } }


.scheme--info {
  .icon.icon-link {
    transition: var(--transition);
    rotate: 90deg; } }

.scheme--info_opening {
  padding: 20px 0 0;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  transition: var(--transition);
  @media(max-width: 1024px) {
    padding: 0 0 20px;
    top: auto;
    right: auto;
    left: 0;
    bottom: 100%; }
  &_title h3 {
    font-size: var(--fs-36);
    margin-bottom: var(--g-content); }
  &_container {
    background-color: #FFFFFF;
    border-radius: var(--border-corner);
    box-shadow: 0px 3px 12px 0px #021F541F;
    padding: var(--g-32) var(--g-content); }
  &_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px; }
  &_item {
    display: flex;
    align-items: center; }
  &.is_active {
    pointer-events: auto;
    opacity: 1;
    .icon {
      rotate: -90deg; } } }

.scheme .scheme--svg_distance {
  transition: var(--transition);
  opacity: 0; }

.scheme.is_showing_distance {
  .scheme--svg_distance {
    opacity: 1; }
  .scheme--svg .placemark {
    opacity: 0; } }

.placemark {
  transition: var(--transition);
  cursor: pointer;
  opacity: 1; }
.placemark.hidden {
  opacity: 0; }
.placemark {
  filter: drop-shadow(0 1px 6px rgba(255,255,255,0.5)); }
.placemark-entrance,
.placemark-entrance svg .placemark-color {
  fill: #3A53D2;
  stroke: #3A53D2; }
.placemark-accommodation,
.placemark-accommodation svg .placemark-color {
  fill: #5a9de7;
  stroke: #5a9de7; }
.placemark-food,
.placemark-food svg .placemark-color {
  fill: #FF5454;
  stroke: #FF5454; }
.placemark-administration,
.placemark-administration svg .placemark-color {
  fill: #B766E9;
  stroke: #B766E9; }
.placemark-entertainment,
.placemark-entertainment svg .placemark-color {
  fill: #FFE500;
  stroke: #FFE500; }
.placemark-receptionist,
.placemark-receptionist svg .placemark-color {
  fill: #FF8A00;
  stroke: #FF8A00; }
.placemark-medicine-procedures,
.placemark-medicine-procedures svg .placemark-color {
  fill: #1FC339;
  stroke: #1FC339; }
.placemark-parking,
.placemark-parking svg .placemark-color {
  fill: #686868;
  stroke: #686868; }
.placemark-pool,
.placemark-pool svg .placemark-color {
  fill: #24CBF0;
  stroke: #24CBF0; }
.placemark-medicine-center,
.placemark-medicine-center svg .placemark-color {
  fill: #2A952E;
  stroke: #2A952E; }


.scheme--tooltip {
  position: fixed;
  opacity: 0;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 6;
  max-width: 240px;
  transition: opacity 0.18s;
  pointer-events: none;
  p {
    color: #2A2827;
    font-size: var(--fs-16);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
  &.is_active {
    opacity: 1; } }

.scheme_images {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-section-inner);
  padding: var(--g-section-inner) 0;
  max-width: var(--container);
  margin: 0 auto;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3,1fr); }
  &--col {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  &--title {
    font-size: var(--fs-40); }
  &--text {
    font-size: var(--fs-20);
    color: var(--c-text-subtitle); }
  &--image {
    @media (min-width: 1024px) {
      margin: var(--g-section) 0; }
    &:first-child:not(:only-child) {
      margin-top: 0; }
    img {
      aspect-ratio: 477 / 640;
      width: 100%;
      height: auto; } } }

.scheme_article {
  padding-top: var(--g-section);
  position: relative;
  z-index: 2;
  //background: url("../img/scheme/bg.webp")
  //background-size: 50%
  //background-position: top right
  //background-repeat: no-repeat
  //background-attachment: scroll
  @media (max-width: 1024px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column; }
  &--bg {
    content: "";
    position: absolute;
    right: 0;
    top: -10%;
    display: block;
    width: 50vw;
    aspect-ratio: 1 / 1;
    height: auto;
    z-index: 1; }
  &--title {
    font-size: clamp(32px, calc(32px + (76 - 32) * ((100vw - 1024px) / (1920 - 1024))), 76px);
    line-height: 1.1;
    max-width: var(--container);
    margin: 0 auto;
    padding: var(--g-section) 0;
    z-index: 2;
    position: relative;
    @media (max-width: 1024px) {
      order: 1; } }
  &--text {
    font-family: "arsenica", sans-serif;
    font-weight: 300;
    font-size: var(--fs-40);
    text-align: right;
    max-width: var(--container);
    margin: var(--g-section-inner) auto;
    position: relative;
    z-index: 3;
    @media (min-width: 1024px) {
      transform: translateX(calc((100vw - var(--container)) / -2)); } }
  &--map {
    aspect-ratio: 1400 / 872;
    width: 1400px;
    position: relative;
    z-index: 4;
    @media (min-width: 1024px) {
      width: 1400px; }
    @media (max-width: 1024px) {
      left: -20vw;
      width: 150vw;
      order: 2; }

    svg {
      //transform-origin: 46.5% 84.51%
      //position: absolute
      //top: 0
      //left: 0
      overflow: visible !important; } }
  &--image {
    position: relative;
    z-index: 2;
    aspect-ratio: 677 / 416;
    @media (min-width: 1024px) {
      position: absolute;
      right: 0;
      width: 677px;
      margin-left: auto; }
    @media (max-width: 1024px) {
      order: 3;
      img {
        display: none; } } }
  & + .scheme {
 } }    //margin-top: 50vh

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  @media (max-width: 1024px) {
    display: none; }
  img {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: screen; } }

#zooming_placemark,
.scheme_article--map {
  pointer-events: none; }
