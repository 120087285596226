.accordion {
  cursor: pointer;
  position: relative;
  @media(max-width: 768px) {
    padding: 4px; }
  &:not(:last-child) {
    margin-bottom: var(--g-content); }
  &.is_open {
    .accordion--content {
      transition: 0.3s ease-out;
      opacity: 1;
      height: auto;
      pointer-events: auto;
      //display: block
      max-height: 100%;
      margin: var(--g-24) 0;
      padding: 16px 0; }
    .accordion--button {
      .icon {
        transform: rotate(180deg);
        transform-origin: center; } } }
  &--button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: var(--g-section-inner);
    .accordion--button_text {
      margin: 0;
      font-size: var(--fs-24);
      font-weight: 600;
      line-height: 1.3;
      max-width: 450px; }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      height: 1.33em;
      width: 1.33em;
      font-size: var(--fs-24);
      transform: rotate(0deg);
      transition: 0.18s ease-in-out;
      color: var(--c-surface-accent); } }
  &--content {
    //display: none
    opacity: 0;
    transition: height 0.2s 0.1s linear, opacity 0.2s 0.15s linear;
    height: 0;
    pointer-events: none;


    max-height: 0;
    overflow: hidden;
    //padding: 15px 0
    transition: all 0.5s;

    &.is_cascade > p:first-child {
      margin: 0; } } }

