.content_accommodation {
  &:not(.block_list-slider) {
    .block--elements {
      grid-template-columns: repeat(3, 1fr);
      gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
      @media(max-width: 1024px) {
        grid-template-columns: 1fr 1fr; }
      @media(max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 32px; } } } }

.page-accommodation-seo .content_accommodation {
  overflow: hidden;
  .block--wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @media(max-width: 768px) {
      grid-template-columns: 1fr; } }
  .block--sections {
    justify-content: space-between;
    @media(max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      //overflow: initial
      //margin: 16px 0
      //padding: 0
      overflow-x: initial;
      overflow-y: initial; }
    &_tabs {
      display: flex;
      gap: 8px;
      @media(max-width: 768px) {
        overflow-x: auto;
        max-width: 100vw;
        margin: 0 -16px;
        padding: 0 16px; } } }
  .block--elements {
    display: grid;
    grid-template-columns: 1fr;
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
    position: relative;
    z-index: 1; }
  .block--filters {
    .open--filters {
      display: block;
      width: 100%;
      text-align: center;
      @media(min-width: 768px) {
        display: none; } }
    form {
      @media(max-width: 768px) {
        display: none; } }
    .filters.is_open {
      form {
        display: block; } } }
  .block--sections_item.js-sorting {
    position: relative;
    margin-left: auto;
    .open-sort-list {
      translate:  0 -30%;
      opacity: 0;
      pointer-events: none;
      top: 120%;
      right: 0;
      padding: var(--g-20) var(--g-24);
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #FFFFFF;
      gap: var(--g-20);
      box-shadow: 0px 3px 12px 0px #021F541F;
      transition: var(--transition);
      border-radius: var(--border-corner);
      @media(max-width: 768px) {
        right: auto;
        left: 0; } }
    .icon {
      transition: var(--transition);
      rotate: 0;
      color: var(--c-text-accent); }
    &.active {
      .icon {
        transition: var(--transition);
        rotate: 180deg; }
      .open-sort-list {
        translate: 0 0;
        opacity: 1;
        pointer-events: auto; } } }


  .card--accommodation {
    opacity: 1;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-areas: 'img content';
    gap: var(--g-24);
    @media(max-width: 998px) {
      grid-template-columns: 1fr 1fr; }
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'img' 'content'; }
    .card--image {
      grid-area: img;
      img {
        border-radius: var(--border-corner);
        aspect-ratio: 343 / 240;
        @media(min-width: 769px) {
          aspect-ratio: 382 /320; } } }
    .card--content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      gap: var(--g-20);
      .card--title {
        font-size: var(--fs-32);
        font-family: 'arsenica', sans-serif; }
      .card--reviews {
        display: flex;
        gap: 8px;
        align-items: center;
        @media(max-width: 768px) {
          flex-wrap: wrap; }
        &_grade {
          white-space: nowrap;
          background-color: #F5F6FA;
          border-radius: var(--border-corner);
          padding: 6px; }
        &_review {
          white-space: nowrap;
          color: var(--c-text-subtitle); }
        &_yandex {
          white-space: nowrap;
          .icon:before {
            color: #FFFFFF;
            height: 11px;
            width: 7px;
            padding: 4px 7px 4px 6px;
            border-radius: 50%;
            background-color: #FC3F1D;
            margin-right: 8px;
            display: inline-block;
            font-size: 12px; } } }
      .card--info {
        ul {
          padding-left: 25px;
          li {
            &:not(:last-of-type) {
              margin-bottom: 8px; } }
          li::marker {
            font-size: 0.8em; } } }
      .card--actions {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: var(--fs-22);
        margin-top: auto;
        &-night {
          font-weight: 600;
          opacity: 0.6; } } } }

  .card--accommodation.hidden {
    opacity: 0;
    display: none; } }


// дет страница
.content_accommodation.block_element {
  .block--wrapper {
    margin-top: var(--g-content); }
  .block--content {
    grid-template-columns: 1fr 1fr;
    gap: var(--g-section-inner);
    display: grid;
    max-width: 1350px;
    align-items: start;
    margin: auto;
    @media(max-width: 768px) {
      //grid-template-columns: 1fr
      display: flex;
      flex-direction: column; }
    .booking {
      grid-column: 2;
      grid-row: auto;
      @media(min-width: 768px) {
        top: var(--headerHeight, 157px );
        margin: 0 40px;
        position: sticky; } }
    .is_cascade {
      grid-column: 1;
      grid-row: 1;

      ul {
        list-style-type: none;
        padding: var(--g-content) 0;
        li {
          position: relative;
          display: flex;
          gap: 16px 16px;
          &  * {
            line-height: 1; }
          strong {
            margin-bottom: 8px;
            display: inline-block; }
          .icon {
            color: var(--c-text-accent); } }
        li:not(:last-child) {
          margin-bottom: 16px; } } }
    .sleep-place {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px 12px;
      @media(max-width: 768px) {
        .sleep-place_item:nth-child(3n) {
          grid-column: span 2; } }
      &_item {
        padding: var(--g-elements) var(--g-32);
        border: 1px solid var(--c-text-disabled);
        border-radius: 12px; } }

    .comfort {
      padding: 20px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 20px;
      &_item {
        font-size: var(--fs-20);
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          font-size: 1.2em; } }
      &_item:nth-child(-n+7) {
        display: flex; }
      &_item:nth-child(n+7) {
        display: none; } }
    .comfort.active {
      .comfort_item:nth-child(n+7) {
        display: block; } }
    .button {
      margin-top: 16px; } }
  .js-show-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    transition: var(--transition); }
  .js-show-content.show {
    -webkit-line-clamp: unset; }
  .js-show-content .comfort_item:nth-child(n+7) {
    display: none; }
  .js-show-content.show .comfort_item:nth-child(n+7) {
    display: block; }
  .js-show-btn.active {
    .icon {
      rotate: 180deg; } } }

