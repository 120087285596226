html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

main {
  display: block; }

h1, h2, h3, h4, h5, h6 {
  font-size: 1em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden], .hidden {
  display: none; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

button {
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-decoration: none; }

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none; }

html {
  -webkit-tap-highlight-color: transparent; }

html {
  --container: 1600px;
  --c-surface-accent: #7BBAFF;
  --c-surface-accent-hovered: #5A9DE7;
  --c-surface-subdued: rgba(227, 241, 255, 0.5);
  --c-surface-subdued-hovered: #D9ECFF;
  --c-surface-primary: #e4e4e4;
  --c-surface-primary-hovered: #b6b4b2;
  --c-text-disabled: rgba(104, 170, 242, 0.6);
  --c-surface-default: #fff;
  --c-surface-transparent: transparent;
  --c-surface-opacity: rgba(255, 255, 255, 0.6);
  --background-primary: #0B182A;
  --bck-color: #EDF6FF;
  --c-text-dark: #32374D;
  --c-text-subdued: #555554;
  --c-text-white: #FFFFFF;
  --c-text-accent: #68AAF2;
  --c-text-accent-subdued: #7FA6D0;
  --c-text-subtitle: #7FA6D0;
  --c-text-secondary:  #A6C8FF;
  --c-success: #008060;
  --c-error: #D72C0D;
  --border-color: rgba(0, 0, 0, 0.03);
  --border-corner: 8px;
  --g-20: clamp(12px, calc(12px + (20 - 12) * ((100vw - 1024px) / (1920 - 1024))), 20px);
  --g-card: clamp(8px, calc(8px + (24 - 8) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-24: clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-32: clamp(16px, calc(16px + (32 - 16) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --g-elements: clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-content: clamp(24px, calc(24px + (42 - 24) * ((100vw - 1024px) / (1920 - 1024))), 42px);
  --g-section-inner: clamp(16px, calc(16px + (64 - 16) * ((100vw - 1024px) / (1920 - 1024))), 64px);
  --g-section: clamp(40px, calc(40px + (80 - 40) * ((100vw - 1024px) / (1920 - 1024))), 80px);
  --g-content_cards: clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px);
  --fs-16: clamp(14px, calc(14px + (16 - 14) * ((100vw - 1024px) / (1920 - 1024))), 16px);
  --fs-18: clamp(14px, calc(14px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  --fs-20: clamp(16px, calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024))), 20px);
  --fs-22: clamp(18px, calc(18px + (22 - 18) * ((100vw - 1024px) / (1920 - 1024))), 22px);
  --fs-24: clamp(18px, calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --fs-28: clamp(20px, calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024))), 28px);
  --fs-32: clamp(20px, calc(20px + (32 - 20) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --fs-36: clamp(24px, calc(24px + (36 - 24) * ((100vw - 1024px) / (1920 - 1024))), 36px);
  --fs-40: clamp(24px, calc(24px + (40 - 24) * ((100vw - 1024px) / (1920 - 1024))), 40px);
  --fs-44: clamp(28px, calc(28px + (44 - 28) * ((100vw - 1024px) / (1920 - 1024))), 44px);
  --fs-56: clamp(32px, calc(32px + (56 - 32) * ((100vw - 1024px) / (1920 - 1024))), 56px);
  --fs-title: clamp(40px, calc(40px + (92 - 40) * ((100vw - 1024px) / (1920 - 1024))), 92px);
  --transition: 0.18s ease-in-out; }
  @media (max-width: 1630px) {
    html {
      --container: calc(100vw - 80px); } }
  @media (max-width: 992px) {
    html {
      --container: calc(100vw - 32px); } }

.block-medicine,
.page-medicine {
  --c-surface-accent: #68BD5B;
  --c-surface-accent-hovered: #53AE45;
  --c-surface-subdued: #D1F2CB;
  --c-surface-subdued-hovered: rgba(104, 189, 91, 0.6);
  --c-surface-primary: #C6F3BD;
  --c-surface-primary-hovered: #b6b4b2;
  --link-hover: #B5F9AB;
  --c-text-disabled: rgba(104, 189, 91, 0.6);
  --c-surface-default: #fff;
  --c-surface-transparent: transparent;
  --background-primary: #10170E;
  --bck-color: #E9F6E7;
  --c-text-accent: #68BD5B;
  --c-text-accent-subdued: #7D9F77;
  --c-text-dark: #043F2D;
  --c-text-secondary:  #68BD5B;
  --blue: #3A53D2; }

@font-face {
  font-family: 'arsenica';
  font-style: normal;
  src: url("../fonts/arsenica/Ars-Light.woff") format("truetype"); }

@font-face {
  font-family: 'wix-madefor';
  font-weight: 125 950;
  font-style: normal;
  src: url("../fonts/wix-madefor/WixMadeforDisplay-VariableFont_wght.ttf") format("truetype"); }

@font-face {
  font-family: 'caveat';
  font-weight: 125 950;
  font-style: normal;
  src: url("../fonts/caveat/Caveat-VariableFont_wght.ttf") format("truetype"); }

html {
  font-size: clamp(16px, calc(16px + (18 - 16) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  line-height: 1.25;
  font-family: 'wix-madefor', sans-serif;
  color: var(--c-text-dark);
  text-size-adjust: none;
  -webkit-text-size-adjust: none; }

h1, h2, h3, h4 {
  font-family: 'arsenica', sans-serif;
  font-weight: 300; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/fonts/icomoon.eot");
  src: url("../fonts/icomoon/fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-ya:before {
  content: "\e934"; }

.icon-check:before {
  content: "\e920"; }

.icon-conditioner:before {
  content: "\e921"; }

.icon-kitchen:before {
  content: "\e922"; }

.icon-pool:before {
  content: "\e923"; }

.icon-toilet:before {
  content: "\e924"; }

.icon-wifi:before {
  content: "\e925"; }

.icon-work-zone:before {
  content: "\e926"; }

.icon-gis:before {
  content: "\e900"; }

.icon-area:before {
  content: "\e901"; }

.icon-arrow_d:before {
  content: "\e902"; }

.icon-arrow_l:before {
  content: "\e903"; }

.icon-arrow_r:before {
  content: "\e904"; }

.icon-arrow_u:before {
  content: "\e905"; }

.icon-calendar:before {
  content: "\e906"; }

.icon-checkbox .path1:before {
  content: "\e907"; }

.icon-checkbox .path2:before {
  content: "\e908"; }

.icon-clock:before {
  content: "\e909"; }

.icon-dot:before {
  content: "\e90b"; }

.icon-dropdown:before {
  content: "\e90c"; }

.icon-file:before {
  content: "\e90d"; }

.icon-fire:before {
  content: "\e90e"; }

.icon-food:before {
  content: "\e90f"; }

.icon-Google:before {
  content: "\e910"; }

.icon-info:before {
  content: "\e911"; }

.icon-keyhole:before {
  content: "\e912"; }

.icon-link:before {
  content: "\e913"; }

.icon-medicine:before {
  content: "\e914"; }

.icon-menu-mob:before {
  content: "\e915"; }

.icon-phone:before {
  content: "\e916"; }

.icon-pin:before {
  content: "\e917"; }

.icon-search-mob:before {
  content: "\e918"; }

.icon-shine:before {
  content: "\e919"; }

.icon-star:before {
  content: "\e91a"; }

.icon-telegramm:before {
  content: "\e91b"; }

.icon-user:before {
  content: "\e91c"; }

.icon-viber:before {
  content: "\e91d"; }

.icon-whatsapp:before {
  content: "\e91e"; }

.icon-Yandex:before {
  content: "\e91f"; }

.icon-close:before {
  content: "\e927"; }

.icon-dzen:before {
  content: "\e928"; }

.icon-odnoklassniki:before {
  content: "\e929"; }

.icon-vk:before {
  content: "\e92a"; }

.icon-youtube:before {
  content: "\e92b"; }

.icon-advantages-icon-1:before {
  content: "\e92c"; }

.icon-advantages-icon-2:before {
  content: "\e92d"; }

.icon-advantages-icon-3:before {
  content: "\e92e"; }

.icon-advantages-icon-4:before {
  content: "\e92f"; }

.icon-advantages-icon-5:before {
  content: "\e930"; }

.icon-advantages-icon-6:before {
  content: "\e931"; }

.icon-advantages-icon-7:before {
  content: "\e932"; }

.icon-advantages-icon-8:before {
  content: "\e933"; }

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

a[href], .button {
  cursor: pointer;
  text-decoration-skip-ink: none;
  transition: 0.3s ease-out; }

.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none; }

.hidden {
  display: none; }

.ux_col {
  padding: 180px;
  display: flex;
  flex-direction: column;
  gap: 24px; }

.ui_grid_head {
  margin: 24px auto; }

.ui_grid_buttons {
  max-width: 1440px;
  margin: 24px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 24px;
  padding: 24px; }
  .ui_grid_buttons_title {
    grid-column: span 3;
    margin: 48px 24px 24px; }
  .ui_grid_buttons .theme-dark {
    padding: 24px;
    background-color: black; }

.language_selector {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 50;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-corner) 0 0 var(--border-corner);
  overflow: hidden; }
  .language_selector--button {
    padding: 8px 12px;
    background-color: var(--c-surface-default);
    color: var(--c-text-accent); }
    .language_selector--button.is_active {
      background-color: var(--c-surface-accent);
      color: var(--c-text-white); }

html.lenis {
  height: auto; }

.lenis.lenis-smooth {
  scroll-behavior: auto; }

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain; }

.lenis.lenis-stopped {
  overflow: hidden; }

.lenis.lenis-scrolling iframe {
  pointer-events: none; }

/* Common styles for button */
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  width: fit-content;
  padding: 12px var(--g-elements);
  font-size: clamp(14px, calc(14px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  transition: var(--transition); }
  .button span {
    color: inherit;
    line-height: 1.1;
    text-align: center;
    position: relative;
    z-index: 2;
    font-weight: 600;
    white-space: nowrap; }
  .button span[class^="icon-"],
  .button span[class*=" icon-"],
  .button svg[class^="icon"] {
    color: inherit;
    width: 1em;
    height: 1em;
    line-height: 1.1;
    aspect-ratio: 1 / 1;
    transition: var(--transition);
    display: flex; }
    .button span[class^="icon-"]:only-child,
    .button span[class*=" icon-"]:only-child,
    .button svg[class^="icon"]:only-child {
      margin: 0 -11px; }

/* Button appearance */
.button.button-filled {
  background: var(--filled-bgc-base);
  border-color: var(--filled-bc-base);
  color: var(--filled-c-base);
  border-radius: var(--border-corner);
  position: relative; }
  .button.button-filled:hover, .button.button-filled:focus, .button.button-filled:active {
    background: var(--filled-bgc-hover);
    border-color: var(--filled-bc-hover);
    color: var(--filled-c-hover); }

.button.button-outlined {
  background-color: var(--c-surface-transparent);
  border-color: var(--outlined-bc-base);
  color: var(--outlined-c-base);
  border-radius: var(--border-corner); }
  .button.button-outlined:hover, .button.button-outlined:focus, .button.button-outlined:active {
    background-color: var(--outlined-bgc-hover);
    border-color: var(--outlined-bc-hover);
    color: var(--outlined-c-hover); }

.button.button-link {
  padding: 0;
  justify-content: flex-start;
  background: var(--c-surface-transparent);
  border-color: var(--c-surface-transparent);
  color: var(--link-c-base);
  border-radius: var(--border-corner); }
  .button.button-link span {
    font-weight: 400; }
  .button.button-link:visited {
    opacity: 0.8; }
  .button.button-link:hover, .button.button-link:focus, .button.button-link:active {
    color: var(--link-c-hover); }
  .button.button-link .icon {
    transition: none; }

/* Button  colors  */
.button.button-accent {
  --filled-bgc-base: var(--c-surface-accent);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-white);
  --filled-bgc-hover: var(--c-surface-accent-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-white);
  --outlined-bc-base: var(--c-text-accent);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-accent);
  --outlined-bc-hover: var(--c-surface-accent);
  --outlined-c-hover: var(--c-text-white);
  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered); }
  .button.button-accent.button-dark {
    --filled-bgc-base: var(--c-surface-accent);
    --filled-bc-base: var(--c-surface-accent);
    --filled-c-base: var(--c-text-white);
    --filled-bgc-hover: var(--c-surface-accent-hovered);
    --filled-bc-hover: var(--c-surface-accent-hovered);
    --filled-c-hover: var(--c-text-white);
    --outlined-bc-base: var(--c-text-accent);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-accent);
    --outlined-bc-hover: var(--c-surface-accent);
    --outlined-c-hover: var(--c-text-white);
    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-text-accent);
    --link-c-hover: #96C9FF; }

.button.button-primary {
  --filled-bgc-base: var(--c-surface-subdued);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-accent);
  --filled-bgc-hover: var(--c-surface-subdued-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-accent);
  --outlined-bc-base: var(--c-text-disabled);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-subdued-hovered);
  --outlined-bc-hover: var(--c-surface-subdued-hovered);
  --outlined-c-hover: var(--c-text-accent);
  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered); }
  .button.button-primary.button-dark {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: var(--c-surface-subdued-hovered);
    --filled-bc-hover: var(--c-surface-transparent);
    --filled-c-hover: var(--c-text-accent);
    --outlined-bc-base: var(--c-surface-default);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-subdued-hovered);
    --outlined-bc-hover: var(--c-surface-subdued-hovered);
    --outlined-c-hover: var(--c-text-accent);
    --link-bgc-hover: var(--c-surface-default);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-white);
    --link-c-hover: var(--c-text-accent); }

.button.button-secondary {
  --filled-bgc-base: var(--c-surface-subdued);
  --filled-bc-base: var(--c-surface-transparent);
  --filled-c-base: var(--c-text-disabled);
  --filled-bgc-hover: var(--c-surface-subdued-hovered);
  --filled-bc-hover: var(--c-surface-transparent);
  --filled-c-hover: var(--c-text-accent);
  --outlined-bc-base: var(--c-surface-subdued);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-subdued-hovered);
  --outlined-bc-hover: var(--c-surface-subdued-hovered);
  --outlined-c-hover: var(--c-text-accent);
  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-disabled);
  --link-c-hover: var(--c-text-accent); }
  .button.button-secondary.button-dark {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: var(--c-surface-subdued-hovered);
    --filled-bc-hover: var(--c-surface-transparent);
    --filled-c-hover: var(--c-text-accent);
    --outlined-bc-base: var(--c-surface-opacity);
    --outlined-c-base: var(--c-surface-opacity);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-text-white);
    --outlined-c-hover: var(--c-text-white);
    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-surface-default);
    --link-c-hover: var(--c-surface-opacity); }

.block-medicine,
.page-medicine {
  --filled-bgc-base: var(--c-surface-accent);
  --filled-bc-base: var(--c-surface-accent);
  --filled-c-base: var(--c-text-white);
  --filled-bgc-hover: var(--c-surface-accent-hovered);
  --filled-bc-hover: var(--c-surface-accent-hovered);
  --filled-c-hover: var(--c-text-white);
  --outlined-bc-base: var(--c-text-accent);
  --outlined-c-base: var(--c-text-accent);
  --outlined-bgc-hover: var(--c-surface-accent);
  --outlined-bc-hover: var(--c-surface-accent);
  --outlined-c-hover: var(--c-text-white);
  --link-bgc-hover: var(--c-surface-default);
  --link-bc-hover: var(--c-surface-default);
  --link-c-base: var(--c-text-accent);
  --link-c-hover: var(--c-surface-accent-hovered); }
  .block-medicine.button-dark,
  .page-medicine.button-dark {
    --filled-bgc-base: var(--c-surface-transparent);
    --filled-bc-base: var(--c-surface-accent);
    --filled-c-base: var(--c-text-white);
    --filled-bgc-hover: var(--c-surface-accent-hovered);
    --filled-bc-hover: var(--c-surface-accent-hovered);
    --filled-c-hover: var(--c-text-white);
    --outlined-bc-base: var(--c-surface-accent);
    --outlined-c-base: var(--c-text-white);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-surface-accent);
    --outlined-c-hover: var(--c-text-white);
    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-accent);
    --link-c-hover: #96C9FF; }
  .block-medicine .button.button-primary,
  .page-medicine .button.button-primary {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-transparent);
    --filled-c-base: var(--c-text-accent);
    --filled-bgc-hover: #C6F3BD;
    --filled-bc-hover: #C6F3BD;
    --filled-c-hover: var(--c-text-accent);
    --outlined-bc-base: var(--c-surface-accent);
    --outlined-c-base: var(--c-surface-accent);
    --outlined-bgc-hover: var(--c-surface-subdued);
    --outlined-bc-hover: var(--c-surface-subdued);
    --outlined-c-hover: var(--c-text-accent);
    --link-bgc-hover: var(--c-surface-transparent);
    --link-bc-hover: var(--c-surface-transparent);
    --link-c-base: var(--c-text-disabled);
    --link-c-hover: var(--c-surface-accent); }
    .block-medicine .button.button-primary.button-dark,
    .page-medicine .button.button-primary.button-dark {
      --filled-bgc-base: var(--c-surface-subdued-hovered);
      --filled-bc-base: var(--c-surface-subdued-hovered);
      --filled-c-base: var(--c-surface-accent);
      --filled-bgc-hover: var(--c-surface-primary);
      --filled-bc-hover: var(--c-surface-primary);
      --filled-c-hover: var(--c-text-accent);
      --outlined-bc-base: var(--c-surface-default);
      --outlined-c-base: var(--c-surface-default);
      --outlined-bgc-hover: var(--c-surface-subdued);
      --outlined-bc-hover: var(--c-surface-subdued);
      --outlined-c-hover: var(--c-surface-accent);
      --link-bgc-hover: var(--c-surface-default);
      --link-bc-hover: var(--c-surface-default);
      --link-c-base: var(--c-text-white);
      --link-c-hover: var(--c-text-accent); }
  .block-medicine .button.button-secondary,
  .page-medicine .button.button-secondary {
    --filled-bgc-base: var(--c-surface-subdued);
    --filled-bc-base: var(--c-surface-subdued);
    --filled-c-base: rgba(104, 189, 91, 0.5);
    --filled-bgc-hover: var(--c-surface-subdued);
    --filled-bc-hover: var(--c-surface-subdued);
    --filled-c-hover: var(--c-text-accent);
    --outlined-bc-base: var(--c-text-disabled);
    --outlined-c-base: var(--c-text-disabled);
    --outlined-bgc-hover: var(--c-surface-transparent);
    --outlined-bc-hover: var(--c-surface-subdued-hovered);
    --outlined-c-hover: var(--c-text-accent);
    --link-bgc-hover: var(--c-surface-default);
    --link-bc-hover: var(--c-surface-default);
    --link-c-base: var(--c-text-disabled);
    --link-c-hover: var(--c-text-accent); }
    .block-medicine .button.button-secondary.button-dark,
    .page-medicine .button.button-secondary.button-dark {
      --filled-bgc-base: var(--c-surface-subdued-hovered);
      --filled-bc-base: var(--c-surface-transparent);
      --filled-c-base: var(--c-surface-subdued);
      --filled-bgc-hover: var(--c-surface-subdued-hovered);
      --filled-bc-hover: var(--c-surface-subdued-hovered);
      --filled-c-hover: var(--c-surface-accent);
      --outlined-bc-base: var(--c-surface-primary-hovered);
      --outlined-c-base: var(--c-surface-primary-hovered);
      --outlined-bgc-hover: var(--c-surface-transparent);
      --outlined-bc-hover: var(--c-text-white);
      --outlined-c-hover: var(--c-text-white);
      --link-bgc-hover: var(--c-surface-transparent);
      --link-bc-hover: var(--c-surface-transparent);
      --link-c-base: var(--c-surface-default);
      --link-c-hover: var(--c-surface-opacity); }

.swiper-slide,
.card,
.gallery--item,
.banner-group .banner {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  flex-grow: 0;
  flex-shrink: 0; }

.swiper-wrapper {
  -webkit-transform-style: preserve-3d; }

.swiper-button-next,
.swiper-button-prev {
  cursor: pointer; }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: 'icomoon' !important; }
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0.2; }

.swiper-button-prev:after {
  content: "\e903"; }

.swiper-button-next:after {
  content: "\e904"; }

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 12px; }

.slider_controls {
  position: absolute;
  width: var(--container);
  justify-content: flex-end;
  display: flex;
  gap: 12px;
  align-items: center;
  right: 0;
  bottom: 0;
  z-index: 1; }
  @media (max-width: 1472px) {
    .slider_controls {
      width: calc(100vw - 160px);
      left: 80px; } }
  .slider_controls .swiper-button-next,
  .slider_controls .swiper-button-prev {
    margin: 0;
    position: static; }
    .slider_controls .swiper-button-next:after,
    .slider_controls .swiper-button-prev:after {
      background-color: var(--c-surface-subdued);
      transition: var(--transition);
      font-size: 1em;
      padding: 14px;
      border-radius: 8px;
      opacity: 0.6;
      color: var(--c-text-accent); }
    .slider_controls .swiper-button-next:hover:after, .slider_controls .swiper-button-next:focus:after, .slider_controls .swiper-button-next:active:after,
    .slider_controls .swiper-button-prev:hover:after,
    .slider_controls .swiper-button-prev:focus:after,
    .slider_controls .swiper-button-prev:active:after {
      opacity: 1;
      background-color: var(--c-surface-subdued-hovered); }

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 50;
  background: #FFFFFF;
  transition: var(--transition); }
  @media (min-width: 768px) {
    .header {
      max-width: calc(100vw - var(--scrollbarWidth, 17px)); } }
  .header .header--logo {
    z-index: 2; }
    .header .header--logo span {
      background: url("../img/logo/logo-2.svg") center no-repeat; }
      .header .header--logo span:after {
        background-image: url("../img/logo/logo-bck4.svg"); }
  .header.is_scrolled {
    box-shadow: 0px 3px 12px 0px #021F541F;
    translate: 0 -55px; }
  .header.header-transparent {
    position: fixed; }
    .header.header-transparent .header--logo span {
      background: url("../img/logo/logo.svg") center no-repeat;
      background-size: contain; }
      .header.header-transparent .header--logo span:after {
        background-image: url("../img/logo/logo-bck1.svg"); }
    .header.header-transparent.is_scrolled .header--logo span {
      background: url("../img/logo/logo-2.svg") center no-repeat;
      background-size: contain; }
  .header.header-transparent:not(.is_scrolled) {
    background: transparent;
    border-color: transparent;
    color: var(--c-text-white); }
    .header.header-transparent:not(.is_scrolled) .header--contacts_mob-phone {
      background: rgba(206, 206, 206, 0.15);
      backdrop-filter: blur(8px); }

.popup-menu .header--logo span {
  background: url("../img/logo/logo.svg") center no-repeat; }
  .popup-menu .header--logo span:after {
    background-image: url("../img/logo/logo-bck1.svg"); }

.page-medicine .header.header-transparent .header--logo span:after,
.page-medicine .popup-menu .header--logo span:after {
  background-image: url("../img/logo/logo-bck2.svg"); }

.page-medicine .header:not(.header-transparent) .header--top .header--actions_item .icon,
.page-medicine .header:not(.header-transparent) .header--top .header--location_item .icon {
  color: var(--c-text-accent); }

.page-medicine .header:not(.header-transparent) .header--logo span {
  background: url("../img/logo/logo-3.svg") center no-repeat;
  background-size: contain; }
  .page-medicine .header:not(.header-transparent) .header--logo span:after {
    background-image: url("../img/logo/logo-bck5.svg"); }

.header:not(.header-transparent) .nav--item,
.header-transparent.is_scrolled .nav--item {
  --c-surface-default: #19293C;
  --c-surface-opacity: #b6b4b2; }

.header:not(.header-transparent) .header--contacts .button,
.header-transparent.is_scrolled .header--contacts .button {
  color: var(--c-text-dark); }

.header .header--top,
.popup-menu .header--top {
  max-width: var(--container);
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
  z-index: 5;
  width: 100%; }
  .header .header--top .header--actions,
  .header .header--top .header--location,
  .popup-menu .header--top .header--actions,
  .popup-menu .header--top .header--location {
    display: flex;
    gap: 16px;
    position: relative;
    letter-spacing: 0.002em;
    border-radius: 6px 0 0 6px;
    padding: 6px 10px;
    overflow: hidden;
    cursor: default;
    background: rgba(206, 206, 206, 0.15);
    backdrop-filter: none; }
    @media (min-width: 768px) {
      .header .header--top .header--actions,
      .header .header--top .header--location,
      .popup-menu .header--top .header--actions,
      .popup-menu .header--top .header--location {
        backdrop-filter: blur(8px);
        border-radius: 6px; } }
    .header .header--top .header--actions .header--actions_item,
    .header .header--top .header--location .header--actions_item,
    .popup-menu .header--top .header--actions .header--actions_item,
    .popup-menu .header--top .header--location .header--actions_item {
      display: flex;
      align-items: center;
      white-space: nowrap; }
    .header .header--top .header--actions span.icon,
    .header .header--top .header--location span.icon,
    .popup-menu .header--top .header--actions span.icon,
    .popup-menu .header--top .header--location span.icon {
      font-size: 16px;
      margin-right: 8px;
      position: relative;
      z-index: 2; }
    .header .header--top .header--actions span:not(.icon),
    .header .header--top .header--location span:not(.icon),
    .popup-menu .header--top .header--actions span:not(.icon),
    .popup-menu .header--top .header--location span:not(.icon) {
      font-size: 14px;
      position: relative;
      z-index: 2; }
  @media (max-width: 998px) {
    .header .header--top .header--location,
    .popup-menu .header--top .header--location {
      display: none; } }
  @media (max-width: 998px) {
    .header .header--top .header--actions,
    .popup-menu .header--top .header--actions {
      overflow-x: auto;
      margin-right: -16px; } }

.header .header--actions .header--actions_item span.icon,
.popup-menu .header--actions .header--actions_item span.icon {
  color: #C6DCFF; }

.header.header-transparent .header--top {
  color: #FFFFFF; }

.header:not(header-transparent) .header--top {
  color: black; }

.header:not(header-transparent) .header--contacts_list ul {
  background-color: rgba(5, 10, 23, 0.3); }

.popup-menu .header--bottom,
.header .header--bottom {
  max-width: var(--container);
  padding: 10px 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--g-elements); }
  @media (min-width: 1140px) {
    .popup-menu .header--bottom,
    .header .header--bottom {
      display: grid;
      grid-template-columns: 1fr auto 1fr; } }
  .popup-menu .header--bottom .header--logo,
  .header .header--bottom .header--logo {
    padding: 0;
    position: relative;
    z-index: 5; }
    @media (min-width: 769px) {
      .popup-menu .header--bottom .header--logo,
      .header .header--bottom .header--logo {
        padding: 0 22px; } }
    .popup-menu .header--bottom .header--logo span,
    .header .header--bottom .header--logo span {
      display: inline-block;
      width: 96px;
      height: 46px;
      background-size: contain; }
      @media (min-width: 768px) {
        .popup-menu .header--bottom .header--logo span,
        .header .header--bottom .header--logo span {
          width: 160px;
          height: 57px; } }
      .popup-menu .header--bottom .header--logo span:after,
      .header .header--bottom .header--logo span:after {
        content: none; }
        @media (min-width: 769px) {
          .popup-menu .header--bottom .header--logo span:after,
          .header .header--bottom .header--logo span:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            z-index: -1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center; } }
  .popup-menu .header--bottom .header--nav,
  .header .header--bottom .header--nav {
    display: none; }
    @media (min-width: 993px) {
      .popup-menu .header--bottom .header--nav,
      .header .header--bottom .header--nav {
        display: flex; } }
    .popup-menu .header--bottom .header--nav .nav--item[data-openpopup],
    .popup-menu .header--bottom .header--nav .nav--item[data-closepopup],
    .header .header--bottom .header--nav .nav--item[data-openpopup],
    .header .header--bottom .header--nav .nav--item[data-closepopup] {
      border: 1px solid currentColor;
      border-radius: var(--border-corner); }
    .popup-menu .header--bottom .header--nav .nav--item,
    .header .header--bottom .header--nav .nav--item {
      font-size: var(--fs-18);
      padding: clamp(14px, calc(14px + (16 - 14) * ((100vw - 1024px) / (1920 - 1024))), 16px);
      transition: var(--transition); }
  .popup-menu .header--bottom .header--actions,
  .header .header--bottom .header--actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: var(--g-elements);
    position: relative; }
    @media (max-width: 768px) {
      .popup-menu .header--bottom .header--actions,
      .header .header--bottom .header--actions {
        flex-wrap: wrap; } }
    @media (max-width: 768px) {
      .popup-menu .header--bottom .header--actions > .button,
      .header .header--bottom .header--actions > .button {
        display: none; } }
  @media (min-width: 768px) {
    .popup-menu .header--bottom .header--location,
    .header .header--bottom .header--location {
      display: none; } }
  .popup-menu .header--bottom .header--contacts,
  .header .header--bottom .header--contacts {
    padding: 12px 0;
    position: relative;
    display: flex; }
    .popup-menu .header--bottom .header--contacts .contacts,
    .header .header--bottom .header--contacts .contacts {
      display: flex;
      gap: 8px;
      transition: none;
      font-size: var(--fs-18);
      white-space: nowrap; }
      .popup-menu .header--bottom .header--contacts .contacts span,
      .header .header--bottom .header--contacts .contacts span {
        font-weight: 400; }
    .popup-menu .header--bottom .header--contacts .icon.icon-link,
    .header .header--bottom .header--contacts .icon.icon-link {
      rotate: 90deg;
      transition: var(--transition); }
      @media (max-width: 768px) {
        .popup-menu .header--bottom .header--contacts .icon.icon-link,
        .header .header--bottom .header--contacts .icon.icon-link {
          display: none; } }
  .popup-menu .header--bottom .header--contacts_list,
  .header .header--bottom .header--contacts_list {
    display: none; }
    @media (min-width: 769px) {
      .popup-menu .header--bottom .header--contacts_list,
      .header .header--bottom .header--contacts_list {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        display: block;
        min-width: 100%;
        padding-top: 8px;
        pointer-events: none;
        color: #FFFFFF;
        opacity: 0;
        transition: var(--transition);
        translate: 0 -30%; }
        .popup-menu .header--bottom .header--contacts_list ul,
        .header .header--bottom .header--contacts_list ul {
          position: relative;
          list-style-type: none;
          background-color: rgba(255, 255, 255, 0.3);
          padding: 12px 16px;
          border-radius: var(--border-corner);
          backdrop-filter: blur(8px); }
          .popup-menu .header--bottom .header--contacts_list ul li,
          .header .header--bottom .header--contacts_list ul li {
            display: flex;
            gap: 8px;
            font-size: var(--fs-18);
            position: relative;
            z-index: 3;
            transition: var(--transition) opacity; }
            .popup-menu .header--bottom .header--contacts_list ul li span,
            .header .header--bottom .header--contacts_list ul li span {
              margin-right: 10px; }
          .popup-menu .header--bottom .header--contacts_list ul li:not(:last-child),
          .header .header--bottom .header--contacts_list ul li:not(:last-child) {
            margin-bottom: 16px; } }
  @media (min-width: 769px) {
    .popup-menu .header--bottom .header--contacts:hover .header--contacts_list,
    .popup-menu .header--bottom .header--contacts_list:hover,
    .header .header--bottom .header--contacts:hover .header--contacts_list,
    .header .header--bottom .header--contacts_list:hover {
      pointer-events: auto;
      opacity: 1; } }
  @media (min-width: 769px) {
    .popup-menu .header--bottom .header--contacts:hover .header--contacts_list,
    .header .header--bottom .header--contacts:hover .header--contacts_list {
      translate: 0 0; } }
  @media (min-width: 769px) {
    .popup-menu .header--bottom .header--contacts:hover .icon.icon-link,
    .header .header--bottom .header--contacts:hover .icon.icon-link {
      rotate: 270deg; } }
  @media (min-width: 769px) {
    .popup-menu .header--bottom .header--contacts_list li:hover,
    .header .header--bottom .header--contacts_list li:hover {
      opacity: 0.7; } }

.header:not(.header-transparent) .header--bottom .header--contacts_list ul,
.header.is_scrolled .header--bottom .header--contacts_list ul {
  color: black;
  background-color: var(--bck-color); }

.popup-menu .header--nav .nav--item[data-closepopup] .burger-line {
  opacity: 0; }

.popup-menu .header--nav .nav--item[data-closepopup] .burger:after {
  top: 50%;
  rotate: 45deg; }

.popup-menu .header--nav .nav--item[data-closepopup] .burger:before {
  top: 50%;
  rotate: -45deg; }

@media (min-width: 993px) {
  .is_dropdown {
    position: relative; }
    .is_dropdown .is_dropdown--content {
      position: absolute;
      top: 100%;
      left: 0;
      pointer-events: none;
      width: fit-content;
      transition: var(--transition);
      pointer-events: none;
      opacity: 0;
      translate: 0 -30%;
      border-radius: 4px;
      color: #FFFFFF; }
      .is_dropdown .is_dropdown--content .button {
        min-width: 100%;
        text-align: left;
        justify-content: flex-start; } }

.is_dropdown:hover .is_dropdown--content,
.is_dropdown:focus .is_dropdown--content {
  display: flex;
  pointer-events: auto;
  flex-direction: column;
  pointer-events: all;
  opacity: 1;
  gap: 4px;
  translate: 0 0; }

.header-transparent .is_dropdown--content {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px); }

.header:not(.header-transparent) .is_dropdown--content,
.header.is_scrolled .is_dropdown--content {
  color: black;
  background-color: var(--bck-color); }

.header--mobile_panel {
  display: none;
  transition: var(--transition); }
  @media (max-width: 992px) {
    .header--mobile_panel {
      display: block;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 10px 12px 14px 12px;
      z-index: 150;
      width: 100vw;
      background-color: #FFFFFF; } }
  .header--mobile_panel .mobile_panel_actions {
    font-size: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.3); }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative; }
      .header--mobile_panel .mobile_panel_actions .mobile_panel_link .icon {
        position: relative; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .icon.icon-contacts {
      position: static;
      display: inline-block;
      background-image: url("../img/icons/contacts.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      width: 20px;
      height: 20px;
      margin-bottom: 6px; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts {
      border-radius: var(--border-corner);
      background-color: #FFFFFF;
      position: fixed;
      bottom: 80px;
      left: 16px;
      pointer-events: none;
      opacity: 0;
      transition: var(--transition);
      translate: 0 30%; }
      .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts--content {
        padding: 12px 16px; }
        .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts--content ul {
          list-style-type: none; }
        .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts--content li {
          color: var(--c-text-accent);
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 16px; }
        .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts--content li:not(:last-child) {
          margin-bottom: 16px; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-contacts .contacts.active {
      opacity: 1;
      translate: 0 0; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-search .icon {
      border-radius: 8px;
      display: inline-block;
      width: 52px;
      height: 52px;
      top: -32px;
      position: absolute;
      background-color: var(--c-text-accent);
      background-image: url("../img/icons/search-mob.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      padding: 4px; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-menu.open .burger-line {
      opacity: 0; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-menu.open .burger:after {
      top: 50%;
      rotate: 45deg; }
    .header--mobile_panel .mobile_panel_actions .mobile_panel_link-menu.open .burger:before {
      top: 50%;
      rotate: -45deg; }
    .header--mobile_panel .mobile_panel_actions .burger {
      margin-bottom: 12px; }

.burger {
  width: 20px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative; }
  .burger-line {
    width: 60%;
    display: block;
    height: 2px;
    background-color: currentColor; }
    @media (max-width: 768px) {
      .burger-line {
        transition: var(--transition); } }
  .burger:after, .burger:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: currentColor;
    height: 2px;
    left: 0;
    right: 0;
    translate: 0 -50%; }
    @media (max-width: 768px) {
      .burger:after, .burger:before {
        transition: var(--transition); } }
  .burger:after {
    top: 0; }
  .burger:before {
    top: 100%; }

.breadcrumbs {
  --main-color: #8C9196;
  --non-active-color: var(--c-text-dark);
  --hover-color: #6D7175;
  padding: var(--g-content) 0;
  display: flex;
  align-items: center;
  gap: 8px;
  width: var(--container);
  margin: 0 auto;
  z-index: 45; }
  @media (max-width: 768px) {
    .breadcrumbs {
      flex-wrap: nowrap;
      width: 100vw;
      overflow-x: auto;
      padding: 20px 16px;
      margin: 0;
      justify-content: flex-start; } }
  .breadcrumbs a {
    transition: var(--transition);
    position: relative;
    color: var(--main-color);
    white-space: nowrap; }
    .breadcrumbs a:not(:last-child):after {
      content: "/";
      margin-left: 8px; }
    .breadcrumbs a:last-child {
      pointer-events: none;
      color: var(--non-active-color); }
    .breadcrumbs a:hover, .breadcrumbs a:focus, .breadcrumbs a:active {
      color: var(--hover-color); }

.header-transparent ~ .breadcrumbs {
  position: absolute;
  top: var(--headerHeight);
  left: calc( ( 100vw - var(--container) )/ 2);
  --main-color: rgba(255,255,255, 0.36);
  --non-active-color: #fff;
  --hover-color: rgba(255,255,255, 0.6); }
  @media (max-width: 768px) {
    .header-transparent ~ .breadcrumbs {
      left: 0; } }

.accordion {
  cursor: pointer;
  position: relative; }
  @media (max-width: 768px) {
    .accordion {
      padding: 4px; } }
  .accordion:not(:last-child) {
    margin-bottom: var(--g-content); }
  .accordion.is_open .accordion--content {
    transition: 0.3s ease-out;
    opacity: 1;
    height: auto;
    pointer-events: auto;
    max-height: 100%;
    margin: var(--g-24) 0;
    padding: 16px 0; }
  .accordion.is_open .accordion--button .icon {
    transform: rotate(180deg);
    transform-origin: center; }
  .accordion--button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: var(--g-section-inner); }
    .accordion--button .accordion--button_text {
      margin: 0;
      font-size: var(--fs-24);
      font-weight: 600;
      line-height: 1.3;
      max-width: 450px; }
    .accordion--button .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      height: 1.33em;
      width: 1.33em;
      font-size: var(--fs-24);
      transform: rotate(0deg);
      transition: 0.18s ease-in-out;
      color: var(--c-surface-accent); }
  .accordion--content {
    opacity: 0;
    transition: height 0.2s 0.1s linear, opacity 0.2s 0.15s linear;
    height: 0;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s; }
    .accordion--content.is_cascade > p:first-child {
      margin: 0; }

.popup {
  background-color: rgba(0, 0, 0, 0.35);
  padding: var(--g-content, 40px) var(--g-elements); }
  @media (max-width: 768px) {
    .popup {
      padding: var(--g-content, 40px) var(--g-elements) 100px;
      height: -webkit-fill-available;
      height: calc(100 * var(--vh, 1vh)); } }
  .popup--content {
    max-width: 780px;
    border-radius: var(--border-corner); }
  .popup--close {
    width: clamp(44px, calc(44px + (54 - 44) * ((100vw - 1024px) / (1920 - 1024))), 54px);
    height: clamp(44px, calc(44px + (54 - 44) * ((100vw - 1024px) / (1920 - 1024))), 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: var(--g-24);
    right: var(--g-24);
    z-index: 42;
    cursor: pointer;
    transition: 0.3s ease-out;
    color: var(--c-text-subdued); }
    .popup--close .icon-close {
      font-size: 24px;
      color: #FFFFFF;
      justify-content: center;
      align-items: center; }
    @media (max-width: 768px) {
      .popup--close {
        left: unset;
        right: 16px;
        top: 16px; } }

[data-popup="popup_for_vacancy_form"] .popup--content,
.popup-confirmation .popup--content {
  position: relative;
  max-width: 702px;
  width: 100%; }
  @media (min-width: 769px) {
    [data-popup="popup_for_vacancy_form"] .popup--content,
    .popup-confirmation .popup--content {
      padding: var(--g-24) var(--g-32); } }
  [data-popup="popup_for_vacancy_form"] .popup--content .popup--close,
  .popup-confirmation .popup--content .popup--close {
    top: var(--g-24);
    right: var(--g-32); }
  [data-popup="popup_for_vacancy_form"] .popup--content .popup--close .icon,
  .popup-confirmation .popup--content .popup--close .icon {
    color: var(--c-surface-accent);
    font-weight: 400;
    opacity: 0.8;
    font-size: 1em; }

.popup.popup-menu {
  width: 100%;
  max-width: 100vw;
  place-items: start;
  padding: 0;
  color: var(--c-text-white);
  background-color: #0B182A;
  pointer-events: none; }
  @media (min-width: 769px) {
    .popup.popup-menu {
      padding-right: var(--scrollbarWidth, 17px); } }
  .popup.popup-menu .menu {
    max-width: var(--container);
    width: 100%;
    margin: auto; }
  .popup.popup-menu .popup--content {
    border-radius: 0;
    background-color: #0B182A;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100vw;
    max-width: 100vw;
    margin: auto; }
    @media (max-width: 768px) {
      .popup.popup-menu .popup--content {
        width: 100%; } }
  .popup.popup-menu.active {
    z-index: 100; }
  .popup.popup-menu .header--top {
    opacity: 0; }
  .popup.popup-menu .menu--content {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0 70px; }
    @media (min-width: 768px) {
      .popup.popup-menu .menu--content {
        padding: 55px 0;
        grid-template-columns: 1fr 1fr; } }
    .popup.popup-menu .menu--content h3 {
      font-weight: 300;
      font-size: var(--fs-56); }
    .popup.popup-menu .menu--content .menu--actions .accordion--button {
      width: max-content;
      gap: var(--g-20); }
    @media (max-width: 768px) {
      .popup.popup-menu .menu--content .menu--actions .accordion--content > p {
        display: flex;
        flex-direction: column;
        gap: 16px; } }
    .popup.popup-menu .menu--content .menu--actions .accordion,
    .popup.popup-menu .menu--content .menu--actions .menu--actions_link {
      opacity: 0.6;
      margin-bottom: 20px; }
    .popup.popup-menu .menu--content .menu--actions .accordion.is_open {
      opacity: 1; }
      .popup.popup-menu .menu--content .menu--actions .accordion.is_open .accordion--button .icon-medicine {
        rotate: 45deg; }
    .popup.popup-menu .menu--content .menu--actions .accordion:hover,
    .popup.popup-menu .menu--content .menu--actions .menu--actions_link:hover {
      opacity: 1; }
    .popup.popup-menu .menu--content .menu--actions .accordion a {
      border: none;
      margin-right: 0.5em; }
    .popup.popup-menu .menu--content .menu--actions .accordion .accordion--content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0; }
      .popup.popup-menu .menu--content .menu--actions .accordion .accordion--content_item {
        display: inline-block; }
    .popup.popup-menu .menu--content .menu--actions .menu--actions_link a {
      display: flex;
      align-items: center;
      gap: var(--g-20); }
      .popup.popup-menu .menu--content .menu--actions .menu--actions_link a .icon {
        font-size: 0.5em;
        color: var(--c-text-accent); }
    .popup.popup-menu .menu--content .menu--offers {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--g-card);
      place-content: baseline; }
      @media (max-width: 1024px) {
        .popup.popup-menu .menu--content .menu--offers {
          display: none; } }

.popup.popup-menu.active {
  pointer-events: auto; }

.popup.popup-scheme {
  background-color: transparent; }
  @media (min-width: 768px) {
    .popup.popup-scheme {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      max-width: var(--container);
      top: 100px;
      left: 50%;
      z-index: 10;
      translate: -50% 0; } }
  .popup.popup-scheme .popup--content {
    max-width: 320px;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column; }
    @media (min-width: 768px) {
      .popup.popup-scheme .popup--content {
        max-width: 500px; } }
    .popup.popup-scheme .popup--content_image {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      aspect-ratio: 1/1; }
      @media (min-width: 768px) {
        .popup.popup-scheme .popup--content_image {
          aspect-ratio: 500/320; } }
      .popup.popup-scheme .popup--content_image img {
        aspect-ratio: 340/320; }
        @media (min-width: 768px) {
          .popup.popup-scheme .popup--content_image img {
            aspect-ratio: 500/320; } }
    .popup.popup-scheme .popup--content_description {
      background-color: #FFFFFF;
      padding: var(--g-24); }
      .popup.popup-scheme .popup--content_description .button {
        margin-top: 16px; }
  .popup.popup-scheme .popup--content_title {
    font-size: var(--fs-32);
    margin-bottom: 20px; }
  .popup.popup-scheme .popup--close {
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--border-corner); }
    .popup.popup-scheme .popup--close .icon-close:before {
      color: #FFFFFF;
      font-size: 0.6em; }
      @media (min-width: 769px) {
        .popup.popup-scheme .popup--close .icon-close:before {
          font-size: 1em; } }

@media (min-width: 768px) {
  .popup.popup-balloon {
    max-width: 500px;
    position: absolute;
    right: 20px;
    bottom: unset;
    top: 50px;
    left: unset;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    z-index: 10;
    width: 100%; } }

.popup.popup-balloon .popup--content {
  position: relative;
  overflow: hidden;
  max-width: 340px;
  width: 100%; }
  @media (min-width: 768px) {
    .popup.popup-balloon .popup--content {
      max-width: 512px; } }

.popup.popup-balloon .popup--close {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: var(--border-corner); }

.popup.popup-balloon .icon-close {
  color: #FFFFFF;
  height: 1em;
  width: 1em; }

.popup.popup-balloon .baloon {
  background-color: #FFFFFF; }
  .popup.popup-balloon .baloon .balloon--image {
    aspect-ratio: 1/1; }
    @media (min-width: 768px) {
      .popup.popup-balloon .baloon .balloon--image {
        aspect-ratio: 500/320; } }
    .popup.popup-balloon .baloon .balloon--image img {
      aspect-ratio: 1/1; }
      @media (min-width: 768px) {
        .popup.popup-balloon .baloon .balloon--image img {
          aspect-ratio: 500/320; } }
  .popup.popup-balloon .baloon .balloon--content {
    padding: var(--g-24); }
  .popup.popup-balloon .baloon .balloon--title {
    font-size: var(--fs-32);
    font-family: 'arsenica', sans-serif;
    margin-bottom: 20px; }
  .popup.popup-balloon .baloon .balloon--text {
    text-overflow: ellipsis;
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; }
  .popup.popup-balloon .baloon .button {
    margin-top: 16px; }

.popup.popup-info_wigjet .popup--content {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(5, 10, 23, 0.3);
  backdrop-filter: blur(8px);
  z-index: 10;
  padding: 20px 24px;
  font-weight: 400; }
  .popup.popup-info_wigjet .popup--content .footer--widget_container {
    display: flex;
    flex-direction: column;
    gap: 24px; }
    .popup.popup-info_wigjet .popup--content .footer--widget_container .item {
      font-size: 14px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: flex-start; }
    .popup.popup-info_wigjet .popup--content .footer--widget_container .item--title {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: var(--c-text-white); }
    .popup.popup-info_wigjet .popup--content .footer--widget_container .item-advantages {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start; }
      .popup.popup-info_wigjet .popup--content .footer--widget_container .item-advantages .item-advantages-slide {
        display: flex;
        gap: 8px; }
    .popup.popup-info_wigjet .popup--content .footer--widget_container .item .slider {
      gap: 16px;
      flex-direction: column;
      margin: 0; }
    .popup.popup-info_wigjet .popup--content .footer--widget_container .item-advantages {
      order: -1; }

.popup.popup-info_wigjet .popup--close {
  top: 0;
  right: 0; }

.popup.popup-info_wigjet .popup--close .icon {
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 400;
  opacity: 0.8; }

.popup-confirmation .popup--content {
  position: relative;
  padding: var(--g-24) var(--g-32);
  max-width: 702px;
  width: 100%;
  background-color: var(--c-surface-default); }
  @media (max-width: 768px) {
    .popup-confirmation .popup--content {
      min-height: 100%; } }
  .popup-confirmation .popup--content .popup--close {
    top: 0;
    right: 0; }
  .popup-confirmation .popup--content .popup--content_title {
    font-size: var(--fs-28);
    font-weight: 700; }
  .popup-confirmation .popup--content .popup--content_text {
    font-size: var(--fs-20);
    color: var(--c-text-subdued);
    line-height: 1.5;
    margin: 8px 0 var(--g-24); }

.is_cascade h1, .is_cascade h2 {
  font-size: var(--fs-56);
  margin: var(--g-elements) 0; }

.is_cascade h3 {
  font-size: var(--fs-36);
  margin: var(--g-elements) 0; }

.is_cascade h4 {
  font-size: var(--fs-32);
  margin: var(--g-elements) 0; }

.is_cascade h5, .is_cascade h6 {
  font-size: var(--fs-24);
  margin: var(--g-elements) 0; }

.is_cascade > p {
  font-size: var(--fs-20);
  line-height: 1.5;
  margin-top: var(--g-20); }

.is_cascade a:not(.button) {
  color: var(--c-text-accent);
  font-size: var(--fs-20);
  margin: 0 0.5em; }
  .is_cascade a:not(.button):hover, .is_cascade a:not(.button):active {
    color: var(--c-text-accent-hovered); }
  .is_cascade a:not(.button):visited {
    opacity: 0.8; }

.is_cascade > span {
  font-size: var(--fs-20);
  line-height: 1.5; }

.is_cascade strong, .is_cascade b {
  font-size: var(--fs-24);
  font-weight: 700;
  line-height: 1.5; }

.is_cascade > ul, .is_cascade ol {
  margin: var(--g-24) 0;
  padding-left: var(--g-24); }
  .is_cascade > ul li, .is_cascade ol li {
    font-size: var(--fs-20);
    line-height: 1.5; }
    .is_cascade > ul li:not(:last-of-type), .is_cascade ol li:not(:last-of-type) {
      margin-bottom: 16px; }
    .is_cascade > ul li::marker, .is_cascade ol li::marker {
      font-size: 0.8em; }

.is_cascade figure {
  margin: var(--g-content) 0; }
  .is_cascade figure img {
    aspect-ratio: 920 / 520;
    width: 100%; }
  .is_cascade figure figcaption {
    color: var(--c-text-disabled);
    font-size: 16px;
    margin-top: 16px; }

.is_cascade > img,
.is_cascade > video,
.is_cascade > iframe,
.is_cascade > object {
  margin: var(--g-32) 0; }

.is_cascade video, .is_cascade img, .is_cascade iframe, .is_cascade object {
  aspect-ratio: 1180 / 540;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  overflow: hidden; }

.is_cascade .is_cascade__slider {
  aspect-ratio: 1180 / 540; }
  .is_cascade .is_cascade__slider .swiper-button-next,
  .is_cascade .is_cascade__slider .swiper-button-prev {
    width: 36px;
    height: 36px;
    border-radius: 50%; }
    .is_cascade .is_cascade__slider .swiper-button-next::after,
    .is_cascade .is_cascade__slider .swiper-button-prev::after {
      font-size: 18px; }

.is_cascade blockquote {
  padding-left: 24px;
  border-left: 1px solid var(--c-surface-accent); }
  .is_cascade blockquote p {
    font-size: var(--fs-20);
    line-height: 1.5; }
  .is_cascade blockquote span {
    display: block;
    font-size: 16px;
    color: var(--c-text-disabled);
    margin-top: 8px; }

.is_cascade .button_wrapper, .is_cascade .block--actions {
  display: flex;
  gap: 8px;
  margin: var(--g-32) 0; }

.is_cascade .accordion {
  margin: var(--g-32) 0; }

.is_cascade p + .button,
.is_cascade ul + .button {
  margin-top: var(--g-32); }

.is_cascade table {
  margin: var(--g-32) 0;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(104, 170, 242, 0.3); }
  @media (min-width: 769px) {
    .is_cascade table {
      box-shadow: 0px 0px 0px 2px rgba(104, 170, 242, 0.3); } }
  @media (max-width: 768px) {
    .is_cascade table tbody {
      max-width: var(--container);
      display: block;
      overflow-x: auto;
      position: relative;
      z-index: 1; } }
  .is_cascade table tbody tr:not(:first-of-type) td:first-of-type {
    background-color: #f6f9ff; }
  .is_cascade table tbody td:first-of-type {
    left: 0;
    min-width: 82px;
    padding-left: 16px;
    vertical-align: bottom;
    letter-spacing: -.02em; }
    @media (max-width: 768px) {
      .is_cascade table tbody td:first-of-type {
        max-width: 50%; } }
  .is_cascade table tbody tr:first-of-type {
    font-weight: 600;
    text-align: left;
    background-color: #edf2ff; }
  .is_cascade table tbody td, .is_cascade table tbody th {
    border: 1px solid rgba(104, 170, 242, 0.3);
    font-size: 16px;
    padding: 10px 16px; }

.is_cascade .note {
  padding: 0; }
  .is_cascade .note li {
    position: relative;
    display: flex;
    margin-left: 0;
    padding: 0;
    justify-content: space-between;
    gap: 20px;
    font-size: var(--fs-16); }
    .is_cascade .note li:before {
      position: absolute;
      z-index: -1;
      top: -0.05em;
      left: 0;
      display: block;
      width: 100%;
      height: 1em;
      content: '';
      opacity: .4;
      border-bottom: 1px dashed #404040;
      z-index: 1; }
    .is_cascade .note li span,
    .is_cascade .note li p {
      background-color: #FFFFFF;
      z-index: 2; }
    .is_cascade .note li span {
      padding-right: 4px;
      font-weight: 700; }
      @media (max-width: 998px) {
        .is_cascade .note li span {
          width: min-content; } }
      @media (max-width: 768px) {
        .is_cascade .note li span {
          white-space: nowrap;
          width: auto; } }
    .is_cascade .note li p {
      padding-left: 4px;
      text-align: right; }

.is_cascade *:nth-child(1) {
  margin-top: 0; }

.block-medicine .is_cascade table {
  border: 1px solid #ddf7d9; }
  @media (min-width: 769px) {
    .block-medicine .is_cascade table {
      box-shadow: 0px 0px 0px 2px #ddf7d9; } }
  .block-medicine .is_cascade table thead, .block-medicine .is_cascade table th {
    border: 1px solid #ddf7d9; }
  .block-medicine .is_cascade table tbody tr:not(:first-of-type) td:first-of-type {
    background-color: #f1fcf0; }
  .block-medicine .is_cascade table tbody td:first-of-type {
    background-color: #f1fcf0; }
  .block-medicine .is_cascade table tbody tr:first-of-type td {
    background-color: #ddf7d9; }
  .block-medicine .is_cascade table tbody td, .block-medicine .is_cascade table tbody th {
    border: 1px solid #ddf7d9; }

article {
  --container: 780px; }
  @media (max-width: 992px) {
    article {
      --container: calc(100vw - 32px); } }

.form fieldset {
  background-color: #EDF6FF;
  border-color: transparent;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: var(--g-content);
  border-radius: var(--border-corner); }

.form--title {
  font-size: var(--fs-56);
  font-family: arsenica; }

.form--text {
  color: var(--c-text-subdued);
  font-size: var(--fs-20); }

.form--input {
  display: flex;
  flex-direction: column;
  gap: 8px; }
  .form--input span {
    font-size: 16px;
    color: var(--c-text-dark);
    font-weight: 600; }
  .form--input input, .form--input textarea, .form--input .form__input-file {
    outline: none;
    padding: 12px 16px;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    border-radius: var(--border-corner);
    transition: 0.3s ease-out;
    background-color: #fff;
    z-index: 1; }
  .form--input input, .form--input textarea {
    border: 1px solid var(--c-text-disabled); }
  .form--input .form__input-file {
    border: 1px dashed var(--c-text-disabled);
    display: flex;
    justify-content: space-between;
    gap: 16px; }
    .form--input .form__input-file .icon {
      color: var(--c-text-accent);
      font-size: 0.8em; }
    .form--input .form__input-file::placeholder, .form--input .form__input-file:placeholder-shown {
      color: #828595; }
    .form--input .form__input-file:not(:placeholder-shown) {
      color: var(--c-dark); }
    .form--input .form__input-file:focus {
      border-color: var(--c-text-dark); }
    .form--input .form__input-file:invalid:not(:placeholder-shown) {
      border-color: var(--c-error); }
      .form--input .form__input-file:invalid:not(:placeholder-shown) ~ .form--input_error {
        height: auto; }
    .form--input .form__input-file:valid:not(:placeholder-shown) {
      border-color: var(--c-text-dark); }
  .form--input .form__input-file.loaded .js-clearFile {
    display: block; }
  .form--input.error .form--input_error {
    height: auto; }
  .form--input_error {
    color: var(--c-error);
    height: 0;
    overflow: hidden;
    transition: 0.3s linear; }

.form--policy {
  font-size: 16px;
  font-weight: 400;
  color: var(--c-text-secondary); }
  .form--policy a:not(.button) {
    font-size: 16px;
    font-weight: 400; }

.form__button {
  align-self: start; }

.form.is_sending button[type="submit"] {
  opacity: 0.5;
  pointer-events: none; }

.form__input-file {
  border-radius: 2px;
  cursor: pointer;
  width: 100%; }
  .form__input-file p, .form__input-file span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: #828595; }
    .form__input-file p span, .form__input-file span span {
      text-transform: none;
      color: var(--c-text-dark); }
  .form__input-file .js-clearFile {
    display: none; }
  .form__input-file.loaded {
    border-color: var(--c-text-dark);
    position: relative; }
  .form__input-file .js-changeDescriptionText {
    position: relative;
    color: var(--c-text-dark);
    display: flex;
    gap: 16px; }
    .form__input-file .js-changeDescriptionText:before {
      font-family: 'icomoon' !important;
      content: "\e90d";
      line-height: 1;
      font-size: 20px;
      margin-right: 6px;
      vertical-align: middle;
      color: var(--c-surface-accent); }
    .form__input-file .js-changeDescriptionText .js-clearFile {
      display: block;
      position: absolute;
      right: 8px;
      top: 12px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.707 12.293C14.098 12.684 14.098 13.316 13.707 13.707C13.512 13.902 13.256 14 13 14C12.744 14 12.488 13.902 12.293 13.707L10 11.414L7.707 13.707C7.512 13.902 7.256 14 7 14C6.744 14 6.488 13.902 6.293 13.707C5.902 13.316 5.902 12.684 6.293 12.293L8.586 10L6.293 7.707C5.902 7.316 5.902 6.684 6.293 6.293C6.684 5.902 7.316 5.902 7.707 6.293L10 8.586L12.293 6.293C12.684 5.902 13.316 5.902 13.707 6.293C14.098 6.684 14.098 7.316 13.707 7.707L11.414 10L13.707 12.293ZM10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2Z' fill='%23010812' fill-opacity='0.35'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px; }

.content_gallery {
  max-width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .content_gallery .block--wrapper {
      overflow: hidden; } }
  .content_gallery figcaption {
    display: none; }
  .content_gallery .slider_controls {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
    left: unset;
    transform: translateY(calc(-100% - var(--g-40))); }
    @media (max-width: 768px) {
      .content_gallery .slider_controls {
        display: none; } }
    .content_gallery .slider_controls .swiper-button-next,
    .content_gallery .slider_controls .swiper-button-prev {
      width: 100%;
      color: var(--c-text-accent); }

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (40 - 12) * ((100vw - 1024px) / (1920 - 1024))), 40px) clamp(12px, calc(12px + (24 - 12) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 992px) {
    .gallery {
      grid-template-columns: 1fr 1fr; } }
  .gallery .gallery--item {
    border-radius: var(--border-corner);
    overflow: hidden; }
    .gallery .gallery--item img, .gallery .gallery--item figure {
      width: 100%;
      height: 100%;
      aspect-ratio: 517 / 420; }
      @media (max-width: 992px) {
        .gallery .gallery--item img, .gallery .gallery--item figure {
          aspect-ratio: 165/220; } }

.js-gallerySwiper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%; }
  .js-gallerySwiper .gallery--item {
    height: 100%;
    aspect-ratio: 517 / 420; }

.block--filters .block--content {
  margin-top: 0; }
  .block--filters .block--content .block--sections {
    margin-top: 0; }

.block--filters .filters--item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: var(--g-32); }

.block--filters .filters--item_title {
  font-weight: 600;
  font-size: 24px; }

.block--filters .filters label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer; }
  .block--filters .filters label .custom-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: transparent;
    z-index: 1;
    border: var(--c-text-disabled) 2px solid;
    border-radius: 2px; }
  .block--filters .filters label .custom-checkbox:after {
    content: '';
    z-index: -1;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
    background-image: url("../img/checkbox.svg");
    z-index: 1;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .block--filters .filters label input {
    display: none; }
  .block--filters .filters label input:checked + .custom-checkbox {
    background-color: #7BBAFF; }
  .block--filters .filters label input:checked + span:after {
    opacity: 1; }

.footer {
  background-color: var(--background-primary);
  position: relative;
  padding: var(--g-content) 0;
  color: var(--c-text-white);
  z-index: 2; }
  @media (max-width: 768px) {
    .footer {
      padding-bottom: 90px; } }
  .footer:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 13%;
    background-image: url("../img/footer-bck.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0; }
  @media (max-width: 768px) {
    .footer .footer--form {
      width: 100%; } }
  .footer .footer--form_title {
    font-size: var(--fs-44);
    margin-bottom: var(--g-32); }
  .footer .footer--form .form {
    display: flex;
    gap: 8px;
    align-items: center; }
    @media (max-width: 375px) {
      .footer .footer--form .form {
        flex-wrap: wrap; } }
    .footer .footer--form .form--input {
      width: 100%; }
  .footer .footer--top {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: var(--g-section-inner);
    margin-top: 30px; }
    @media (max-width: 768px) {
      .footer .footer--top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 40px;
        margin: 0; } }
    .footer .footer--top .footer--logo {
      position: relative;
      padding: 0 22px;
      position: relative;
      width: fit-content; }
      .footer .footer--top .footer--logo span {
        position: relative;
        display: inline-block;
        width: 280px;
        height: 109px;
        background: url("../img/logo/logo.svg") center no-repeat;
        background-size: contain; }
        @media (max-width: 768px) {
          .footer .footer--top .footer--logo span {
            width: 157px;
            height: 54px; } }
        .footer .footer--top .footer--logo span:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
          z-index: -1;
          background-image: url("../img/logo/logo-bck1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; }
  .footer .footer--main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--g-section) 0;
    gap: var(--g-section-inner); }
    @media (max-width: 1024px) {
      .footer .footer--main {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 40px; } }
    .footer .footer--main .footer--contacts {
      display: flex;
      flex-direction: column;
      gap: var(--g-content); }
      .footer .footer--main .footer--contacts h4 {
        color: var(--c-surface-accent);
        font-family: 'caveat', sans-serif;
        font-size: var(--fs-28);
        margin-bottom: 16px; }
      .footer .footer--main .footer--contacts_phone div {
        display: flex;
        align-items: center;
        gap: var(--g-20); }
        @media (max-width: 768px) {
          .footer .footer--main .footer--contacts_phone div {
            flex-wrap: wrap; } }
      .footer .footer--main .footer--contacts_phone a {
        padding: 0;
        border: none;
        font-family: 'arsenica', sans-serif; }
        .footer .footer--main .footer--contacts_phone a span {
          font-size: var(--fs-28); }
      .footer .footer--main .footer--contacts_phone a:not(:last-child):after {
        content: '';
        width: 11px;
        height: 11px;
        background-color: var(--c-surface-accent);
        border-radius: 50%;
        position: static;
        display: inline-block;
        margin-left: var(--g-20); }
      .footer .footer--main .footer--contacts_adress div {
        display: flex;
        align-items: center;
        gap: var(--g-20);
        white-space: nowrap;
        flex-wrap: wrap; }
      .footer .footer--main .footer--contacts_adress span {
        color: var(--c-surface-opacity); }
      .footer .footer--main .footer--contacts_adress a {
        color: var(--c-surface-opacity);
        text-decoration: underline;
        transition: var(--transition); }
        .footer .footer--main .footer--contacts_adress a:hover {
          color: var(--c-text-white); }
      .footer .footer--main .footer--contacts_soc div {
        display: flex;
        gap: 8px; }
      .footer .footer--main .footer--contacts_soc .icon {
        display: inline-block;
        background-color: #FFFFFF;
        border-radius: var(--border-corner);
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        position: relative; }
        .footer .footer--main .footer--contacts_soc .icon:before {
          color: var(--c-surface-accent);
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 42px;
          transition: var(--transition);
          translate: -50% -50%; }
        .footer .footer--main .footer--contacts_soc .icon:hover:before {
          color: var(--c-surface-accent-hovered); }
    .footer .footer--main .footer--nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px; }
      @media (max-width: 475px) {
        .footer .footer--main .footer--nav {
          justify-content: flex-start; } }
      .footer .footer--main .footer--nav span {
        display: inline-block;
        color: var(--c-surface-accent);
        font-weight: 600;
        margin-bottom: 16px;
        font-family: 'caveat', sans-serif;
        font-size: var(--fs-28); }
      .footer .footer--main .footer--nav a {
        border: none;
        padding: 0;
        margin-bottom: 12px; }
  .footer .footer--bottom {
    padding: var(--g-section-inner) 0 0 0;
    display: flex;
    justify-content: space-between;
    gap: 20px; }
    @media (max-width: 768px) {
      .footer .footer--bottom {
        flex-wrap: wrap; } }
    .footer .footer--bottom span {
      color: var(--c-surface-opacity);
      white-space: nowrap; }
    .footer .footer--bottom_copyright, .footer .footer--bottom_developer {
      display: flex;
      align-items: center;
      gap: var(--g-20);
      z-index: 1; }
      .footer .footer--bottom_copyright a, .footer .footer--bottom_developer a {
        padding: 0;
        white-space: nowrap; }
        .footer .footer--bottom_copyright a:hover, .footer .footer--bottom_developer a:hover {
          opacity: 0.6; }
    @media (max-width: 768px) {
      .footer .footer--bottom_copyright {
        flex-wrap: wrap; } }

.page-medicine .footer {
  --c-surface-accent: #68BD5B; }

.page-medicine .footer:after {
  background-image: url("../img/footer-bck-medicine.png"); }

.page-medicine .footer--top .footer--logo span:after {
  background-image: url("../img/logo/logo-bck2.svg"); }

.footer--widget {
  display: none; }
  @media (min-width: 1241px) {
    .footer--widget {
      display: block;
      height: 45px; } }
  @media (min-width: 1241px) {
    .footer--widget_container {
      position: fixed;
      z-index: 50;
      max-width: var(--container);
      margin: 0 auto;
      backdrop-filter: blur(8px);
      bottom: 15px;
      left: 50%;
      width: 100%;
      justify-content: space-between;
      display: flex;
      translate: -50% 0;
      padding: 10px 24px;
      background: rgba(206, 206, 206, 0.15);
      transition: background-color var(--transition);
      border-radius: var(--border-corner); } }
  @media (min-width: 1241px) {
    .footer--widget_item {
      display: flex;
      gap: 16px;
      flex: 0 0 33%; } }
  @media (min-width: 1241px) {
    .footer--widget .item-grade .footer--widget_item--title,
    .footer--widget .item-advantages .footer--widget_item--title {
      display: none; } }
  .footer--widget .item-advantages .item-advantages-slide {
    padding-right: 80px;
    justify-content: flex-end; }
  .footer--widget .item-advantages .swiper-button-next,
  .footer--widget .item-advantages .swiper-button-prev {
    color: #FFFFFF; }
  .footer--widget .item-advantages .swiper-button-prev {
    right: 15px;
    left: auto; }
    .footer--widget .item-advantages .swiper-button-prev:after {
      font-size: 16px; }
  .footer--widget .item-advantages .swiper-button-next {
    right: -5px; }
    .footer--widget .item-advantages .swiper-button-next:after {
      font-size: 16px; }

.footer--widget.fixed .footer--widget_container {
  position: static;
  translate: 0 0; }

.footer--widget:not(.fixed) .footer--widget_container.is_scrolled {
  background-color: rgba(5, 10, 23, 0.3); }

.popup .footer--widget_container,
.footer--widget .footer--widget_container {
  color: var(--c-text-white); }
  .popup .footer--widget_container .item-temperature,
  .footer--widget .footer--widget_container .item-temperature {
    justify-content: center; }
  .popup .footer--widget_container .item-advantages,
  .footer--widget .footer--widget_container .item-advantages {
    overflow: hidden;
    position: relative; }
    @media (max-width: 1241px) {
      .popup .footer--widget_container .item-advantages .footer--widget_item--content,
      .footer--widget .footer--widget_container .item-advantages .footer--widget_item--content {
        flex-direction: column; } }
    @media (min-width: 1241px) {
      .popup .footer--widget_container .item-advantages .item-advantages-slide,
      .footer--widget .footer--widget_container .item-advantages .item-advantages-slide {
        opacity: 0;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        overflow: hidden;
        transition: var(--transition) opacity; } }
    .popup .footer--widget_container .item-advantages .item-advantages-slide .icon,
    .footer--widget .footer--widget_container .item-advantages .item-advantages-slide .icon {
      color: #CFE1FF; }
    @media (min-width: 1241px) {
      .popup .footer--widget_container .item-advantages .item-advantages-slide.swiper-slide-active,
      .footer--widget .footer--widget_container .item-advantages .item-advantages-slide.swiper-slide-active {
        opacity: 1; } }
  .popup .footer--widget_container span,
  .footer--widget .footer--widget_container span {
    white-space: nowrap;
    line-height: 1;
    font-size: var(--fs-16); }

@media (max-width: 1240px) {
  .popup_item--title,
  .footer--widget_item--title {
    margin-bottom: 8px;
    font-weight: 500; } }

.popup_item--content,
.footer--widget_item--content {
  display: flex;
  gap: 16px; }

.popup .item-grade_item,
.footer--widget .item-grade_item {
  display: flex;
  gap: 8px;
  align-items: center; }

.popup .icon-star,
.footer--widget .icon-star {
  color: #FDA95C;
  font-size: 1.3em; }

.info-widjet {
  display: none; }
  @media (max-width: 1240px) {
    .info-widjet {
      transition: var(--transition);
      display: block;
      position: fixed;
      z-index: 10;
      top: 50%;
      right: 0; }
      .info-widjet a {
        transform: rotate(-90deg) translateY(-100%);
        transform-origin: top right;
        font-size: 14px;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 4px;
        background-color: var(--c-surface-accent);
        color: var(--c-text-white);
        border-radius: 8px 8px 0 0; }
      .info-widjet .icon {
        rotate: -90deg; } }

#map-big {
  width: 100%;
  min-height: 400px; }
  @media (min-width: 769px) {
    #map-big {
      min-height: 800px; } }
  #map-big .ymaps-2-1-79-balloon__content {
    font: inherit; }
  #map-big .ymaps-2-1-79-image {
    -webkit-clip-path: url(#placemark_clip_path);
    clip-path: url(#placemark_clip_path);
    aspect-ratio: 40 / 50;
    width: 40px;
    height: 50px;
    background-size: cover !important;
    background-position: center !important; }

.content_map .block--map {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  margin: var(--g-section) 0;
  position: relative; }

.content_scheme {
  overflow-x: hidden; }
  .content_scheme .block--sections {
    position: absolute;
    top: 120px;
    left: 50%;
    max-width: var(--container);
    margin: auto;
    justify-content: space-between;
    translate: -50% 0;
    width: 100%;
    flex-wrap: nowrap;
    align-items: flex-start; }
    @media (max-width: 1024px) {
      .content_scheme .block--sections {
        height: 100%;
        flex-direction: column;
        align-items: start;
        pointer-events: none;
        padding: 0;
        max-width: 100%; } }

.scheme {
  position: relative;
  overflow: hidden;
  background-color: #fff; }
  @media (max-width: 1024px) {
    .scheme {
      padding: var(--g-section) 0; } }
  .scheme--svg {
    aspect-ratio: 1920 / 960;
    margin-top: 140px; }
    @media (max-width: 1024px) {
      .scheme--svg {
        overflow-x: auto;
        width: auto;
        max-width: 100vw;
        height: 70vh; }
        .scheme--svg svg {
          height: 70vh; } }

@media (min-width: 1024px) {
  .scheme--filters {
    flex-wrap: wrap; } }

.scheme--filters,
.scheme--actions {
  display: flex;
  gap: 16px;
  pointer-events: all; }
  @media (max-width: 1140px) {
    .scheme--filters,
    .scheme--actions {
      overflow-x: auto;
      width: 100%;
      max-width: 100vw;
      padding: 0 16px; } }
  .scheme--filters button,
  .scheme--actions button {
    pointer-events: all;
    text-align: center;
    white-space: nowrap;
    border-radius: 24px;
    border: 2px solid var(--c-text-disabled);
    padding: 12px 20px;
    font-size: var(--fs-18);
    opacity: 0.7;
    display: flex;
    align-items: center;
    transition: var(--transition);
    gap: 8px; }
    .scheme--filters button:hover, .scheme--filters button:focus, .scheme--filters button:active,
    .scheme--actions button:hover,
    .scheme--actions button:focus,
    .scheme--actions button:active {
      opacity: 1; }
    .scheme--filters button.active,
    .scheme--actions button.active {
      opacity: 1;
      border: 2px solid var(--c-surface-accent-hovered); }

.scheme--actions {
  position: relative; }

.scheme--switch {
  font-size: var(--fs-18);
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 12px 20px;
  cursor: pointer;
  white-space: nowrap;
  opacity: .7;
  border: 2px solid var(--c-text-disabled);
  border-radius: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px; }
  .scheme--switch input {
    display: none; }
  .scheme--switch input:checked + .scheme--slider {
    background-color: #68AAF2; }
  .scheme--switch input:focus + .scheme--slider {
    box-shadow: 0 0 1px #8188a5; }
  .scheme--switch input:checked + .scheme--slider:before {
    left: 100%;
    translate: -100% -50%; }

.scheme--slider {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 16px;
  cursor: pointer;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  border: 1px solid rgba(129, 136, 165, 0.8);
  border-radius: 34px;
  background-color: rgba(196, 207, 255, 0.4); }
  .scheme--slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0;
    top: 50%;
    translate: 0 -50%;
    background-color: white;
    transition: var(--transition);
    border-radius: 50%; }

.scheme--info .icon.icon-link {
  transition: var(--transition);
  rotate: 90deg; }

.scheme--info_opening {
  padding: 20px 0 0;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  transition: var(--transition); }
  @media (max-width: 1024px) {
    .scheme--info_opening {
      padding: 0 0 20px;
      top: auto;
      right: auto;
      left: 0;
      bottom: 100%; } }
  .scheme--info_opening_title h3 {
    font-size: var(--fs-36);
    margin-bottom: var(--g-content); }
  .scheme--info_opening_container {
    background-color: #FFFFFF;
    border-radius: var(--border-corner);
    box-shadow: 0px 3px 12px 0px #021F541F;
    padding: var(--g-32) var(--g-content); }
  .scheme--info_opening_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px; }
  .scheme--info_opening_item {
    display: flex;
    align-items: center; }
  .scheme--info_opening.is_active {
    pointer-events: auto;
    opacity: 1; }
    .scheme--info_opening.is_active .icon {
      rotate: -90deg; }

.scheme .scheme--svg_distance {
  transition: var(--transition);
  opacity: 0; }

.scheme.is_showing_distance .scheme--svg_distance {
  opacity: 1; }

.scheme.is_showing_distance .scheme--svg .placemark {
  opacity: 0; }

.placemark {
  transition: var(--transition);
  cursor: pointer;
  opacity: 1; }

.placemark.hidden {
  opacity: 0; }

.placemark {
  filter: drop-shadow(0 1px 6px rgba(255, 255, 255, 0.5)); }

.placemark-entrance,
.placemark-entrance svg .placemark-color {
  fill: #3A53D2;
  stroke: #3A53D2; }

.placemark-accommodation,
.placemark-accommodation svg .placemark-color {
  fill: #5a9de7;
  stroke: #5a9de7; }

.placemark-food,
.placemark-food svg .placemark-color {
  fill: #FF5454;
  stroke: #FF5454; }

.placemark-administration,
.placemark-administration svg .placemark-color {
  fill: #B766E9;
  stroke: #B766E9; }

.placemark-entertainment,
.placemark-entertainment svg .placemark-color {
  fill: #FFE500;
  stroke: #FFE500; }

.placemark-receptionist,
.placemark-receptionist svg .placemark-color {
  fill: #FF8A00;
  stroke: #FF8A00; }

.placemark-medicine-procedures,
.placemark-medicine-procedures svg .placemark-color {
  fill: #1FC339;
  stroke: #1FC339; }

.placemark-parking,
.placemark-parking svg .placemark-color {
  fill: #686868;
  stroke: #686868; }

.placemark-pool,
.placemark-pool svg .placemark-color {
  fill: #24CBF0;
  stroke: #24CBF0; }

.placemark-medicine-center,
.placemark-medicine-center svg .placemark-color {
  fill: #2A952E;
  stroke: #2A952E; }

.scheme--tooltip {
  position: fixed;
  opacity: 0;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 6;
  max-width: 240px;
  transition: opacity 0.18s;
  pointer-events: none; }
  .scheme--tooltip p {
    color: #2A2827;
    font-size: var(--fs-16);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
  .scheme--tooltip.is_active {
    opacity: 1; }

.scheme_images {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-section-inner);
  padding: var(--g-section-inner) 0;
  max-width: var(--container);
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .scheme_images {
      grid-template-columns: repeat(3, 1fr); } }
  .scheme_images--col {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  .scheme_images--title {
    font-size: var(--fs-40); }
  .scheme_images--text {
    font-size: var(--fs-20);
    color: var(--c-text-subtitle); }
  @media (min-width: 1024px) {
    .scheme_images--image {
      margin: var(--g-section) 0; } }
  .scheme_images--image:first-child:not(:only-child) {
    margin-top: 0; }
  .scheme_images--image img {
    aspect-ratio: 477 / 640;
    width: 100%;
    height: auto; }

.scheme_article {
  padding-top: var(--g-section);
  position: relative;
  z-index: 2; }
  @media (max-width: 1024px) {
    .scheme_article {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column; } }
  .scheme_article--bg {
    content: "";
    position: absolute;
    right: 0;
    top: -10%;
    display: block;
    width: 50vw;
    aspect-ratio: 1 / 1;
    height: auto;
    z-index: 1; }
  .scheme_article--title {
    font-size: clamp(32px, calc(32px + (76 - 32) * ((100vw - 1024px) / (1920 - 1024))), 76px);
    line-height: 1.1;
    max-width: var(--container);
    margin: 0 auto;
    padding: var(--g-section) 0;
    z-index: 2;
    position: relative; }
    @media (max-width: 1024px) {
      .scheme_article--title {
        order: 1; } }
  .scheme_article--text {
    font-family: "arsenica", sans-serif;
    font-weight: 300;
    font-size: var(--fs-40);
    text-align: right;
    max-width: var(--container);
    margin: var(--g-section-inner) auto;
    position: relative;
    z-index: 3; }
    @media (min-width: 1024px) {
      .scheme_article--text {
        transform: translateX(calc((100vw - var(--container)) / -2)); } }
  .scheme_article--map {
    aspect-ratio: 1400 / 872;
    width: 1400px;
    position: relative;
    z-index: 4; }
    @media (min-width: 1024px) {
      .scheme_article--map {
        width: 1400px; } }
    @media (max-width: 1024px) {
      .scheme_article--map {
        left: -20vw;
        width: 150vw;
        order: 2; } }
    .scheme_article--map svg {
      overflow: visible !important; }
  .scheme_article--image {
    position: relative;
    z-index: 2;
    aspect-ratio: 677 / 416; }
    @media (min-width: 1024px) {
      .scheme_article--image {
        position: absolute;
        right: 0;
        width: 677px;
        margin-left: auto; } }
    @media (max-width: 1024px) {
      .scheme_article--image {
        order: 3; }
        .scheme_article--image img {
          display: none; } }

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  @media (max-width: 1024px) {
    .clouds {
      display: none; } }
  .clouds img {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: screen; }

#zooming_placemark,
.scheme_article--map {
  pointer-events: none; }

.block {
  color: var(--c-text-dark);
  margin: var(--g-section) 0; }
  .block--wrapper {
    max-width: var(--container);
    margin: 0 auto; }
  .block--content, .block--elements {
    margin-top: var(--g-content); }

.block--content > img {
  aspect-ratio: 1880 / 900;
  height: auto; }

.block-background-color {
  padding: var(--g-section) 0;
  margin: 0;
  background-color: var(--bck-color); }

.block--head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; }
  .block--head_subtitle {
    width: fit-content;
    font-family: 'caveat', sans-serif;
    font-size: var(--fs-28);
    color: var(--c-text-accent-subdued); }
    .block--head_subtitle:empty {
      display: none; }
    .block--head_subtitle p {
      margin-bottom: 8px; }
      .block--head_subtitle p:empty {
        display: none; }
  .block--head_caption p {
    line-height: 1.4;
    max-width: 700px;
    font-size: var(--fs-20);
    color: var(--c-text-accent-subdued);
    margin-top: 16px; }
    .block--head_caption p:empty {
      display: none; }
  .block--head_title {
    line-height: 1; }
    @media (max-width: 768px) {
      .block--head_title {
        max-width: 90%;
        word-break: break-word; } }
    .block--head_title h1 {
      font-weight: 300;
      font-size: var(--fs-title); }
    .block--head_title h2 {
      font-weight: 300;
      font-size: var(--fs-56); }
    .block--head_title a h1 {
      font-size: var(--fs-title); }
    .block--head_title a h2 {
      font-size: var(--fs-56); }
    .block--head_title a h1, .block--head_title a h2 {
      position: relative; }
      .block--head_title a h1:after, .block--head_title a h2:after {
        content: "\e913";
        font-family: 'icomoon' !important;
        font-size: 0.6em;
        color: currentColor;
        padding-left: 0.2em;
        color: var(--c-surface-accent);
        opacity: 1;
        transition: 0.25s linear;
        display: inline-block; }
        @media (max-width: 768px) {
          .block--head_title a h1:after, .block--head_title a h2:after {
            position: static; } }
    .block--head_title a:hover h1:after, .block--head_title a:hover h2:after, .block--head_title a:focus h1:after, .block--head_title a:focus h2:after, .block--head_title a:active h1:after, .block--head_title a:active h2:after {
      transform: translateX(15%);
      opacity: 0.6; }

.block--actions, .button_wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .block--actions .button, .button_wrapper .button {
    margin-top: var(--g-24); }

.block--actions:empty {
  display: none; }

.block--pagination {
  display: flex;
  justify-content: center; }
  @media (max-width: 768px) {
    .block--pagination {
      grid-column: span 1; } }

.block--sections {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: var(--g-content) 0;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    .block--sections {
      overflow-x: auto;
      padding: 20px 16px;
      margin: 0 -16px;
      flex-wrap: nowrap;
      max-width: 100vw; } }
  .block--sections [data-button],
  .block--sections .block--sections_item,
  .block--sections a {
    text-align: center;
    white-space: nowrap;
    border-radius: 24px;
    border: 2px solid var(--c-text-disabled);
    padding: 12px 20px;
    font-size: var(--fs-18);
    opacity: 0.7;
    display: flex;
    align-items: center;
    transition: var(--transition);
    gap: 8px; }
    .block--sections [data-button]:hover, .block--sections [data-button]:focus, .block--sections [data-button]:active,
    .block--sections .block--sections_item:hover,
    .block--sections .block--sections_item:focus,
    .block--sections .block--sections_item:active,
    .block--sections a:hover,
    .block--sections a:focus,
    .block--sections a:active {
      opacity: 1; }
    .block--sections [data-button].active,
    .block--sections .block--sections_item.active,
    .block--sections a.active {
      opacity: 1;
      border: 2px solid var(--c-surface-accent-hovered); }
  .block--sections a[data-price] .icon {
    transition: var(--transition);
    rotate: 0;
    color: var(--c-text-accent); }
  .block--sections a[data-price].active .icon {
    transition: var(--transition);
    rotate: 180deg; }
  .block--sections .actions {
    position: relative; }
    .block--sections .actions .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 16px; }
    .block--sections .actions .switch input {
      display: none; }
    .block--sections .actions .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(196, 207, 255, 0.4);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;
      border: 1px solid rgba(129, 136, 165, 0.8); }
    .block--sections .actions .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      background-color: white;
      transition: var(--transition);
      border-radius: 50%; }
    .block--sections .actions input:checked + .slider {
      background-color: #68AAF2; }
    .block--sections .actions input:focus + .slider {
      box-shadow: 0 0 1px #8188a5; }
    .block--sections .actions input:checked + .slider:before {
      left: 100%;
      translate: -100% -50%; }
    .block--sections .actions .info .icon.icon-link {
      transition: var(--transition);
      rotate: 90deg; }
    .block--sections .actions .info-opening {
      padding: 20px 0 0;
      pointer-events: none;
      opacity: 0;
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      transition: var(--transition); }
      @media (max-width: 1024px) {
        .block--sections .actions .info-opening {
          padding: 0 0 20px;
          top: auto;
          right: auto;
          left: 0;
          bottom: 100%; } }
      .block--sections .actions .info-opening-title h3 {
        font-size: var(--fs-36);
        margin-bottom: var(--g-content); }
      .block--sections .actions .info-opening-container {
        background-color: #FFFFFF;
        border-radius: var(--border-corner);
        box-shadow: 0px 3px 12px 0px #021F541F;
        padding: var(--g-32) var(--g-content); }
      .block--sections .actions .info-opening-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px; }
      .block--sections .actions .info-opening-item {
        display: flex;
        align-items: center; }
    .block--sections .actions .info.active .icon {
      rotate: -90deg; }
    .block--sections .actions .info.active + .info-opening {
      pointer-events: auto;
      opacity: 1; }

.block_banner {
  overflow: hidden;
  position: relative; }
  .block_banner .block--wrapper {
    height: 100%; }
  .block_banner .block--head,
  .block_banner .block--actions {
    z-index: 2;
    position: relative; }
  .block_banner .block--content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .block_banner .block--content img, .block_banner .block--content video {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover; }
  .block_banner .block--head > * {
    z-index: 2;
    position: relative; }
  .block_banner .banner {
    display: grid; }

.block_banner.banner-group:not(.banner-hero) .block--wrapper {
  overflow: hidden; }

.block_banner.banner-group:not(.banner-hero) .slider_controls {
  position: absolute;
  top: 50%;
  width: var(--container);
  left: calc((100% - var(--container)) / 2);
  justify-content: space-between; }

.block_banner.banner-group:not(.banner-hero) .swiper-button-next {
  transform: translateX(calc(100% + 24px)); }

.block_banner.banner-group:not(.banner-hero) .swiper-button-prev {
  transform: translateX(calc(-100% - 24px)); }

.block_banner:not(.banner-group):not(.banner-hero) .banner {
  margin: auto; }

.block_banner.banner-group .banner.swiper-slide-active {
  z-index: 1; }

.block_banner .banner {
  min-width: var(--container);
  width: var(--container);
  height: auto;
  overflow: hidden;
  position: relative;
  place-content: end start;
  place-items: end start; }
  @media (max-width: 768px) {
    .block_banner .banner {
      aspect-ratio: 320 / 420;
      padding: 24px 16px;
      min-width: calc(100vw - 32px - 8px); } }

.block_banner .block--content:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 51.09%, rgba(0, 0, 0, 0.3) 64.69%, rgba(0, 0, 0, 0.3) 50.57%);
  z-index: 0; }

.block_banner .block--head_caption p {
  line-height: 1.5;
  font-size: var(--fs-24); }

.block_banner.banner-hero {
  height: 100vh;
  margin: 0;
  position: relative; }
  .block_banner.banner-hero .block--wrapper {
    padding: 0;
    max-width: 100%; }
  .block_banner.banner-hero .banner {
    position: relative;
    height: 100%;
    min-width: 100%;
    place-items: start;
    place-content: start;
    color: var(--c-text-white);
    padding: calc(20% + var(--headerHeight, 88px)) calc((100% - var(--container)) / 2); }
    @media (min-width: 998px) {
      .block_banner.banner-hero .banner {
        padding: calc(120px + var(--headerHeight, 137px)) calc((100% - var(--container)) / 2); } }
  .block_banner.banner-hero .block--head {
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 720px; }
    .block_banner.banner-hero .block--head:before {
      content: "";
      position: absolute;
      top: -10%;
      left: -10%;
      display: block;
      width: 120%;
      height: 120%;
      background: rgba(0, 0, 0, 0.35);
      filter: blur(125px); }
    .block_banner.banner-hero .block--head_caption p {
      color: var(--c-text-white);
      opacity: 0.8; }
  .block_banner.banner-hero .slider_controls {
    display: none; }
    @media (min-width: 1241px) {
      .block_banner.banner-hero .slider_controls {
        display: flex;
        justify-content: space-between;
        bottom: clamp(10%, calc(10% + (50 - 10) * ((100vw - 1024px) / (1920 - 1024))), 50%);
        left: 50%;
        translate: -50% -50%;
        width: calc(100% - var(--g-section));
        pointer-events: none; } }
  @media (min-width: 1241px) and (min-width: 1760px) {
    .block_banner.banner-hero .slider_controls {
      bottom: 50%; } }
    .block_banner.banner-hero .slider_controls .swiper-button-prev,
    .block_banner.banner-hero .slider_controls .swiper-button-next {
      pointer-events: auto; }
      .block_banner.banner-hero .slider_controls .swiper-button-prev:after,
      .block_banner.banner-hero .slider_controls .swiper-button-next:after {
        color: white;
        background-color: rgba(255, 255, 255, 0.3); }
  .block_banner.banner-hero .swiper_pagination {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10%; }
    @media (max-width: 768px) {
      .block_banner.banner-hero .swiper_pagination {
        bottom: 5%; } }
    .block_banner.banner-hero .swiper_pagination .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      z-index: 10;
      background: #fff;
      opacity: 0.6;
      position: relative; }
    .block_banner.banner-hero .swiper_pagination .swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      opacity: 1; }

.block_banner.banner-no_media .banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  aspect-ratio: unset;
  padding: 0; }

.block_banner.banner-no_media .block--head {
  max-width: 1000px;
  width: 100%;
  align-items: center; }
  .block_banner.banner-no_media .block--head .f-h2 {
    font-size: var(--fs-title); }

.block_banner.banner-no_media .block--head_title,
.block_banner.banner-no_media .block--head_caption p {
  text-align: center;
  max-width: 1000px; }

@media (max-width: 768px) {
  .block_banner.banner-no_media .block--actions {
    justify-content: center;
    flex-wrap: wrap; } }

.block_banner.banner-no_media .block--actions .button {
  margin-top: 24px; }
  @media (min-width: 768px) {
    .block_banner.banner-no_media .block--actions .button {
      margin-top: 32px; } }

.block_image_text {
  margin: 0;
  padding: var(--g-section) 0; }
  .block_image_text .block--wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: start;
    grid-template-rows: 0.5fr auto 0.5fr;
    gap: 0 24px; }
    .block_image_text .block--wrapper:has(.block--image) {
      grid-template-rows: 0.5fr auto 0.5fr; }
    @media (max-width: 768px) {
      .block_image_text .block--wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto; } }
  .block_image_text .block--head {
    grid-column: 1;
    border-radius: 8px 8px 0 0; }
  .block_image_text .block--content,
  .block_image_text .block--actions {
    grid-column: 2; }
    @media (max-width: 768px) {
      .block_image_text .block--content,
      .block_image_text .block--actions {
        grid-column: 1; } }
  .block_image_text .block--image ~ .block--head,
  .block_image_text .block--image ~ .block--content,
  .block_image_text .block--image ~ .block--actions {
    grid-column: 1;
    width: 100%;
    background-color: #fff; }
    @media (min-width: 769px) {
      .block_image_text .block--image ~ .block--head,
      .block_image_text .block--image ~ .block--content,
      .block_image_text .block--image ~ .block--actions {
        padding: 16px var(--g-section); } }
  .block_image_text .block--image ~ .block--head {
    justify-content: flex-end; }
  .block_image_text .block--image {
    grid-column: 2;
    grid-row: 1 / span 3;
    max-width: 100%;
    border-radius: var(--border-corner); }
    @media (max-width: 768px) {
      .block_image_text .block--image {
        grid-column: 1;
        margin-bottom: 8px; } }
    .block_image_text .block--image img, .block_image_text .block--image video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: var(--border-corner); }
      @media (min-width: 769px) {
        .block_image_text .block--image img, .block_image_text .block--image video {
          top: var(--headerHeight); } }
  .block_image_text .block--head {
    align-items: flex-start; }
    @media (max-width: 768px) {
      .block_image_text .block--head {
        padding: 0; } }
    .block_image_text .block--head_title {
      color: var(--c-text-dark); }
  .block_image_text .block--content {
    margin-top: 0;
    padding: 16px 0; }
    @media (max-width: 768px) {
      .block_image_text .block--content {
        padding: 0; } }
    .block_image_text .block--content > span {
      color: var(--c-text-accent-subdued); }
    .block_image_text .block--content > span,
    .block_image_text .block--content > p {
      margin-top: 16px;
      display: inline-block; }
  .block_image_text .block--actions {
    align-items: flex-start;
    border-radius: 0 0 8px 8px; }
    @media (max-width: 768px) {
      .block_image_text .block--actions {
        padding: 0; } }
  .block_image_text.block_image_text-reversed .block--wrapper .block--image {
    grid-column: 1; }
  .block_image_text.block_image_text-reversed .block--wrapper .block--head,
  .block_image_text.block_image_text-reversed .block--wrapper .block--content,
  .block_image_text.block_image_text-reversed .block--wrapper .block--actions {
    padding: 0 var(--g-section);
    grid-column: 2; }
    @media (max-width: 768px) {
      .block_image_text.block_image_text-reversed .block--wrapper .block--head,
      .block_image_text.block_image_text-reversed .block--wrapper .block--content,
      .block_image_text.block_image_text-reversed .block--wrapper .block--actions {
        grid-column: 1;
        padding: 0; } }
  .block_image_text.block_image_text-reversed .block--wrapper .block--content {
    margin-top: 0;
    padding: 16px var(--g-section); }
    @media (max-width: 768px) {
      .block_image_text.block_image_text-reversed .block--wrapper .block--content {
        padding: 0; } }
  @media (max-width: 768px) and (max-width: 768px) {
    .block_image_text.block_image_text-reversed .block--wrapper .block--content .block--actions {
      padding: 0 var(--g-elements) 16px; } }
  .block_image_text.block_image_text-reversed.block_image_text-mountain {
    position: relative;
    overflow: hidden; }
    .block_image_text.block_image_text-reversed.block_image_text-mountain .block--image ~ .block--head {
      padding-top: var(--g-section); }
    .block_image_text.block_image_text-reversed.block_image_text-mountain .block--image ~ .block--actions {
      padding-bottom: var(--g-section); }
    .block_image_text.block_image_text-reversed.block_image_text-mountain .block--content {
      z-index: 1;
      background-color: transparent; }
    .block_image_text.block_image_text-reversed.block_image_text-mountain:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: var(--g-section);
      width: clamp(291px, calc(291px + (486 - 291) * ((100vw - 1024px) / (1920 - 1024))), 486px);
      height: clamp(221px, calc(221px + (615 - 221) * ((100vw - 1024px) / (1920 - 1024))), 615px);
      background-image: url("../img/mountain.png");
      left: auto;
      scale: 1 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      z-index: 0; }
      @media (max-width: 768px) {
        .block_image_text.block_image_text-reversed.block_image_text-mountain:after {
          opacity: 0.4; } }
  .block_image_text.block_image_text-ratio .block--image img, .block_image_text.block_image_text-ratio .block--image video {
    aspect-ratio: 1 / 1; }
  .block_image_text.block_image_text-ratio_portrait .block--image img, .block_image_text.block_image_text-ratio_portrait .block--image video {
    aspect-ratio: 3/4; }
  .block_image_text.block_image_text-ratio_landscape .block--image img, .block_image_text.block_image_text-ratio_landscape .block--image video {
    aspect-ratio: 3 / 2; }
  .block_image_text.block_image_text-wide {
    overflow: hidden; }
    .block_image_text.block_image_text-wide .block--image img, .block_image_text.block_image_text-wide .block--image video {
      margin-right: calc((100vw - var(--container) - var(--scrollbarWidth)) / -2);
      width: calc(100% + (100vw - var(--container) - var(--scrollbarWidth)) / 2);
      border-radius: var(--border-corner) 0 0 var(--border-corner); }
      @media (max-width: 768px) {
        .block_image_text.block_image_text-wide .block--image img, .block_image_text.block_image_text-wide .block--image video {
          width: 100vw;
          margin: 0 -16px; } }
  .block_image_text.block_image_text-reversed.block_image_text-wide {
    overflow: hidden; }
    .block_image_text.block_image_text-reversed.block_image_text-wide .block--image img, .block_image_text.block_image_text-reversed.block_image_text-wide .block--image video {
      margin-left: calc((100vw - var(--container) - var(--scrollbarWidth)) / -2);
      width: calc(100% + (100vw - var(--container) - var(--scrollbarWidth)) / 2);
      border-radius: 0 var(--border-corner) var(--border-corner) 0; }
      @media (max-width: 768px) {
        .block_image_text.block_image_text-reversed.block_image_text-wide .block--image img, .block_image_text.block_image_text-reversed.block_image_text-wide .block--image video {
          width: auto;
          max-width: 100vw;
          margin: 0 -16px; } }
  .block_image_text.block_image_text-bg .block--wrapper .block--head {
    padding-top: 16px; }
  .block_image_text.block_image_text-bg .block--wrapper .block--actions {
    padding-bottom: 16px; }
  .block_image_text.block_image_text-bg .block--image ~ .block--head,
  .block_image_text.block_image_text-bg .block--image ~ .block--content,
  .block_image_text.block_image_text-bg .block--image ~ .block--actions {
    background-color: var(--bck-color); }
    @media (max-width: 768px) {
      .block_image_text.block_image_text-bg .block--image ~ .block--head,
      .block_image_text.block_image_text-bg .block--image ~ .block--content,
      .block_image_text.block_image_text-bg .block--image ~ .block--actions {
        padding: 16px; } }
  .block_image_text.block_image_text-wrapper_bg {
    background-color: var(--bck-color); }
    @media (max-width: 768px) {
      .block_image_text.block_image_text-wrapper_bg .block--image ~ .block--head,
      .block_image_text.block_image_text-wrapper_bg .block--image ~ .block--content,
      .block_image_text.block_image_text-wrapper_bg .block--image ~ .block--actions {
        padding: 16px; } }
    .block_image_text.block_image_text-wrapper_bg .block--wrapper {
      gap: 0 16px; }
  .block_image_text.block_image_text.block_image_text-text .block--wrapper {
    grid-template-rows: auto; }
    @media (min-width: 769px) {
      .block_image_text.block_image_text.block_image_text-text .block--wrapper {
        padding: 0 var(--g-section); } }
  .block_image_text.block_image_text.block_image_text-text .block--head_caption {
    line-height: 1.5; }
  .block_image_text.block_image_text.block_image_text-text .block--content {
    color: var(--c-text-dark); }

.block_image_text.block_image_text-bg.block_image_text-wrapper_bg.block_image_text-wide {
  padding: 0; }
  .block_image_text.block_image_text-bg.block_image_text-wrapper_bg.block_image_text-wide img {
    border-radius: 0; }

.block_image_text.block_image_text-ratio_landscape.block_image_text-bg {
  margin: var(--g-section) 0; }
  @media (max-width: 768px) {
    .block_image_text.block_image_text-ratio_landscape.block_image_text-bg {
      padding: 0; }
      .block_image_text.block_image_text-ratio_landscape.block_image_text-bg .block--image {
        max-width: 100vw;
        width: 100vw; } }
  @media (min-width: 769px) {
    .block_image_text.block_image_text-ratio_landscape.block_image_text-bg .block--image ~ .block--head {
      padding-top: var(--g-section); } }
  @media (min-width: 769px) {
    .block_image_text.block_image_text-ratio_landscape.block_image_text-bg .block--image ~ .block--actions {
      padding-bottom: var(--g-section); } }

.block_image_text_mask-1 .block--wrapper {
  grid-template-columns: 1fr 1fr; }
  @media (max-width: 768px) {
    .block_image_text_mask-1 .block--wrapper {
      grid-template-columns: 1fr; } }
  .block_image_text_mask-1 .block--wrapper .block--head,
  .block_image_text_mask-1 .block--wrapper .block--content,
  .block_image_text_mask-1 .block--wrapper .block--actions {
    background-color: transparent; }

.block_image_text_mask-1 .block--image img {
  position: static; }

.block_image_text_mask-1 svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none; }

.block_image_text_mask-1 img {
  clip-path: url(#mask);
  background-color: #fff; }

.block_image_text.block_image_text_mask-2 {
  overflow: hidden; }
  @media (max-width: 768px) {
    .block_image_text.block_image_text_mask-2 {
      padding: 20px 0; } }
  .block_image_text.block_image_text_mask-2 .block--wrapper {
    clip-path: url(#mask-2);
    background-color: #fff;
    --container: calc(100vw - 80px); }
    @media (max-width: 768px) {
      .block_image_text.block_image_text_mask-2 .block--wrapper {
        clip-path: url(#mask-2-mob);
        padding: 0 0 60px 0;
        --container: 100vw; } }
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--head,
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--content,
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--actions {
      padding: 0 var(--g-section) 0 var(--g-section-inner); }
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--head {
      padding-top: var(--g-elements); }
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--actions {
      padding-bottom: var(--g-elements); }
      @media (max-width: 768px) {
        .block_image_text.block_image_text_mask-2 .block--wrapper .block--image ~ .block--actions {
          padding-bottom: clamp(40px, calc(40px + (80 - 40) * ((100vw - 375px) / (768 - 375))), 80px); } }
    .block_image_text.block_image_text_mask-2 .block--wrapper .block--image {
      min-height: auto; }
      @media (max-width: 768px) {
        .block_image_text.block_image_text_mask-2 .block--wrapper .block--image {
          aspect-ratio: 1/1; } }
      .block_image_text.block_image_text_mask-2 .block--wrapper .block--image img {
        border-radius: 0;
        height: 100%; }
        @media (max-width: 768px) {
          .block_image_text.block_image_text_mask-2 .block--wrapper .block--image img {
            padding: 0 10px;
            height: 100%;
            object-position: center right; } }
  .block_image_text.block_image_text_mask-2 svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none; }

.block_list .block--elements {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: var(--container);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 768px) {
    .block_list .block--elements {
      grid-template-columns: 1fr; } }

.block_list.block_list-slider {
  position: relative;
  overflow: hidden; }
  .block_list.block_list-slider .block--elements {
    position: relative;
    height: 100%;
    min-height: 100%; }
    @media (min-width: 1024px) {
      .block_list.block_list-slider .block--elements {
        overflow: hidden; } }
    .block_list.block_list-slider .block--elements .card {
      min-height: 100%;
      height: 100%; }
  .block_list.block_list-slider .slider_controls {
    pointer-events: none;
    left: unset;
    width: fit-content; }
    @media (max-width: 998px) {
      .block_list.block_list-slider .slider_controls {
        display: none; } }
    .block_list.block_list-slider .slider_controls .swiper-button-prev,
    .block_list.block_list-slider .slider_controls .swiper-button-next {
      width: 100%;
      pointer-events: auto; }

.card {
  flex-shrink: 0;
  flex-grow: 0;
  opacity: 1; }

.card--title {
  transition: var(--transition);
  overflow: hidden; }

.card--image {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.content_offers .card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: var(--transition);
  background-color: var(--c-text-accent); }

.content_offers .card--image {
  aspect-ratio: 382 / 280;
  border-radius: 8px 8px 0 0;
  overflow: hidden; }
  .content_offers .card--image img {
    transition: var(--transition);
    scale: 1;
    transform-origin: center;
    width: 100%;
    height: 100%; }

.content_offers .card--content {
  padding: var(--g-elements);
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  color: var(--c-text-white);
  flex-grow: 1;
  gap: var(--g-20); }
  @media (max-width: 575px) {
    .content_offers .card--content {
      justify-content: center; } }

.content_offers .card--title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative; }
  .content_offers .card--title h3 {
    font-size: var(--fs-44); }

.content_offers .card--text {
  flex-grow: 1; }

.content_offers .card--actions {
  display: none;
  margin-top: auto; }
  .content_offers .card--actions .button {
    padding: 0;
    overflow: inherit; }
    .content_offers .card--actions .button span {
      font-size: var(--fs-18); }
    .content_offers .card--actions .button:after {
      position: absolute;
      margin-left: 6px;
      content: "\e913";
      font-family: 'icomoon' !important;
      font-size: inherit;
      top: 0;
      right: 0;
      transform: translateX(24px);
      transition: 0.25s linear; }

.content_offers a.card:hover .card--image img {
  scale: 1.1; }

.content_offers a.card:hover .button:after {
  transition: var(--transition) transform;
  transform: translateX(32px); }

.content_offers a.card .card--content {
  justify-content: space-between; }

.content_offers a.card .card--actions {
  display: block; }

.content_offers a.card .card--title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; }

.content_accommodation .card,
.content_excursions .card,
.content_afisha .card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: var(--transition);
  transition: var(--transition); }
  .content_accommodation .card .card--image,
  .content_excursions .card .card--image,
  .content_afisha .card .card--image {
    aspect-ratio: 517 / 420;
    border-radius: 8px;
    overflow: hidden;
    position: relative; }
    .content_accommodation .card .card--image:after,
    .content_excursions .card .card--image:after,
    .content_afisha .card .card--image:after {
      position: absolute;
      content: '';
      inset: 0;
      background: linear-gradient(180deg, rgba(19, 21, 39, 0.5) 0%, rgba(19, 21, 39, 0) 98.97%);
      z-index: 1;
      pointer-events: none;
      opacity: 0.5;
      transition: var(--transition); }
    .content_accommodation .card .card--image img,
    .content_excursions .card .card--image img,
    .content_afisha .card .card--image img {
      transition: var(--transition) scale;
      scale: 1;
      transform-origin: center;
      width: 100%;
      height: 100%; }
    .content_accommodation .card .card--image .card-video,
    .content_excursions .card .card--image .card-video,
    .content_afisha .card .card--image .card-video {
      position: absolute;
      inset: 0;
      z-index: -1;
      opacity: 0; }
      .content_accommodation .card .card--image .card-video video,
      .content_excursions .card .card--image .card-video video,
      .content_afisha .card .card--image .card-video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .content_accommodation .card .card--image .card-video:empty,
    .content_excursions .card .card--image .card-video:empty,
    .content_afisha .card .card--image .card-video:empty {
      display: none; }
    .content_accommodation .card .card--image .play,
    .content_excursions .card .card--image .play,
    .content_afisha .card .card--image .play {
      position: absolute;
      right: 20px;
      bottom: 20px;
      background: rgba(206, 206, 206, 0.15);
      backdrop-filter: blur(8px);
      z-index: 10;
      border-radius: 50%;
      padding: 15px; }
      .content_accommodation .card .card--image .play:after,
      .content_excursions .card .card--image .play:after,
      .content_afisha .card .card--image .play:after {
        background: url("../img/play.svg") center no-repeat;
        background-size: contain;
        content: '';
        z-index: 10;
        position: relative;
        width: 22px;
        height: 22px;
        display: block;
        right: 0px;
        transition: var(--transition); }
    .content_accommodation .card .card--image .card-video.is_playing,
    .content_excursions .card .card--image .card-video.is_playing,
    .content_afisha .card .card--image .card-video.is_playing {
      z-index: 5;
      opacity: 1; }
    .content_accommodation .card .card--image .card-video.is_playing ~ .play:after,
    .content_excursions .card .card--image .card-video.is_playing ~ .play:after,
    .content_afisha .card .card--image .card-video.is_playing ~ .play:after {
      background: url("../img/icons/close.svg") center no-repeat; }
    .content_accommodation .card .card--image .images_hover,
    .content_excursions .card .card--image .images_hover,
    .content_afisha .card .card--image .images_hover {
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%; }
      @media (max-width: 1024px) {
        .content_accommodation .card .card--image .images_hover,
        .content_excursions .card .card--image .images_hover,
        .content_afisha .card .card--image .images_hover {
          pointer-events: none; } }
    .content_accommodation .card .card--image .images_hover div,
    .content_excursions .card .card--image .images_hover div,
    .content_afisha .card .card--image .images_hover div {
      height: 100%;
      flex: 1 1 auto; }
  .content_accommodation .card .card--label,
  .content_excursions .card .card--label,
  .content_afisha .card .card--label {
    position: absolute;
    z-index: 2;
    color: var(--c-text-white);
    top: var(--g-32);
    left: var(--g-elements);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px; }
    .content_accommodation .card .card--label p,
    .content_excursions .card .card--label p,
    .content_afisha .card .card--label p {
      position: relative;
      display: flex;
      align-items: center;
      letter-spacing: 0.002em;
      border-radius: 6px;
      padding: 6px 10px;
      overflow: hidden;
      background: rgba(206, 206, 206, 0.15);
      backdrop-filter: blur(8px); }
      .content_accommodation .card .card--label p span,
      .content_excursions .card .card--label p span,
      .content_afisha .card .card--label p span {
        position: relative;
        z-index: 2;
        font-size: clamp(12px, calc(12px + (14 - 12) * ((100vw - 1024px) / (1920 - 1024))), 14px); }
      .content_accommodation .card .card--label p span.icon,
      .content_excursions .card .card--label p span.icon,
      .content_afisha .card .card--label p span.icon {
        margin-right: 8px; }
  .content_accommodation .card .card--overlay,
  .content_excursions .card .card--overlay,
  .content_afisha .card .card--overlay {
    position: absolute;
    z-index: 2;
    top: var(--g-32);
    right: var(--g-card);
    color: var(--c-text-white); }
    .content_accommodation .card .card--overlay .card--overlay__text,
    .content_excursions .card .card--overlay .card--overlay__text,
    .content_afisha .card .card--overlay .card--overlay__text {
      opacity: 0; }
    .content_accommodation .card .card--overlay:hover .card--overlay__text,
    .content_excursions .card .card--overlay:hover .card--overlay__text,
    .content_afisha .card .card--overlay:hover .card--overlay__text {
      opacity: 1;
      translate: 0 0; }
    .content_accommodation .card .card--overlay__icon,
    .content_excursions .card .card--overlay__icon,
    .content_afisha .card .card--overlay__icon {
      text-align: right; }
      .content_accommodation .card .card--overlay__icon .icon,
      .content_excursions .card .card--overlay__icon .icon,
      .content_afisha .card .card--overlay__icon .icon {
        position: relative;
        padding: 8px;
        border-radius: 8px;
        display: inline-block;
        z-index: 2;
        background: rgba(206, 206, 206, 0.15);
        backdrop-filter: blur(8px); }
    .content_accommodation .card .card--overlay__text,
    .content_excursions .card .card--overlay__text,
    .content_afisha .card .card--overlay__text {
      width: 500%;
      position: absolute;
      display: block;
      pointer-events: none;
      font-size: 14px;
      right: 0;
      top: 120%;
      padding: 8px;
      background: rgba(206, 206, 206, 0.15);
      backdrop-filter: blur(8px);
      border-radius: 6px;
      translate: 0 -15%;
      transition: var(--transition); }
  .content_accommodation .card .card--content,
  .content_excursions .card .card--content,
  .content_afisha .card .card--content {
    padding: 16px 0;
    z-index: 3; }
  .content_accommodation .card .card--title,
  .content_excursions .card .card--title,
  .content_afisha .card .card--title {
    position: relative;
    line-height: 1.5;
    font-size: var(--fs-44);
    line-height: 1em; }
    .content_accommodation .card .card--title h3,
    .content_excursions .card .card--title h3,
    .content_afisha .card .card--title h3 {
      font-size: var(--fs-44); }
  .content_accommodation .card .card--text,
  .content_excursions .card .card--text,
  .content_afisha .card .card--text {
    font-size: var(--fs-22);
    font-weight: 600;
    color: var(--c-text-dark);
    margin-top: 16px; }
    .content_accommodation .card .card--text span,
    .content_excursions .card .card--text span,
    .content_afisha .card .card--text span {
      opacity: 0.5; }
  .content_accommodation .card .swiper-pagination-bullet,
  .content_excursions .card .swiper-pagination-bullet,
  .content_afisha .card .swiper-pagination-bullet {
    background-color: #FFFFFF;
    width: clamp(8px, calc(8px + (12 - 8) * ((100vw - 1024px) / (1920 - 1024))), 12px);
    height: clamp(8px, calc(8px + (12 - 8) * ((100vw - 1024px) / (1920 - 1024))), 12px);
    transition: var(--transition); }
  .content_accommodation .card .swiper-pagination-bullet-active,
  .content_excursions .card .swiper-pagination-bullet-active,
  .content_afisha .card .swiper-pagination-bullet-active {
    scale: 1.4; }

.content_accommodation a.card--title:hover,
.content_excursions a.card--title:hover,
.content_afisha a.card--title:hover {
  color: var(--c-text-accent); }

.content_programm .card,
.content_services .card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: var(--transition);
  background-color: var(--bck-color);
  gap: 16px;
  padding: 12px 16px; }
  @media (min-width: 768px) {
    .content_programm .card,
    .content_services .card {
      padding: var(--g-card) var(--g-20); } }

.content_programm .card--content,
.content_services .card--content {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1; }

.content_programm .card--title,
.content_services .card--title {
  font-size: var(--fs-28);
  position: relative; }
  .content_programm .card--title h3,
  .content_services .card--title h3 {
    font-size: var(--fs-36);
    font-weight: 700;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
    @media (min-width: 768px) {
      .content_programm .card--title h3,
      .content_services .card--title h3 {
        min-height: 2.5em; } }
    .content_programm .card--title h3 span,
    .content_services .card--title h3 span {
      display: block; }

.content_programm .card--image,
.content_services .card--image {
  aspect-ratio: 342 / 360;
  overflow: hidden; }
  .content_programm .card--image img,
  .content_services .card--image img {
    transition: var(--transition);
    scale: 1;
    transform-origin: center;
    width: 100%;
    height: 100%; }

.content_programm .card--text p,
.content_services .card--text p {
  font-size: 16px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }
  .content_programm .card--text p:empty,
  .content_services .card--text p:empty {
    display: none; }

.content_programm .card--actions,
.content_services .card--actions {
  display: none;
  margin-top: auto; }
  .content_programm .card--actions .button,
  .content_services .card--actions .button {
    padding: 0;
    overflow: inherit; }
    .content_programm .card--actions .button span,
    .content_services .card--actions .button span {
      font-size: var(--fs-18); }
    .content_programm .card--actions .button:after,
    .content_services .card--actions .button:after {
      position: absolute;
      margin-left: 6px;
      content: "\e913";
      font-family: 'icomoon' !important;
      font-size: inherit;
      top: 0;
      right: 0;
      transform: translateX(24px);
      transition: 0.25s linear; }

.content_programm a.card:hover,
.content_services a.card:hover {
  box-shadow: 0px 4px 16px -10px var(--c-surface-accent); }
  .content_programm a.card:hover .card--image img,
  .content_services a.card:hover .card--image img {
    scale: 1.1; }
  .content_programm a.card:hover .button:after,
  .content_services a.card:hover .button:after {
    transition: var(--transition);
    transform: translateX(32px); }
  .content_programm a.card:hover .card--title,
  .content_services a.card:hover .card--title {
    color: var(--c-text-accent); }

.content_programm a.card .card--text p,
.content_services a.card .card--text p {
  margin-bottom: var(--g-24); }

.content_programm a.card .card--actions,
.content_services a.card .card--actions {
  display: block; }

.content_personal .card,
.content_galleries .card,
.content_card .card {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  transition: var(--transition);
  aspect-ratio: 166/220; }
  @media (min-width: 769px) {
    .content_personal .card,
    .content_galleries .card,
    .content_card .card {
      aspect-ratio: 382 / 420; } }
  .content_personal .card:after,
  .content_galleries .card:after,
  .content_card .card:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(19, 21, 39, 0.6) 0%, rgba(19, 21, 39, 0) 25.12%, rgba(19, 21, 39, 0) 42.02%, rgba(19, 21, 39, 0.6) 65.36%);
    z-index: 2;
    transition: 0.18s ease-in-out;
    opacity: 0.7; }
  .content_personal .card .card--content,
  .content_galleries .card .card--content,
  .content_card .card .card--content {
    position: absolute;
    z-index: 3;
    bottom: var(--g-card);
    left: var(--g-card);
    right: var(--g-card);
    top: var(--g-card);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px; }
  .content_personal .card .card--label,
  .content_galleries .card .card--label,
  .content_card .card .card--label {
    position: absolute;
    z-index: 3;
    color: var(--c-text-white);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px; }
    @media (min-width: 768px) {
      .content_personal .card .card--label,
      .content_galleries .card .card--label,
      .content_card .card .card--label {
        gap: 8px; } }
    .content_personal .card .card--label p,
    .content_galleries .card .card--label p,
    .content_card .card .card--label p {
      position: relative;
      display: inline-block;
      background: rgba(206, 206, 206, 0.3);
      backdrop-filter: blur(8px);
      border-radius: 6px;
      padding: 5px 6px; }
      @media (min-width: 768px) {
        .content_personal .card .card--label p,
        .content_galleries .card .card--label p,
        .content_card .card .card--label p {
          padding: 6px 10px; } }
      .content_personal .card .card--label p:empty,
      .content_galleries .card .card--label p:empty,
      .content_card .card .card--label p:empty {
        display: none; }
      .content_personal .card .card--label p span,
      .content_galleries .card .card--label p span,
      .content_card .card .card--label p span {
        font-size: 12px;
        letter-spacing: 0.002em;
        overflow: hidden;
        z-index: 2;
        display: block; }
        @media (min-width: 768px) {
          .content_personal .card .card--label p span,
          .content_galleries .card .card--label p span,
          .content_card .card .card--label p span {
            font-size: 14px; } }
  .content_personal .card .card--title,
  .content_galleries .card .card--title,
  .content_card .card .card--title {
    font-size: var(--fs-36);
    font-weight: 700;
    color: var(--c-text-white);
    z-index: 3; }
    .content_personal .card .card--title h3,
    .content_galleries .card .card--title h3,
    .content_card .card .card--title h3 {
      font-size: var(--fs-36); }
      @media (max-width: 768px) {
        .content_personal .card .card--title h3,
        .content_galleries .card .card--title h3,
        .content_card .card .card--title h3 {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; } }
  .content_personal .card .card--image,
  .content_galleries .card .card--image,
  .content_card .card .card--image {
    aspect-ratio: 410 / 480;
    overflow: hidden; }
    @media (max-width: 768px) {
      .content_personal .card .card--image,
      .content_galleries .card .card--image,
      .content_card .card .card--image {
        border-radius: 16px;
        aspect-ratio: 160 / 220; } }
    .content_personal .card .card--image img,
    .content_galleries .card .card--image img,
    .content_card .card .card--image img {
      transition: var(--transition);
      scale: 1;
      transform-origin: center;
      width: 100%;
      height: 100%; }
  .content_personal .card .card--actions,
  .content_galleries .card .card--actions,
  .content_card .card .card--actions {
    display: none; }

.content_personal a.card:hover:after,
.content_galleries a.card:hover:after,
.content_card a.card:hover:after {
  transition: 0.18s ease-in-out;
  opacity: 0.9; }

.content_personal a.card:hover .card--actions,
.content_galleries a.card:hover .card--actions,
.content_card a.card:hover .card--actions {
  opacity: 1; }
  .content_personal a.card:hover .card--actions .button .icon:before,
  .content_galleries a.card:hover .card--actions .button .icon:before,
  .content_card a.card:hover .card--actions .button .icon:before {
    transform: translateX(6px); }

.content_personal a.card:hover .button .icon:before,
.content_galleries a.card:hover .button .icon:before,
.content_card a.card:hover .button .icon:before {
  transition: var(--transition) transform; }

.content_personal a.card:hover .card--image img,
.content_galleries a.card:hover .card--image img,
.content_card a.card:hover .card--image img {
  scale: 1.1; }

.content_personal a.card .card--actions,
.content_galleries a.card .card--actions,
.content_card a.card .card--actions {
  opacity: 0.8;
  display: block; }

.content_advantages .card {
  display: flex;
  gap: var(--g-elements);
  padding: var(--g-elements); }
  @media (max-width: 768px) {
    .content_advantages .card {
      flex-direction: column; } }
  .content_advantages .card--image {
    color: var(--c-text-accent);
    min-width: 30%; }
    .content_advantages .card--image img {
      aspect-ratio: 120/120;
      max-width: 120px;
      max-height: 120px;
      object-fit: contain; }
  .content_advantages .card--title {
    margin-bottom: 16px; }
    .content_advantages .card--title h3 {
      font-size: var(--fs-32);
      font-weight: 300; }
      .content_advantages .card--title h3 span {
        display: block; }
  .content_advantages .card--text {
    display: none; }
    @media (min-width: 768px) {
      .content_advantages .card--text {
        display: block; }
        .content_advantages .card--text p {
          max-width: 85%;
          max-width: 85%;
          opacity: 0.8;
          font-size: 16px;
          line-height: 1.5; } }

.content_sertificates .card .card--image {
  aspect-ratio: 382 / 278;
  overflow: hidden;
  background: #fff; }
  .content_sertificates .card .card--image img {
    object-fit: contain; }

.review {
  border-radius: 8px;
  background: #EDF6FF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .review--image {
    aspect-ratio: 410 / 516;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    position: relative; }
    .review--image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .review--author {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 8px 16px;
    margin-bottom: 12px; }
    .review--author_image {
      grid-column: 1;
      grid-row: span 2;
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100%; }
      .review--author_image img {
        border-radius: 50%;
        width: 48px;
        height: 48px; }
    .review--author_name {
      font-weight: 700;
      font-size: var(--fs-20); }
    .review--author_description {
      font-size: var(--fs-18);
      color: rgba(31, 31, 31, 0.5); }
  .review--info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px; }
  .review--stars {
    display: flex;
    align-items: center;
    --inactive_stars: var(--c-text-disabled);
    --star-color:  var(--c-text-accent); }
    .review--stars .star-rate {
      height: 18px; }
  .review--platform .icon {
    display: flex;
    color: var(--c-text-disabled); }
  .review--text {
    font-size: 16px;
    line-height: 1.6;
    color: var(--c-text-dark-paragraph);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; }
  .review--content {
    padding: 16px var(--g-32);
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .review--date {
    color: rgba(151, 86, 233, 0.6);
    font-size: var(--fs-18);
    line-height: 1; }
  @media (max-width: 768px) {
    .review .button {
      display: none; } }

.content_document .card {
  background-color: #EDF6FF;
  border-radius: 12px;
  padding: 8px 12px;
  aspect-ratio: 382/256; }
  @media (min-width: 769px) {
    .content_document .card {
      padding: var(--g-elements); } }
  .content_document .card:hover .card--title {
    opacity: 0.7; }
  .content_document .card--content {
    min-height: 132px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    gap: 8px; }
  .content_document .card--title {
    font-weight: 600;
    font-size: var(--fs-22);
    margin-bottom: 12px;
    overflow: hidden; }
    .content_document .card--title p {
      display: -webkit-box;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }
  .content_document .card--subtitle {
    font-weight: 400; }
    @media (max-width: 768px) {
      .content_document .card--subtitle {
        font-size: 12px; }
        .content_document .card--subtitle p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical; } }
  .content_document .card--link {
    margin-top: auto; }

.menu--offers .card {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  transition: var(--transition);
  display: flex;
  aspect-ratio: 378 / 320;
  max-width: 378px;
  max-height: 320px; }
  .menu--offers .card:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(19, 21, 39, 0.6) 0%, rgba(19, 21, 39, 0) 25.12%, rgba(19, 21, 39, 0) 42.02%, rgba(19, 21, 39, 0.6) 65.36%);
    z-index: 2;
    transition: 0.18s ease-in-out;
    opacity: 0.8; }
  .menu--offers .card:hover:after {
    transition: 0.18s ease-in-out;
    opacity: 1; }
  .menu--offers .card:hover .card--image img {
    scale: 1.1; }
  .menu--offers .card .card--content {
    position: absolute;
    z-index: 1;
    top: var(--g-card);
    left: var(--g-card);
    right: var(--g-card);
    bottom: var(--g-card);
    z-index: 3;
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  .menu--offers .card .card--title {
    font-size: var(--fs-44);
    font-weight: 700; }
    .menu--offers .card .card--title h3 {
      font-size: var(--fs-44); }
  .menu--offers .card .card--actions {
    margin-top: auto; }
    .menu--offers .card .card--actions .button {
      padding: 0; }
  .menu--offers .card .card--image {
    aspect-ratio: 378 / 320;
    border-radius: 8px;
    overflow: hidden; }
    @media (max-width: 768px) {
      .menu--offers .card .card--image {
        border-radius: 16px; } }
    .menu--offers .card .card--image img {
      transition: var(--transition);
      scale: 1;
      transform-origin: center;
      width: 100%;
      height: 100%; }

.content_vacancies .card {
  width: 100%;
  border-radius: var(--border-corner);
  overflow: hidden;
  background-color: transparent;
  transition: var(--transition); }

.content_vacancies .card--content {
  padding: 16px var(--g-card);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px; }
  @media (max-width: 768px) {
    .content_vacancies .card--content {
      padding: 16px 0; } }

.content_vacancies .card--title {
  margin-bottom: 8px;
  line-height: 1.33;
  font-size: var(--fs-24);
  font-family: 'arsenica', sans-serif;
  position: relative; }

.content_vacancies a.card--title {
  display: flex;
  align-items: center; }
  .content_vacancies a.card--title:after {
    color: var(--c-text-accent);
    margin-left: 5px;
    content: "\e913";
    display: inline-block;
    font-family: 'icomoon' !important;
    font-size: inherit;
    top: 0;
    right: 0;
    transform: translateX(0);
    transition: 0.25s linear; }
  .content_vacancies a.card--title:hover:after {
    transform: translateX(6px);
    opacity: 0.6; }

.content_vacancies .card--text {
  display: flex;
  align-items: center;
  gap: 8px; }
  @media (max-width: 768px) {
    .content_vacancies .card--text {
      flex-wrap: wrap; } }

.content_vacancies .icon {
  color: var(--c-text-accent); }

.content_vacancies .card--subtitle {
  font-size: 16px; }

.content_vacancies .card--actions {
  margin-top: 8px; }

.card.hidden {
  display: none;
  opacity: 0; }

.block-background-color:not(.content_advantages) .card {
  background-color: #FFFFFF; }

.content_advantages {
  overflow: hidden; }
  @media (max-width: 768px) {
    .content_advantages .swiper-button-next,
    .content_advantages .swiper-button-prev {
      display: none; } }

.block_list:not(.block_list-slider).content_advantages .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .block_list:not(.block_list-slider).content_advantages .block--elements {
      grid-template-columns: repeat(2, 1fr); } }

.block_list.content_advantages-2 .advantages--item_wrapper.swiper {
  overflow: visible; }

.block_list.content_advantages-2 {
  overflow: hidden;
  padding: var(--g-section) 0;
  margin: 0; }
  .block_list.content_advantages-2 .block--elements {
    display: flex;
    flex-direction: column;
    place-items: start;
    margin: var(--g-content) auto 0;
    border-radius: var(--border-corner);
    gap: 24px; }
    @media (min-width: 1024px) {
      .block_list.content_advantages-2 .block--elements {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'image text'; } }
    .block_list.content_advantages-2 .block--elements .advantages_item_wrapper {
      grid-area: text; }
      @media (max-width: 1024px) {
        .block_list.content_advantages-2 .block--elements .advantages_item_wrapper {
          order: 2; } }
    .block_list.content_advantages-2 .block--elements .advantages_image {
      grid-area: image; }
      @media (max-width: 1024px) {
        .block_list.content_advantages-2 .block--elements .advantages_image {
          order: 1; } }
  .block_list.content_advantages-2 .advantages--item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%; }
  .block_list.content_advantages-2 .advantages--item {
    cursor: pointer;
    background-color: var(--c-surface-default);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0;
    border-radius: var(--border-corner);
    background-color: transparent;
    flex-shrink: 0;
    flex-grow: 0; }
    @media (min-width: 1024px) {
      .block_list.content_advantages-2 .advantages--item {
        height: 100%;
        background-color: var(--c-surface-default);
        gap: 16px;
        flex-shrink: 1;
        flex-grow: 1;
        padding: var(--g-20) var(--g-32);
        opacity: 0.7; } }
    .block_list.content_advantages-2 .advantages--item_image video {
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center; }
    .block_list.content_advantages-2 .advantages--item.is_active, .block_list.content_advantages-2 .advantages--item.swiper-slide-active {
      opacity: 1; }
    @media (max-width: 1024px) {
      .block_list.content_advantages-2 .advantages--item_button {
        border-radius: 8px 8px 0 0; } }
    .block_list.content_advantages-2 .advantages--item_button p {
      font-size: var(--fs-24);
      font-family: 'arsenica', sans-serif;
      text-transform: uppercase;
      line-height: 1.17; }
    .block_list.content_advantages-2 .advantages--item_content.is_cascade {
      color: var(--c-text-dark);
      line-height: 1.5;
      font-size: var(--fs-18);
      border-radius: 0 0 8px 8px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (min-width: 1024px) {
        .block_list.content_advantages-2 .advantages--item_content.is_cascade {
          align-items: center;
          flex-direction: row; }
          .block_list.content_advantages-2 .advantages--item_content.is_cascade .advantages--item_image {
            display: none;
            border-radius: 0; } }
      .block_list.content_advantages-2 .advantages--item_content.is_cascade div,
      .block_list.content_advantages-2 .advantages--item_content.is_cascade p,
      .block_list.content_advantages-2 .advantages--item_content.is_cascade li,
      .block_list.content_advantages-2 .advantages--item_content.is_cascade span {
        font-size: var(--fs-18);
        margin-bottom: 0; }
        .block_list.content_advantages-2 .advantages--item_content.is_cascade div:first-child,
        .block_list.content_advantages-2 .advantages--item_content.is_cascade p:first-child,
        .block_list.content_advantages-2 .advantages--item_content.is_cascade li:first-child,
        .block_list.content_advantages-2 .advantages--item_content.is_cascade span:first-child {
          margin-top: 0; }
      .block_list.content_advantages-2 .advantages--item_content.is_cascade ul {
        padding-left: 24px; }
      .block_list.content_advantages-2 .advantages--item_content.is_cascade .button_wrapper {
        margin: 0; }
        .block_list.content_advantages-2 .advantages--item_content.is_cascade .button_wrapper span {
          margin-bottom: 0; }
      .block_list.content_advantages-2 .advantages--item_content.is_cascade .button {
        width: 100%;
        max-width: 130px;
        padding-right: 25px; }
        .block_list.content_advantages-2 .advantages--item_content.is_cascade .button span:empty + span {
          display: none; }
    .block_list.content_advantages-2 .advantages--item_button, .block_list.content_advantages-2 .advantages--item_content {
      background-color: #FFFFFF;
      padding: 12px var(--g-32); }
  .block_list.content_advantages-2 .advantages--image {
    display: none; }
    @media (min-width: 1024px) {
      .block_list.content_advantages-2 .advantages--image {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        aspect-ratio: 1/1; } }
    .block_list.content_advantages-2 .advantages--image_item {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      transition: 0.24s linear; }
      .block_list.content_advantages-2 .advantages--image_item img, .block_list.content_advantages-2 .advantages--image_item video {
        width: 100%;
        height: 100%;
        object-fit: cover; }
        @media (max-width: 1024px) {
          .block_list.content_advantages-2 .advantages--image_item img, .block_list.content_advantages-2 .advantages--image_item video {
            aspect-ratio: 344 / 333; } }
      .block_list.content_advantages-2 .advantages--image_item.is_active {
        opacity: 1; }
  .block_list.content_advantages-2 .advantages--item_image {
    aspect-ratio: 328 / 300;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    margin: calc(var(--g-40) * -1) calc(var(--g-64) * -1) 0; }
    @media (max-width: 1024px) {
      .block_list.content_advantages-2 .advantages--item_image {
        display: block;
        margin-bottom: 24px; } }

.content_contacts .block--content,
.content_contacts-reversed .block--content {
  display: flex;
  flex-direction: column;
  gap: 24px; }
  @media (min-width: 769px) {
    .content_contacts .block--content,
    .content_contacts-reversed .block--content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'content map'; } }
  .content_contacts .block--content .is_cascade,
  .content_contacts-reversed .block--content .is_cascade {
    padding: var(--g-32) 0; }
    @media (min-width: 769px) {
      .content_contacts .block--content .is_cascade,
      .content_contacts-reversed .block--content .is_cascade {
        max-width: 600px;
        margin: auto;
        grid-area: content; } }
  .content_contacts .block--content .block--head h2,
  .content_contacts-reversed .block--content .block--head h2 {
    margin-top: 0; }
  .content_contacts .block--content .contact,
  .content_contacts-reversed .block--content .contact {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
    position: relative;
    margin-bottom: 20px; }
    .content_contacts .block--content .contact:before,
    .content_contacts-reversed .block--content .contact:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0.3em;
      display: block;
      width: 100%;
      height: 2px;
      border-bottom: 1px dashed #404040;
      opacity: 0.4;
      z-index: -1; }
    .content_contacts .block--content .contact--title,
    .content_contacts-reversed .block--content .contact--title {
      color: rgba(31, 31, 31, 0.5);
      background-color: #fff;
      padding-right: 4px;
      z-index: 1;
      font-size: var(--fs-20);
      line-height: 1.4; }
    .content_contacts .block--content .contact--value a, .content_contacts .block--content .contact--value p,
    .content_contacts-reversed .block--content .contact--value a,
    .content_contacts-reversed .block--content .contact--value p {
      opacity: 0.9;
      background-color: #fff;
      padding-left: 4px;
      z-index: 1;
      font-size: var(--fs-20);
      line-height: 1.4;
      color: #2A2827;
      font-weight: 500;
      margin: 0; }
  .content_contacts .block--content .icon,
  .content_contacts-reversed .block--content .icon {
    display: inline-block;
    background-color: var(--bck-color);
    border-radius: var(--border-corner);
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    position: relative; }
    .content_contacts .block--content .icon:before,
    .content_contacts-reversed .block--content .icon:before {
      position: absolute;
      inset: 0;
      color: var(--c-text-accent);
      font-size: xxx-large; }
    .content_contacts .block--content .icon:hover:before,
    .content_contacts-reversed .block--content .icon:hover:before {
      color: var(--c-text-accent-subdued); }

.map {
  grid-area: map;
  overflow: hidden;
  border-radius: var(--border-corner);
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  min-height: 100%; }
  @media (max-width: 768px) {
    .map {
      aspect-ratio: 380 /  280; } }
  .map iframe,
  .map #map {
    width: 100%;
    height: 100%;
    min-height: 100%; }

.content_contacts-reversed .block--content {
  grid-template-areas: 'map content '; }

.content_vacancies .block--wrapper {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-column-gap: var(--g-section);
  place-content: start;
  place-items: start; }
  @media (max-width: 768px) {
    .content_vacancies .block--wrapper {
      grid-template-columns: 1fr;
      grid-column-gap: 20px; } }
  @media (min-width: 769px) {
    .content_vacancies .block--wrapper .block--head, .content_vacancies .block--wrapper .block--actions {
      grid-column: span 2; } }
  .content_vacancies .block--wrapper .block--elements {
    margin: 0;
    width: 100%;
    grid-template-columns: 1fr;
    gap: var(--g-content); }
  .content_vacancies .block--wrapper .block--filters {
    overflow: hidden; }
  .content_vacancies .block--wrapper .filters {
    background-color: var(--bck-color);
    padding: var(--g-elements) var(--g-32);
    border-radius: var(--border-corner); }
    @media (max-width: 768px) {
      .content_vacancies .block--wrapper .filters {
        overflow-x: auto;
        max-width: 100vw;
        margin: 0 -16px 0 0; } }
    .content_vacancies .block--wrapper .filters--container {
      display: flex;
      flex-direction: column;
      gap: 20px; }
      @media (max-width: 768px) {
        .content_vacancies .block--wrapper .filters--container {
          flex-direction: row; } }
      .content_vacancies .block--wrapper .filters--container label {
        white-space: nowrap; }
      .content_vacancies .block--wrapper .filters--container label:last-child span:not(.custom-checkbox) {
        margin-right: 32px; }
    .content_vacancies .block--wrapper .filters--item_title {
      grid-area: title;
      font-family: 'arsenica', sans-serif;
      font-size: var(--fs-32); }

.content_reviews.block_list:not(.block_list-slider) .block--elements .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }

@media (max-width: 1024px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 768px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: 1fr; } }

.content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
  grid-column: span 3; }
  @media (max-width: 1024px) {
    .content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 575px) {
    .content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
      grid-column: 1; } }

.content_offers:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_offers:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 575px) {
    .content_offers:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr; } }

.content_offers:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_offers:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 575px) {
    .content_offers:not(.block_list-slider) .block--pagination {
      grid-column: 1; } }

.content_programm {
  overflow: hidden; }
  .content_programm:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(4, 1fr);
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
    @media (max-width: 1024px) {
      .content_programm:not(.block_list-slider) .block--elements {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 768px) {
      .content_programm:not(.block_list-slider) .block--elements {
        grid-template-columns: 1fr; } }

.page-title {
  font-size: var(--fs-title);
  text-align: center;
  margin-bottom: var(--g-content_cards); }

.content_schedule {
  overflow: hidden; }
  .content_schedule .list_item {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  .content_schedule .list_title p {
    font-size: var(--fs-18);
    font-weight: 600; }
    .content_schedule .list_title p span.today {
      color: var(--c-text-subtitle);
      margin-left: 8px; }
  .content_schedule .list_title div {
    font-family: 'arsenica', sans-serif;
    font-size: var(--fs-36);
    margin-bottom: var(--g-24); }
  .content_schedule .list_content {
    display: grid;
    align-items: start;
    grid-template-columns: 0.5fr 1fr; }
  .content_schedule .list_time {
    font-family: 'arsenica', sans-serif;
    font-size: var(--fs-32); }
  .content_schedule .list_text {
    display: flex;
    flex-direction: column;
    gap: 8px; }
    .content_schedule .list_text span {
      font-size: 16px; }
    .content_schedule .list_text span:last-child {
      font-size: 14px;
      opacity: 0.8; }
  .content_schedule .slider_controls {
    display: none; }
    @media (min-width: 769px) {
      .content_schedule .slider_controls {
        display: flex;
        width: fit-content;
        left: unset;
        transform: translateY(calc(-100% - var(--g-40))); } }
    .content_schedule .slider_controls .swiper-button-next,
    .content_schedule .slider_controls .swiper-button-prev {
      width: 100%;
      color: var(--c-text-accent); }

.content_services:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_services:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 768px) {
    .content_services:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr; } }

.content_services:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_services:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 768px) {
    .content_services:not(.block_list-slider) .block--pagination {
      grid-column: 1; } }

.content_personal:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_personal:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }

.content_personal:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_personal:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }

.content_galleries:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_galleries:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }

.content_galleries:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_galleries:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }

.content_card.block_list:not(.block_list-slider) .block--elements .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_card.block_list:not(.block_list-slider) .block--elements .block--elements {
      grid-template-columns: repeat(2, 1fr); } }

.content_card.block_list:not(.block_list-slider) .block--elements .block--pagination {
  grid-column: span 3; }
  @media (max-width: 1024px) {
    .content_card.block_list:not(.block_list-slider) .block--elements .block--pagination {
      grid-column: span 2; } }

.content_sertificates:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_sertificates:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 475px) {
    .content_sertificates:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr; } }

.content_sertificates:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_sertificates:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 475px) {
    .content_sertificates:not(.block_list-slider) .block--pagination {
      grid-column: 1; } }

.content_document:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr); }
  @media (max-width: 1024px) {
    .content_document:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(3, 1fr);
      gap: 12px; } }
  @media (max-width: 768px) {
    .content_document:not(.block_list-slider) .block--elements {
      grid-template-columns: repeat(2, 1fr); } }

.content_document:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_document:not(.block_list-slider) .block--pagination {
      grid-column: span 3; } }
  @media (max-width: 768px) {
    .content_document:not(.block_list-slider) .block--pagination {
      grid-column: 2; } }

@media (min-width: 1630px) {
  .content_form .block--wrapper {
    max-width: 1350px;
    margin: auto; } }

.content_form .block--elements {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-elements); }
  @media (min-width: 768px) {
    .content_form .block--elements {
      grid-template-columns: repeat(2, 1fr); } }
  .content_form .block--elements .block--head {
    max-width: 520px; }
    .content_form .block--elements .block--head_title {
      line-height: 1; }

.content_faq {
  max-width: 1350px;
  margin: var(--g-section) auto; }
  .content_faq .block--wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; }
    @media (min-width: 769px) {
      .content_faq .block--wrapper {
        grid-template-columns: 1fr 1fr;
        gap: 20px; } }
    .content_faq .block--wrapper .block--head {
      grid-column: 1;
      align-items: flex-start; }
      @media (min-width: 769px) {
        .content_faq .block--wrapper .block--head {
          align-items: center; } }
    .content_faq .block--wrapper .block--elements,
    .content_faq .block--wrapper .block--actions {
      grid-column: 2; }
      @media (max-width: 768px) {
        .content_faq .block--wrapper .block--elements,
        .content_faq .block--wrapper .block--actions {
          grid-column: 1; } }
    .content_faq .block--wrapper .block--actions {
      padding: 0;
      grid-row: 2; }
    .content_faq .block--wrapper .block--actions:empty {
      display: none; }
    .content_faq .block--wrapper .block--elements {
      margin-top: 0;
      width: 100%;
      grid-template-columns: 1fr;
      gap: 0; }

.content_404 .block--wrapper {
  padding: var(--g-section) 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.content_404 .block--head {
  align-items: center; }

.content_404 h1 {
  font-size: clamp(32px, calc(32px + (96 - 32) * ((100vw - 1024px) / (1920 - 1024))), 96px);
  text-align: center; }

.content_excursions:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_excursions:not(.block_list-slider) .block--elements {
      grid-template-columns: 2fr; } }
  @media (max-width: 768px) {
    .content_excursions:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr;
      gap: 32px; } }

.content_excursions:not(.block_list-slider) .block--pagination {
  grid-column: span 3; }
  @media (max-width: 1024px) {
    .content_excursions:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 768px) {
    .content_excursions:not(.block_list-slider) .block--pagination {
      grid-column: 1; } }

.content_afisha:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_afisha:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 768px) {
    .content_afisha:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr;
      gap: 32px; } }

.content_afisha:not(.block_list-slider) .block--pagination {
  grid-column: span 4; }
  @media (max-width: 1024px) {
    .content_afisha:not(.block_list-slider) .block--pagination {
      grid-column: span 2; } }
  @media (max-width: 768px) {
    .content_afisha:not(.block_list-slider) .block--pagination {
      grid-column: 1; } }

.article .block--content {
  margin-top: 0; }

.article .block--wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 var(--g-content_cards); }
  @media (min-width: 768px) {
    .article .block--wrapper {
      grid-template-columns: 7fr 4fr; } }

.article article {
  margin: var(--g-section-inner) 0; }

.article .block--head {
  margin-bottom: var(--g-32); }

.article .block--head_caption {
  font-family: 'caveat' , sans-serif;
  font-size: var(--fs-28); }

.article article .f-h1,
.article article .f-h2 {
  font-size: clamp(32px, calc(32px + (64 - 32) * ((100vw - 1024px) / (1920 - 1024))), 64px); }

.article article .block--head {
  max-width: 100%; }

.article article .block--content {
  max-width: var(--container);
  --container: 900px; }
  @media (max-width: 992px) {
    .article article .block--content {
      --container: calc(100vw - 32px); } }

.article .block--content {
  grid-column: 1; }

.article .content_subscription {
  height: max-content;
  margin: 0 -16px; }
  @media (min-width: 768px) {
    .article .content_subscription {
      background-color: transparent;
      margin: 0; } }
  .article .content_subscription .block--wrapper {
    grid-template-areas: 'content' 'img'; }
    @media (min-width: 768px) {
      .article .content_subscription .block--wrapper {
        padding: 0;
        grid-template-columns: 1fr; } }
    @media (min-width: 768px) {
      .article .content_subscription .block--wrapper .block--elements {
        grid-template-columns: 1fr; } }
    @media (min-width: 768px) {
      .article .content_subscription .block--wrapper .block--content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: var(--bck-color);
        padding: var(--g-section-inner) var(--g-content); } }
    .article .content_subscription .block--wrapper .block--content .block--head_title h3 {
      font-size: var(--fs-36); }
    .article .content_subscription .block--wrapper .block--content .block--text {
      padding: 8px 0; }
      .article .content_subscription .block--wrapper .block--content .block--text p {
        padding: 8px 0;
        font-size: var(--fs-20);
        color: #9198B5; }
    @media (max-width: 768px) {
      .article .content_subscription .block--wrapper .block--actions {
        width: 100%; } }
    .article .content_subscription .block--wrapper .block--image .people {
      top: 2px; }
      @media (max-width: 768px) {
        .article .content_subscription .block--wrapper .block--image .people {
          top: auto; } }

@media (max-width: 768px) {
  .content_article--sidebar {
    grid-row: 2;
    margin: var(--g-32) 0; } }

.content_article--sidebar_sticky {
  background-color: var(--bck-color);
  border-radius: var(--border-corner);
  padding: var(--g-24) var(--g-32);
  grid-row: 1; }
  @media (min-width: 768px) {
    .content_article--sidebar_sticky {
      grid-row: auto; } }
  @media (min-width: 992px) {
    .content_article--sidebar_sticky {
      position: sticky;
      top: calc(var(--headerHeight) + 40px); } }
  .content_article--sidebar_sticky strong {
    font-size: var(--fs-24);
    font-family: 'arsenica', sans-serif;
    line-height: 1.17;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    display: block; }
  .content_article--sidebar_sticky .sidebar_nav {
    list-style: none;
    margin: 24px 0 0;
    display: flex;
    flex-direction: column; }
    .content_article--sidebar_sticky .sidebar_nav--item {
      position: relative;
      font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024))), 20px);
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 18px;
      opacity: 0.6; }
      .content_article--sidebar_sticky .sidebar_nav--item:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        min-width: 10px;
        background-color: transparent;
        border-radius: 50%; }
      .content_article--sidebar_sticky .sidebar_nav--item.is_active {
        opacity: 1; }
        .content_article--sidebar_sticky .sidebar_nav--item.is_active:before {
          background-color: var(--c-text-accent); }
      @media (max-width: 768px) {
        .content_article--sidebar_sticky .sidebar_nav--item:before {
          background-color: var(--c-text-accent); } }
      .content_article--sidebar_sticky .sidebar_nav--item:hover, .content_article--sidebar_sticky .sidebar_nav--item:focus, .content_article--sidebar_sticky .sidebar_nav--item:active {
        opacity: 1; }

.content_subscription,
.content_subscription-reversed {
  background-color: var(--bck-color);
  position: relative;
  margin: 0; }
  .content_subscription .block--wrapper,
  .content_subscription-reversed .block--wrapper {
    padding: clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px) 0;
    grid-template-columns: 1fr;
    display: grid;
    grid-template-areas: 'content' 'img';
    max-width: var(--container);
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375))), 24px); }
    @media (min-width: 769px) {
      .content_subscription .block--wrapper,
      .content_subscription-reversed .block--wrapper {
        grid-template-areas: 'content img';
        grid-template-columns: repeat(2, 1fr); } }
  .content_subscription .block--content,
  .content_subscription-reversed .block--content {
    grid-area: content;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    border-radius: var(--border-corner);
    padding: clamp(22px, calc(22px + (40 - 22) * ((100vw - 375px) / (768 - 375))), 40px) 22px; }
    @media (min-width: 769px) {
      .content_subscription .block--content,
      .content_subscription-reversed .block--content {
        padding: var(--g-section); } }
    .content_subscription .block--content:after,
    .content_subscription-reversed .block--content:after {
      content: '';
      position: absolute;
      width: 110%;
      height: 125%;
      bottom: calc(clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 118px) * -1);
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../img/block-subscription-bck-1.png"); }
  .content_subscription .block--image,
  .content_subscription-reversed .block--image {
    grid-area: img; }
    @media (max-width: 768px) {
      .content_subscription .block--image,
      .content_subscription-reversed .block--image {
        position: relative; } }
    .content_subscription .block--image .people,
    .content_subscription-reversed .block--image .people {
      height: 150%;
      position: absolute;
      width: 100%;
      bottom: calc(clamp(32px, calc(32px + (118 - 32) * ((100vw - 1024px) / (1920 - 1024))), 40px) * -1);
      right: 0;
      object-fit: contain;
      object-position: bottom right;
      aspect-ratio: 517/560; }
      @media (min-width: 575px) {
        .content_subscription .block--image .people,
        .content_subscription-reversed .block--image .people {
          height: 120%; } }
      @media (min-width: 768px) {
        .content_subscription .block--image .people,
        .content_subscription-reversed .block--image .people {
          top: 0;
          height: 100%;
          bottom: 0;
          aspect-ratio: 948/1020; } }
    .content_subscription .block--image img,
    .content_subscription-reversed .block--image img {
      border-radius: var(--border-corner); }
  .content_subscription .form,
  .content_subscription-reversed .form {
    padding: 0; }
  .content_subscription fieldset,
  .content_subscription-reversed fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: #fff;
    gap: var(--g-20); }
    @media (max-width: 768px) {
      .content_subscription fieldset,
      .content_subscription-reversed fieldset {
        padding: 0;
        flex-direction: column; }
        .content_subscription fieldset .form--input,
        .content_subscription fieldset .form--button,
        .content_subscription fieldset .form--button .button,
        .content_subscription-reversed fieldset .form--input,
        .content_subscription-reversed fieldset .form--button,
        .content_subscription-reversed fieldset .form--button .button {
          width: 100%; } }
    .content_subscription fieldset .form-input,
    .content_subscription-reversed fieldset .form-input {
      flex-grow: 1; }
    .content_subscription fieldset .form--policy,
    .content_subscription fieldset .form--input span,
    .content_subscription-reversed fieldset .form--policy,
    .content_subscription-reversed fieldset .form--input span {
      display: none; }
    .content_subscription fieldset .form--button,
    .content_subscription-reversed fieldset .form--button {
      margin-top: 8px; }

.content_subscription-reversed .block--wrapper {
  grid-template-areas: 'content' 'img'; }
  @media (min-width: 769px) {
    .content_subscription-reversed .block--wrapper {
      grid-template-areas: ' img content'; } }

.content_subscription-reversed .block--image .people {
  scale: -1 1; }

.block-medicine.content_subscription,
.block-medicine.content_subscription-reversed {
  padding: 0; }
  .block-medicine.content_subscription .block--content:after,
  .block-medicine.content_subscription-reversed .block--content:after {
    background-image: url("../img/block-subscription-bck-2.png"); }

.content_diseases .block--content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px; }
  @media (min-width: 768px) {
    .content_diseases .block--content {
      grid-template-columns: 1fr 2fr; } }
  .content_diseases .block--content .block--search {
    background-color: var(--bck-color);
    border-radius: var(--border-corner);
    height: min-content;
    padding: var(--g-32) var(--g-content); }
    .content_diseases .block--content .block--search .search-input form {
      padding: 0 12px;
      margin-bottom: var(--g-32);
      border-radius: var(--border-corner);
      background-color: #FFFFFF;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between; }
      .content_diseases .block--content .block--search .search-input form input,
      .content_diseases .block--content .block--search .search-input form input:focus,
      .content_diseases .block--content .block--search .search-input form input:active {
        outline: none;
        border: none;
        width: 100%; }
      .content_diseases .block--content .block--search .search-input form .icon-search {
        display: inline-block;
        width: 16px;
        height: 16px;
        transition: var(--transition);
        background: url("../img/search.svg") no-repeat center; }
      .content_diseases .block--content .block--search .search-input form input.close + .icon-serach {
        background: url("../img/icon/close.svg") no-repeat center; }
      .content_diseases .block--content .block--search .search-input form button {
        display: none;
        font-size: 14px; }
    .content_diseases .block--content .block--search .search-alphabet {
      display: grid;
      grid-template-columns: repeat(7, 1fr); }
      @media (min-width: 475px) {
        .content_diseases .block--content .block--search .search-alphabet {
          grid-template-columns: repeat(12, 1fr); } }
      .content_diseases .block--content .block--search .search-alphabet [data-button] {
        cursor: pointer;
        padding: 5px 10px;
        transition: var(--transition);
        text-align: center; }
      .content_diseases .block--content .block--search .search-alphabet [data-button].active,
      .content_diseases .block--content .block--search .search-alphabet [data-button]:hover {
        background-color: #FFFFFF;
        border-radius: var(--border-corner); }
  .content_diseases .block--content .block--diseases-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--g-content); }
    .content_diseases .block--content .block--diseases-list a {
      transition: var(--transition); }
    .content_diseases .block--content .block--diseases-list a:hover {
      text-decoration: underline; }
  .content_diseases .block--content .diseases-list_item {
    display: flex;
    flex-direction: column;
    gap: 20px; }
    @media (min-width: 769px) {
      .content_diseases .block--content .diseases-list_item {
        margin-bottom: 20px; } }
  .content_diseases .block--content .diseases-list_item.hidden {
    display: none; }
  .content_diseases .block--content .diseases-list_item-title {
    font-family: 'arsenica', sans-serif;
    font-size: var(--fs-44);
    color: var(--c-text-accent); }

.content_diseases .diseases_not_found {
  display: none;
  grid-column: span 3; }
  .content_diseases .diseases_not_found div {
    font-family: 'arsenica', sans-serif;
    font-size: var(--fs-44);
    color: var(--c-text-accent);
    margin-bottom: 20px; }

.content_accommodation:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px); }
  @media (max-width: 1024px) {
    .content_accommodation:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 768px) {
    .content_accommodation:not(.block_list-slider) .block--elements {
      grid-template-columns: 1fr;
      gap: 32px; } }

.page-accommodation-seo .content_accommodation {
  overflow: hidden; }
  .page-accommodation-seo .content_accommodation .block--wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr; }
    @media (max-width: 768px) {
      .page-accommodation-seo .content_accommodation .block--wrapper {
        grid-template-columns: 1fr; } }
  .page-accommodation-seo .content_accommodation .block--sections {
    justify-content: space-between; }
    @media (max-width: 768px) {
      .page-accommodation-seo .content_accommodation .block--sections {
        flex-direction: column-reverse;
        align-items: flex-start;
        overflow-x: initial;
        overflow-y: initial; } }
    .page-accommodation-seo .content_accommodation .block--sections_tabs {
      display: flex;
      gap: 8px; }
      @media (max-width: 768px) {
        .page-accommodation-seo .content_accommodation .block--sections_tabs {
          overflow-x: auto;
          max-width: 100vw;
          margin: 0 -16px;
          padding: 0 16px; } }
  .page-accommodation-seo .content_accommodation .block--elements {
    display: grid;
    grid-template-columns: 1fr;
    gap: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
    position: relative;
    z-index: 1; }
  .page-accommodation-seo .content_accommodation .block--filters .open--filters {
    display: block;
    width: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      .page-accommodation-seo .content_accommodation .block--filters .open--filters {
        display: none; } }
  @media (max-width: 768px) {
    .page-accommodation-seo .content_accommodation .block--filters form {
      display: none; } }
  .page-accommodation-seo .content_accommodation .block--filters .filters.is_open form {
    display: block; }
  .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting {
    position: relative;
    margin-left: auto; }
    .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting .open-sort-list {
      translate: 0 -30%;
      opacity: 0;
      pointer-events: none;
      top: 120%;
      right: 0;
      padding: var(--g-20) var(--g-24);
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #FFFFFF;
      gap: var(--g-20);
      box-shadow: 0px 3px 12px 0px #021F541F;
      transition: var(--transition);
      border-radius: var(--border-corner); }
      @media (max-width: 768px) {
        .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting .open-sort-list {
          right: auto;
          left: 0; } }
    .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting .icon {
      transition: var(--transition);
      rotate: 0;
      color: var(--c-text-accent); }
    .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting.active .icon {
      transition: var(--transition);
      rotate: 180deg; }
    .page-accommodation-seo .content_accommodation .block--sections_item.js-sorting.active .open-sort-list {
      translate: 0 0;
      opacity: 1;
      pointer-events: auto; }
  .page-accommodation-seo .content_accommodation .card--accommodation {
    opacity: 1;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-areas: 'img content';
    gap: var(--g-24); }
    @media (max-width: 998px) {
      .page-accommodation-seo .content_accommodation .card--accommodation {
        grid-template-columns: 1fr 1fr; } }
    @media (max-width: 768px) {
      .page-accommodation-seo .content_accommodation .card--accommodation {
        grid-template-columns: 1fr;
        grid-template-areas: 'img' 'content'; } }
    .page-accommodation-seo .content_accommodation .card--accommodation .card--image {
      grid-area: img; }
      .page-accommodation-seo .content_accommodation .card--accommodation .card--image img {
        border-radius: var(--border-corner);
        aspect-ratio: 343 / 240; }
        @media (min-width: 769px) {
          .page-accommodation-seo .content_accommodation .card--accommodation .card--image img {
            aspect-ratio: 382 /320; } }
    .page-accommodation-seo .content_accommodation .card--accommodation .card--content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      gap: var(--g-20); }
      .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--title {
        font-size: var(--fs-32);
        font-family: 'arsenica', sans-serif; }
      .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews {
        display: flex;
        gap: 8px;
        align-items: center; }
        @media (max-width: 768px) {
          .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews {
            flex-wrap: wrap; } }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews_grade {
          white-space: nowrap;
          background-color: #F5F6FA;
          border-radius: var(--border-corner);
          padding: 6px; }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews_review {
          white-space: nowrap;
          color: var(--c-text-subtitle); }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews_yandex {
          white-space: nowrap; }
          .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--reviews_yandex .icon:before {
            color: #FFFFFF;
            height: 11px;
            width: 7px;
            padding: 4px 7px 4px 6px;
            border-radius: 50%;
            background-color: #FC3F1D;
            margin-right: 8px;
            display: inline-block;
            font-size: 12px; }
      .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--info ul {
        padding-left: 25px; }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--info ul li:not(:last-of-type) {
          margin-bottom: 8px; }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--info ul li::marker {
          font-size: 0.8em; }
      .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--actions {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: var(--fs-22);
        margin-top: auto; }
        .page-accommodation-seo .content_accommodation .card--accommodation .card--content .card--actions-night {
          font-weight: 600;
          opacity: 0.6; }
  .page-accommodation-seo .content_accommodation .card--accommodation.hidden {
    opacity: 0;
    display: none; }

.content_accommodation.block_element .block--wrapper {
  margin-top: var(--g-content); }

.content_accommodation.block_element .block--content {
  grid-template-columns: 1fr 1fr;
  gap: var(--g-section-inner);
  display: grid;
  max-width: 1350px;
  align-items: start;
  margin: auto; }
  @media (max-width: 768px) {
    .content_accommodation.block_element .block--content {
      display: flex;
      flex-direction: column; } }
  .content_accommodation.block_element .block--content .booking {
    grid-column: 2;
    grid-row: auto; }
    @media (min-width: 768px) {
      .content_accommodation.block_element .block--content .booking {
        top: var(--headerHeight, 157px);
        margin: 0 40px;
        position: sticky; } }
  .content_accommodation.block_element .block--content .is_cascade {
    grid-column: 1;
    grid-row: 1; }
    .content_accommodation.block_element .block--content .is_cascade ul {
      list-style-type: none;
      padding: var(--g-content) 0; }
      .content_accommodation.block_element .block--content .is_cascade ul li {
        position: relative;
        display: flex;
        gap: 16px 16px; }
        .content_accommodation.block_element .block--content .is_cascade ul li * {
          line-height: 1; }
        .content_accommodation.block_element .block--content .is_cascade ul li strong {
          margin-bottom: 8px;
          display: inline-block; }
        .content_accommodation.block_element .block--content .is_cascade ul li .icon {
          color: var(--c-text-accent); }
      .content_accommodation.block_element .block--content .is_cascade ul li:not(:last-child) {
        margin-bottom: 16px; }
  .content_accommodation.block_element .block--content .sleep-place {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px 12px; }
    @media (max-width: 768px) {
      .content_accommodation.block_element .block--content .sleep-place .sleep-place_item:nth-child(3n) {
        grid-column: span 2; } }
    .content_accommodation.block_element .block--content .sleep-place_item {
      padding: var(--g-elements) var(--g-32);
      border: 1px solid var(--c-text-disabled);
      border-radius: 12px; }
  .content_accommodation.block_element .block--content .comfort {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px; }
    .content_accommodation.block_element .block--content .comfort_item {
      font-size: var(--fs-20);
      display: flex;
      align-items: center;
      gap: 8px; }
      .content_accommodation.block_element .block--content .comfort_item .icon {
        font-size: 1.2em; }
    .content_accommodation.block_element .block--content .comfort_item:nth-child(-n+7) {
      display: flex; }
    .content_accommodation.block_element .block--content .comfort_item:nth-child(n+7) {
      display: none; }
  .content_accommodation.block_element .block--content .comfort.active .comfort_item:nth-child(n+7) {
    display: block; }
  .content_accommodation.block_element .block--content .button {
    margin-top: 16px; }

.content_accommodation.block_element .js-show-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  transition: var(--transition); }

.content_accommodation.block_element .js-show-content.show {
  -webkit-line-clamp: unset; }

.content_accommodation.block_element .js-show-content .comfort_item:nth-child(n+7) {
  display: none; }

.content_accommodation.block_element .js-show-content.show .comfort_item:nth-child(n+7) {
  display: block; }

.content_accommodation.block_element .js-show-btn.active .icon {
  rotate: 180deg; }

.content_map_around #map_places {
  aspect-ratio: 1272 / 540;
  margin-top: 40px;
  position: relative; }
  @media (max-width: 768px) {
    .content_map_around #map_places {
      aspect-ratio: 1 / 1; } }
  .content_map_around #map_places .ymaps-2-1-79-balloon__content {
    font: inherit; }
  .content_map_around #map_places .ymaps-2-1-79-image {
    -webkit-clip-path: url(#placemark_clip_path);
    clip-path: url(#placemark_clip_path);
    aspect-ratio: 40 / 50;
    width: 40px;
    height: 50px;
    background-size: cover !important;
    background-position: center !important; }
  .content_map_around #map_places .balloon {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: var(--c-surface-default); }
  .content_map_around #map_places .balloon--wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px; }
    .content_map_around #map_places .balloon--wrapper .button {
      margin-top: 16px; }
  .content_map_around #map_places .balloon--image {
    width: 100%;
    aspect-ratio: 300 / 234;
    position: relative; }
    .content_map_around #map_places .balloon--image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .content_map_around #map_places .balloon--image:after {
      content: "";
      width: 100%;
      height: auto;
      background: radial-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.25));
      background-blend-mode: multiply;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2; }
  .content_map_around #map_places .balloon--title {
    font-weight: 500;
    font-size: var(--fs-20);
    color: var(--c-text-dark); }
  .content_map_around #map_places .balloon--text {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .content_map_around #map_places .balloon--content {
    font-size: var(--fs-16);
    color: var(--c-text-subdued);
    line-height: 1.5; }

.booking {
  padding: var(--g-content) var(--g-32);
  display: flex;
  border-radius: 24px;
  position: relative;
  flex-direction: column;
  border: solid 1px rgba(255, 255, 255, 0.15);
  background-color: var(--bck-color);
  gap: 8px; }
  @media (max-width: 768px) {
    .booking {
      flex-direction: column;
      align-items: stretch;
      max-width: calc(100vw - 32px);
      width: 100%; } }
  .booking .booking-title .price {
    font-family: 'arsenica', sans-serif;
    font-size: var(--fs-44); }
  .booking .booking-title .day {
    color: var(--c-surface-primary-hovered); }
  .booking input {
    border: none;
    outline: none; }
  .booking input[type="number"] {
    -webkit-appearance: textarea;
    -moz-appearance: textfield; }
  .booking input::-webkit-outer-spin-button, .booking input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  @media (min-width: 992px) {
    .booking .booking__button {
      flex: 0 0 fit-content; }
    .booking .booking__room {
      flex: 1 1 30%; }
    .booking .booking__calendar {
      flex: 1 1 30%; } }
  .booking__calendar, .booking__guests {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    gap: 8px;
    position: relative; }
    .booking__calendar span, .booking__guests span {
      grid-row: 1;
      font-weight: 600; }
    .booking__calendar input, .booking__guests input {
      height: min-content;
      grid-row: 2; }
  .booking__guests {
    grid-template-columns: 1fr; }
  .booking .booking__calendar_input,
  .booking .booking__guests-item {
    position: relative;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--c-surface-default);
    border: 1px solid var(--c-text-disabled);
    border-radius: var(--border-corner);
    font-weight: 400;
    padding: 12px 16px; }
  .booking .booking__calendar-item {
    width: 100%;
    min-height: 100%; }
  .booking .booking__calendar_input .icon {
    color: var(--c-text-accent); }
  .booking .booking__button .button {
    width: 100%; }

@media (min-width: 1024px) {
  .banner-hero + .booking {
    position: absolute;
    top: calc(100vh - var(--g-content));
    transform: translateY(-100%);
    right: calc((100vw - var(--container))/2);
    z-index: 12;
    width: 800px;
    margin: 0 auto; }
    .banner-hero + .booking > * {
      z-index: 1; } }

@media (max-width: 1023px) {
  .banner-hero + .booking {
    position: static; } }
